import { FC, useEffect, useMemo, useState } from "react";
import { MdOutlinePlace, MdPeopleAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { AddCountry, Users } from "../../../../store/loginSlice";
import { url } from "../../../../store/url";
import { RiSettingsLine } from "react-icons/ri";
import "./style.css";
import {
  AddCategory,
  AddSubCategory,
  DeleteCategory,
  DeleteChampionships,
  DeleteSubCategory,
  UpdateCategory,
  UpdateSubCategory,
  createChampType,
  createKTGType,
  getCategory,
  getParticipant,
  getSubCategory,
  participantType,
  subCategoryType,
} from "../../../../store/settingSlice";
import { Participant } from "./participant/page";
import {
  getSubscribeType,
  subscribeType,
} from "../../../../store/subscribeSlice";
import { FaMale, FaFemale, FaPencilAlt, FaCheck } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import {
  AdminType,
  AdmissionAdminsType,
  GetAdmissionAdmins,
  SType,
  getSetka,
  reverbUpdateLoser,
  reverbUpdateWinner,
} from "../../../../store/setkaSlice";
import { notDublicate } from "../../../notDublicate";
import { CoachMap } from "./CoachMap/page";
import { Time } from "./time/page";
import subscriptionManager from "../../../../SubscriptionManager";
import { Report } from "./Report/page";
import axios from "axios";
import { api } from "../../../../store/axiosInstans";
import { MyParticipant } from "./MyParticipants/page";

let Api = url();

type ChampResultType = {
  ch: createChampType;
  login: Users[];
  user: Users;
  subsAllCoachChild: subscribeType[];
  subsAssocCoach: subscribeType[];
};

export const ChampResult: FC<ChampResultType> = ({
  ch,
  login,
  user,
  subsAllCoachChild,
  subsAssocCoach,
}) => {
  const championship_id = ch?.id;
  useEffect(() => {
    const channelName = "winner-grid-championship." + championship_id;
    const eventName = "winner-grid-event";

    subscriptionManager.subscribe(channelName, eventName, (data) => {
      dispatch(reverbUpdateWinner(data.data));
    });

    // Отписка при размонтировании компонента
    return () => {
      subscriptionManager.unsubscribe(channelName);
    };
  }, []);

  useEffect(() => {
    const channelName = "loser-grid-championship." + championship_id;
    const eventName = "loser-grid-event";

    subscriptionManager.subscribe(channelName, eventName, (data) => {
      dispatch(reverbUpdateLoser(data.data));
    });

    // Отписка при размонтировании компонента
    return () => {
      subscriptionManager.unsubscribe(channelName);
    };
  }, []);

  useEffect(() => {
    dispatch(getSetka({ championship_id }));
  }, []);

  const admin = useAppSelector((state: any) => state.setka.admins);
  let fAdmin = useMemo(() => {
    let f: AdminType[] = admin.filter(
      (item: AdminType) =>
        item?.admin === user?.id && item?.creator === ch?.creator
    );
    return f;
  }, [admin, ch, user]);

  const [categoryABC, setKtg] = useState("");
  const [gender, setGender] = useState(1);
  const dispatch = useAppDispatch();
  const CategoryChamp = useAppSelector(
    (state: any) => state.setting.championshipCategory
  );

  const SubCategoryChamp = useAppSelector(
    (state: any) => state.setting.championshipSubCategory
  );
  let fCategory: createKTGType[] = CategoryChamp.filter(
    (item: createKTGType) => item?.championship_id === championship_id
  );
  let fSubCategoryChamp: createKTGType[] = SubCategoryChamp.filter(
    (item: createKTGType) => item?.championship_id === championship_id
  );
  const Part = useAppSelector((state: any) => state.setting.participant);

  const CreatorData: Users =
    login.find((item: Users) => item?.id === ch?.creator) || {};
  let fCategoryChamp: createKTGType[] = CategoryChamp.filter(
    (item: createKTGType) =>
      item?.championship_id === championship_id &&
      item?.categoryABC === categoryABC &&
      item.gender === gender
  );
  let fPartA: participantType[] = Part.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.categoryABC === "A"
  );
  let fPartB: participantType[] = Part.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.categoryABC === "B"
  );
  let fPartC: participantType[] = Part.filter(
    (item: participantType) =>
      item?.championship_id === championship_id && item?.categoryABC === "C"
  );

  // balalardin trenerin tabu
  // let ChildrensCoaches = useMemo(() => {
  //   let ChildrensCoaches: number[] = [];
  //   for (let i = 0; i < Part.length; i++) {
  //     const id: number = Part[i]?.child;
  //     let fSubsCoach: subscribeType[] = subscribe.filter(
  //       (item: subscribeType) => item?.following_id === id && item.type === 2
  //     );

  //     // if (fSubsCoach.length === 0) {
  //     //   dispatch(getSubscribeType({ id, type: 2 })); ...
  //     // }

  //     // bir balanin  trenerinin associasi
  //     let filterCoachAssocSubs: subscribeType[] = subscribe.filter(
  //       (item: subscribeType) =>
  //         item?.following_id === Part[i].coach &&
  //         item?.approved === 1 &&
  //         item?.type === 3
  //     );
  //     if (filterCoachAssocSubs.length === 0) {
  //       // dispatch(getSubscribeType({ id: Part[i]?.coach || 0, type: 3 })); // trenerdin associasin tabu ...
  //     }
  //     if (filterCoachAssocSubs.length > 0) {
  //       for (let i2 = 0; i2 < filterCoachAssocSubs.length; i2++) {
  //         let id = filterCoachAssocSubs[i2]?.follower_id;

  //         let f: any[] = ChildrensCoaches.filter(
  //           (item: any) => item === Part[i]?.coach
  //         );
  //         //Users
  //         let userAssoc: Users =
  //           login.find((item: Users) => item?.id === id) || {};
  //         let userCoach: Users =
  //           login.find(
  //             (item: Users) =>
  //               item?.id === filterCoachAssocSubs[i2]?.following_id
  //           ) || {};
  //         let userChild: Users =
  //           login.find((item: Users) => item?.id === Part[i]?.child) || {};

  //         if (userCoach.country === null) {
  //           dispatch(
  //             AddCountry({
  //               id: userCoach?.id,
  //               country: userAssoc?.country || null,
  //               region: userAssoc?.region || null,
  //               city: userAssoc?.city || null,
  //             })
  //           );
  //         }
  //         if (userChild.country === null) {
  //           dispatch(
  //             AddCountry({
  //               id: userChild?.id,
  //               country: userAssoc?.country || null,
  //               region: userAssoc?.region || null,
  //               city: userAssoc?.city || null,
  //             })
  //           );
  //         }

  //         if (f.length < 1) {
  //           ChildrensCoaches = [...ChildrensCoaches, Part[i]?.coach || 0];
  //         }
  //       }
  //     }
  //   }
  //   return ChildrensCoaches;
  // }, [subscribe, Part]);

  useEffect(() => {
    dispatch(getCategory({ championship_id }));
    dispatch(getSubCategory({ championship_id }));
    dispatch(getParticipant({ championship_id }));
    dispatch(GetAdmissionAdmins({ championship_id }));
  }, []);

  const CountryStor = useAppSelector((state: any) => state.login.country);
  const CityStor = useAppSelector((state: any) => state.login.city);
  const RegionStor = useAppSelector((state: any) => state.login.region);
  let hu: Users = login.find((item: Users) => item.id === ch.creator) || {};
  // let findKG = KG?.filter(item => item.years === year && item.categoryABC === categoryABC)

  let CS = CountryStor.find((item: any) => item.id === hu.country),
    rC = RegionStor.find((item: any) => item.id === hu.region),
    cC = CityStor.find((item: any) => item.id === hu.city);

  let open_tournamentParse = Date.parse(ch.open_tournament);
  let open_tournament = new Date(open_tournamentParse);
  let close_registerParse = Date.parse(ch.close_register);
  let close_register = new Date(close_registerParse);
  let close_tournamentParse = Date.parse(ch.close_tournament);
  let close_tournament = new Date(close_tournamentParse);

  const participant = useAppSelector((state: any) => state.setting.participant);
  const AdmissionAdmin = useAppSelector((state) => state.setka.admissionAdmin);

  const allSetka: SType[] = useAppSelector((state: any) => state.setka.setka);
  let fAllSetka = useMemo(() => {
    let f: SType[] = allSetka.filter(
      (item: SType) => item?.championship_id === championship_id
    );

    return f;
  }, [allSetka, championship_id]);

  let mergedGrid: (SType & participantType)[] = useMemo(() => {
    // Объединяем данные из обоих массивов
    const mergedGrid = fAllSetka.map((item: any) => {
      const partic = participant.find(
        (part: any) => part.id === item.championship_participant_id
      );
      return {
        ...partic, // Добавляем все свойства из participant, если он найден
        ...item,
      };
    });

    // Сортируем массив по убыванию значений h
    mergedGrid.sort((a, b) => Number(b.place) - Number(a.place));

    return mergedGrid;
  }, [fAllSetka, participant]);

  let Coach: any[] = useMemo(() => {
    let coach: any[] = [];
    for (let p = 0; p < participant.length; p++) {
      for (let s = 0; s < fAllSetka.length; s++) {
        if (fAllSetka[s]?.championship_participant_id === participant[p]?.id) {
          let fCoach: any[] = coach.filter(
            (item: any) => item?.coach === participant[p]?.coach
          );
          if (fCoach.length === 0) {
            if (fAllSetka[s]?.place === 1) {
              coach = [
                ...coach,
                {
                  coach: participant[p]?.coach,
                  gold: 1,
                  silver: 0,
                  bronze: 0,
                },
              ];
            }
            if (fAllSetka[s]?.place === 2) {
              coach = [
                ...coach,
                {
                  coach: participant[p]?.coach,
                  gold: 0,
                  silver: 1,
                  bronze: 0,
                },
              ];
            }
            if (fAllSetka[s]?.place === 4) {
              coach = [
                ...coach,
                {
                  coach: participant[p]?.coach,
                  gold: 0,
                  silver: 0,
                  bronze: 1,
                },
              ];
            }
          } else {
            for (let ci = 0; ci < coach.length; ci++) {
              if (participant[p]?.coach === coach[ci]?.coach) {
                if (fAllSetka[s]?.place === 1) {
                  let medalGold = coach[ci]?.gold + 1;
                  let medalSilver = coach[ci]?.silver;
                  let medalBronze = coach[ci]?.bronze;
                  coach[ci] = {
                    coach: participant[p]?.coach,
                    gold: medalGold,
                    silver: medalSilver,
                    bronze: medalBronze,
                  };
                }
                if (fAllSetka[s]?.place === 2) {
                  let medalGold = coach[ci]?.gold;
                  let medalSilver = coach[ci]?.silver + 1;
                  let medalBronze = coach[ci]?.bronze;
                  coach[ci] = {
                    coach: participant[p]?.coach,
                    gold: medalGold,
                    silver: medalSilver,
                    bronze: medalBronze,
                  };
                }
                if (fAllSetka[s]?.place === 4) {
                  let medalGold = coach[ci]?.gold;
                  let medalSilver = coach[ci]?.silver;
                  let medalBronze = coach[ci]?.bronze + 1;
                  coach[ci] = {
                    coach: participant[p]?.coach,
                    gold: medalGold,
                    silver: medalSilver,
                    bronze: medalBronze,
                  };
                }
              }
            }
          }
        }
      }
    }
    coach.sort(
      (a, b) => b.gold - a.gold || b.silver - a.silver || b.bronze - a.bronze
    );
    return coach;
  }, [fAllSetka, participant]);

  let MyChild = useMemo(() => {
    let f: (SType & participantType)[] = [];
    if (user?.position === 2) {
      f = mergedGrid.filter(
        (item: participantType) => item?.coach === user?.id
      );
    }
    return f;
  }, [mergedGrid, user]);

  let AssocChild = useMemo(() => {
    let f: (SType & participantType)[] = [];
    if (user?.position === 3) {
      for (let i = 0; i < subsAssocCoach.length; i++) {
        let childrens = mergedGrid.filter(
          (item: participantType) =>
            item?.coach === subsAssocCoach[i]?.following_id
        );
        f = [...f, ...childrens];
      }
    }
    return f;
  }, [mergedGrid, user, subsAssocCoach]);

  return (
    <div className="ContainerChampInfo">
      <div className="blueBackgroundChamp"></div>
      {/* <HeaderChamp /> */}
      <BodyChampInfo
        ch={ch}
        hu={hu}
        user={user}
        close_register={close_register}
        open_tournament={open_tournament}
        close_tournament={close_tournament}
        CS={CS}
        rC={rC}
        cC={cC}
        participant={participant}
      />
      <SelectParticipant
        fPartA={fPartA}
        fPartB={fPartB}
        fPartC={fPartC}
        ch={ch}
        setKtg={setKtg}
        categoryABC={categoryABC}
        user={user}
      />
      {categoryABC === "A" || categoryABC === "B" || categoryABC === "C" ? (
        <Category
          fAdmin={fAdmin}
          allSetka={allSetka}
          AdmissionAdmin={AdmissionAdmin}
          gender={gender}
          setGender={setGender}
          Part={Part}
          subsAllCoachChild={subsAllCoachChild}
          championship_id={championship_id}
          fCategoryChamp={fCategoryChamp}
          categoryABC={categoryABC}
          SubCategoryChamp={SubCategoryChamp}
          user={user}
          ch={ch}
        />
      ) : null}
      {categoryABC === "Coach" && (
        <div>
          {Coach.map((c, index) => (
            <CoachMap
              coach={c}
              login={login}
              participant={participant}
              championship_id={championship_id}
              index={index}
            />
          ))}
        </div>
      )}
      {categoryABC === "time" && (
        <Time
          fAdmin={fAdmin}
          mergedGrid={mergedGrid}
          championship_id={championship_id}
          championship={ch}
          user={user}
          fCategory={fCategory}
          fSubCategoryChamp={fSubCategoryChamp}
          login={login}
          MyChild={MyChild}
          AssocChild={AssocChild}
        />
      )}
      {categoryABC === "Report" && <Report mergedGrid={mergedGrid} />}
      {categoryABC === "participant" && (
        <MyParticipant
          participant={participant}
          Users={login}
          user={user}
          championship_id={championship_id}
          AdmissionAdmin={AdmissionAdmin}
        />
      )}
      <div className="leftovers"></div>
    </div>
  );
};

const HeaderChamp: FC<any> = () => {
  return (
    <div>
      <div className="HeaderChamp">
        <span>О турнире</span>
        <span>Участьники</span>
        <span>Сетка</span>
        <span>Создать сетку</span>
        <span>Изменить сетку</span>
      </div>
    </div>
  );
};

const BodyChampInfo: FC<any> = ({
  ch,
  hu,
  close_register,
  open_tournament,
  close_tournament,
  CS,
  rC,
  cC,
  user,
  participant,
}) => {
  const downloadPDF = async () => {
    const response = await api.post(
      Api + "api/auth/report/pdfChampionship",
      {
        championship_id: ch?.id,
      },
      { responseType: "blob" }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = "report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    const password = window.prompt(
      "Введите ваш пароль для подтверждения удаления:"
    );
    if (password) {
      dispatch(DeleteChampionships({ id: ch?.id, password }));
    }
  };
  return (
    <div id="bodyChampInfoContainer">
      <h2 className="nameChamp">{ch.name}</h2>
      {ch?.creator === user?.id && (
        <Link to={"/settingChampionship"}>
          <RiSettingsLine id="setttingIconChamp" />
        </Link>
      )}
      {ch?.creator === user?.id && (
        <button className="DeleteChampClick" onClick={handleDelete}>
          Удалить турнир
        </button>
      )}

      <div className="avaContainerChamp">
        {hu.ava && (
          <div id="avaClubChampRemove" className="ava">
            <img
              className="avaImg"
              src={Api + "storage/championship/avatar/" + ch.foto}
              alt=""
            />
          </div>
        )}
      </div>
      <div className="divBodyChamp">
        <span>
          <div>
            <div>Клуб:</div>
            <div className="f">{hu?.name}</div>
          </div>
        </span>
        <span>
          <div>
            <div>Категория: </div>
            <div className="f">
              {ch.ranking === 1 && "L1"}
              {ch.ranking === 2 && "L2"}
              {ch.ranking === 3 && "L3"}
              {ch.ranking === 4 && "L4"}
              {ch.ranking === 5 && "L5"}
              {ch.ranking === 6 && "L6"}
              {ch.ranking === 7 && "L7"}
              {ch.ranking === 8 && "L8"}
              {ch.ranking === 9 && "L9"}
              {ch.ranking === 10 && "W1"}
              {ch.ranking === 11 && "W2"}
              {ch.ranking === 12 && "W3"}
              {ch.ranking === 13 && "W4"}
              {ch.ranking === 14 && "W5"}
              {ch.ranking === 15 && "W6"}
              {ch.ranking === 16 && "W7"}
              {ch.ranking === 17 && "W8"}
              {ch.ranking === 18 && "W9"}
              {ch.ranking === 19 && "W10"}
            </div>
          </div>
        </span>

        <span>
          <div>
            <div>Регистрация заканчивается:</div>
            <div className="f">
              {close_register.getDate()}.{close_register.getMonth()}.
              {close_register.getFullYear()}г.
            </div>
          </div>
        </span>
        <span>
          <div>
            <div>Начало соревнований:</div>
            <div className="f">
              {open_tournament.getDate()}.{open_tournament.getMonth()}.
              {open_tournament.getFullYear()}г.
            </div>
          </div>
        </span>
        <span>
          <div>
            <div>Конечный день:</div>
            <div className="f">
              {close_tournament.getDate()}.{close_tournament.getMonth()}.
              {close_tournament.getFullYear()}г.
            </div>
          </div>
        </span>
        <span>
          <div className="plaseChamp">
            <div>
              <div>
                <MdOutlinePlace className="pCh" />
              </div>
              <div>{rC?.name}</div>
            </div>
            <div>
              <div>
                <MdOutlinePlace className="pCh" />
              </div>
              <div>г.{cC?.name}</div>
            </div>
          </div>
        </span>
        <span>
          <div>
            <div className="f">
              {participant?.length}/{ch?.restric}
            </div>
          </div>
        </span>
        <span>
          <input
            id="reportPDFChampionship"
            type="button"
            value="Скачать сетку PDF"
            onClick={downloadPDF}
          />
        </span>
      </div>
    </div>
  );
};

export const Coach: FC<any> = ({}) => {
  return <div></div>;
};

const SelectParticipant: FC<any> = ({
  fPartA,
  fPartB,
  fPartC,
  ch,
  setKtg,
  categoryABC,
  user,
}) => {
  let date = new Date();
  return (
    <div className="SettingMyChampContainer">
      <div className="selectParticipant">
        <div className="sportInfo">
          {ch.checkA === 1 && (
            <div
              className="nameChampCategory"
              id={categoryABC === "A" ? "click" : ""}
              onClick={(e) => setKtg("A")}
            >
              A{" "}
              {fPartA?.length > 0 ? (
                <main className="champPeopleLength">
                  <MdPeopleAlt className="champPeopleIcon" />
                  {fPartA?.length}
                </main>
              ) : (
                ""
              )}
            </div>
          )}
          {ch.checkB === 1 && (
            <div
              className="nameChampCategory"
              id={categoryABC === "B" ? "click" : ""}
              onClick={(e) => setKtg("B")}
            >
              B{" "}
              {fPartB?.length > 0 ? (
                <main className="champPeopleLength">
                  <MdPeopleAlt className="champPeopleIcon" />
                  {fPartB?.length}
                </main>
              ) : (
                ""
              )}
            </div>
          )}
          {ch.checkC === 1 && (
            <div
              className="nameChampCategory"
              id={categoryABC === "C" ? "click" : ""}
              onClick={(e) => setKtg("C")}
            >
              C{" "}
              {fPartC?.length > 0 ? (
                <main className="champPeopleLength">
                  <MdPeopleAlt className="champPeopleIcon" />
                  {fPartC?.length}
                </main>
              ) : (
                ""
              )}
            </div>
          )}
          <div
            className="nameChampCategory"
            id={categoryABC === "time" ? "click" : ""}
            onClick={(e) => setKtg("time")}
          >
            Время{" "}
          </div>
          <div
            className="nameChampCategory"
            id={categoryABC === "Coach" ? "click" : ""}
            onClick={(e) => setKtg("Coach")}
          >
            Лучшие тренеры{" "}
          </div>
          {ch?.creator === user?.id ? (
            <div
              className="nameChampCategory"
              id={categoryABC === "Report" ? "click" : ""}
              onClick={(e) => setKtg("Report")}
            >
              Отчёт{" "}
            </div>
          ) : null}
          {user?.position === 2 ? (
            <div
              className="nameChampCategory"
              id={categoryABC === "participant" ? "click" : ""}
              onClick={(e) => setKtg("participant")}
            >
              Мои участьники{" "}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

type CategoryType = {
  allSetka: SType[];
  AdmissionAdmin: AdmissionAdminsType[];
  championship_id: number;
  fCategoryChamp: createKTGType[];
  categoryABC: string;
  SubCategoryChamp: subCategoryType[];
  user: Users;
  ch: createChampType;
  subsAllCoachChild: subscribeType[];
  Part: participantType[];
  gender: number;
  setGender: any;
  fAdmin: AdminType[];
};

const Category: FC<CategoryType> = ({
  fAdmin,
  allSetka,
  AdmissionAdmin,
  gender,
  setGender,
  Part,
  subsAllCoachChild,
  championship_id,
  fCategoryChamp,
  categoryABC,
  SubCategoryChamp,
  user,
  ch,
}) => {
  const [name, setName] = useState("");
  const dispatch = useAppDispatch();
  const creator = ch?.creator;

  const [championship_category_id, setChampionship_category_id] = useState(0);
  const [championship_sub_category_id, setSubCategory] = useState(0);
  useEffect(() => {
    setChampionship_category_id(0);
    setSubCategory(0);
  }, [categoryABC, gender]);

  useEffect(() => {
    setSubCategory(0);
  }, [championship_category_id]);

  const addCategoryFunction = () => {
    if (isValid) {
      dispatch(
        AddCategory({ name, championship_id, categoryABC, creator, gender })
      );
    }
    setName("");
  };
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (categoryABC && name) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [categoryABC, name]);

  let fSubCategoryChamp = useMemo(() => {
    let f = SubCategoryChamp.filter(
      (item) =>
        item?.championship_id === championship_id &&
        item?.championship_category_id === championship_category_id
    );

    return f;
  }, [SubCategoryChamp, championship_id, championship_category_id]);

  const [subName, setSubName] = useState("");
  const [isValidSub, setIsValidSub] = useState(false);
  useEffect(() => {
    if (championship_category_id !== 0 && subName) {
      setIsValidSub(true);
    } else {
      setIsValidSub(false);
    }
  }, [championship_sub_category_id, subName]);

  const addSubCategoryFunction = () => {
    if (isValidSub) {
      const name = subName;
      dispatch(
        AddSubCategory({
          name,
          championship_id,
          championship_category_id,
          creator,
        })
      );
    }
    setSubName("");
  };

  const [textCategory, setTextCategory] = useState("");

  const updateTextCategory = (e: string, type: string) => {
    if (type === "category") {
      setTextCategory(e);
    }
    if (type === "subCategory") {
      setTextCategory(e);
    }
  };
  const [globalInput, setGlobalInput] = useState(0);

  return (
    <div className="SettingMyChampContainer">
      <div className="selectParticipant">
        <div className="sportInfo">
          <div
            className="nameChampCategory"
            id={gender === 1 ? "clickGenderMale" : ""}
            onClick={(e) => setGender(1)}
          >
            <FaMale
              id={gender === 1 ? "clickGenderMaleIcon" : ""}
              className="champGender"
            />
          </div>
          <div
            className="nameChampCategory"
            id={gender === 2 ? "clickGenderFemale" : ""}
            onClick={(e) => setGender(2)}
          >
            <FaFemale
              id={gender === 2 ? "clickGenderFemaleIcon" : ""}
              className="champGender"
            />
          </div>
        </div>
      </div>
      {user?.id === creator && (
        <div className="inputColumn">
          <input
            type="text"
            value={name}
            id="registerInput"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="button"
            id={isValid ? "submitIsValid" : ""}
            value={"Создать категорию"}
            onClick={addCategoryFunction}
            disabled={!isValid}
          />
        </div>
      )}
      <div className="selectParticipant">
        <div className="sportInfo">
          {fCategoryChamp.map((ctg) => (
            <CategoryMap
              gender={gender}
              creator={creator}
              user={user}
              Part={Part}
              globalInput={globalInput}
              setGlobalInput={setGlobalInput}
              ctg={ctg}
              ctgClick={setChampionship_category_id}
              championship_category_id={championship_category_id}
              textCategory={textCategory}
              updateTextCategory={updateTextCategory}
            />
          ))}
        </div>
      </div>
      {user?.id === creator &&
      categoryABC &&
      fCategoryChamp.length !== 0 &&
      championship_category_id ? (
        <div className="inputColumn">
          <input
            type="text"
            value={subName}
            id="registerInput"
            onChange={(e) => setSubName(e.target.value)}
          />
          <input
            type="button"
            id={isValidSub ? "submitIsValid" : ""}
            value={"Создать подкатегорию"}
            onClick={addSubCategoryFunction}
            disabled={!isValidSub}
          />
        </div>
      ) : null}
      <div className="selectParticipant">
        <div className="sportInfo">
          {fSubCategoryChamp.map((ctg) => (
            <SubCategoryMap
              gender={gender}
              creator={creator}
              user={user}
              Part={Part}
              globalInput={globalInput}
              setGlobalInput={setGlobalInput}
              ctg={ctg}
              ctgClick={setSubCategory}
              championship_sub_category_id={championship_sub_category_id}
              textCategory={textCategory}
              updateTextCategory={updateTextCategory}
            />
          ))}
        </div>
      </div>
      <Participant
        fAdmin={fAdmin}
        allSetka={allSetka}
        AdmissionAdmin={AdmissionAdmin}
        user={user}
        ch={ch}
        gender={gender}
        championship_id={championship_id}
        subsAllCoachChild={subsAllCoachChild}
        categoryABC={categoryABC}
        championship_sub_category_id={championship_sub_category_id}
        championship_category_id={championship_category_id}
        Part={Part}
      />
    </div>
  );
};

type CategoryMapType = {
  creator: number;
  user: Users;
  ctg: createKTGType;
  ctgClick: any;
  championship_category_id?: number;
  championship_sub_category_id?: number;
  updateTextCategory: any;
  textCategory: string;
  setGlobalInput: any;
  globalInput: number;
  Part: participantType[];
  gender: number;
};

export const CategoryMap: FC<CategoryMapType> = ({
  creator,
  user,
  Part,
  globalInput,
  setGlobalInput,
  textCategory,
  ctg,
  gender,
  ctgClick,
  championship_category_id,
  updateTextCategory,
}) => {
  const [Input, setInput] = useState(false);
  const dispatch = useAppDispatch();

  let fPart = useMemo(() => {
    let f = Part.filter(
      (item: participantType) => item?.championship_category_id === ctg?.id
    );
    return f;
  }, [Part, ctg]);

  const clickInput = () => {
    setGlobalInput(ctg?.id);
    if (creator === user?.id) {
      setInput(true);
    }
  };

  useEffect(() => {
    if (globalInput !== ctg?.id) {
      setInput(false);
    }
  }, [globalInput]);

  const clickCategory = () => {
    ctgClick(ctg?.id);
  };

  useEffect(() => {
    if (Input) {
      updateTextCategory(ctg?.name, "category");
    }
  }, [Input]);

  const deleteCategoryClick = () => {
    let del = window.confirm(
      `Точно хотите удалить категорию '${ctg?.name}'? Тогда будет удаляться все подкатегории которые вы создали!`
    );
    const id = ctg?.id;
    if (del) {
      dispatch(DeleteCategory({ id }));
    }
  };

  const updateClick = () => {
    const id = ctg?.id,
      name = textCategory;
    dispatch(UpdateCategory({ id, name }));
    setInput(false);
  };

  const clickCancel = () => {
    setInput(false);
    updateTextCategory("", "category");
  };

  return (
    <>
      {Input ? (
        <main>
          <input
            type="text"
            autoFocus
            id="champCategoryInput"
            value={textCategory}
            onChange={(e) => updateTextCategory(e.target.value, "category")}
          />
          <FaCheck onClick={updateClick} />
          <ImCancelCircle onClick={clickCancel} />
        </main>
      ) : (
        <div
          id={championship_category_id === ctg?.id ? "click" : ""}
          className="nameChampCategory"
          onClick={clickCategory}
        >
          {ctg?.name}{" "}
          {fPart?.length > 0 ? (
            <main
              className={
                gender === 1 ? "champPeopleLength" : "champPeopleLengthFemale"
              }
            >
              <MdPeopleAlt
                className={
                  gender === 1 ? "champPeopleIcon" : "champPeopleIconFemale"
                }
              />
              {fPart?.length}
            </main>
          ) : (
            ""
          )}{" "}
          {
            <main className="champRemoveCategoryIcons">
              {creator === user?.id && (
                <FaPencilAlt
                  className="PenChampCategory"
                  onClick={clickInput}
                />
              )}
              {creator === user?.id && (
                <MdDelete
                  className="PenChampCategory"
                  onClick={deleteCategoryClick}
                />
              )}
            </main>
          }
        </div>
      )}
    </>
  );
};

export const SubCategoryMap: FC<CategoryMapType> = ({
  creator,
  user,
  Part,
  globalInput,
  setGlobalInput,
  textCategory,
  ctg,
  ctgClick,
  championship_sub_category_id,
  updateTextCategory,
  gender,
}) => {
  const [Input, setInput] = useState(false);
  const dispatch = useAppDispatch();
  let fPart = useMemo(() => {
    let f = Part.filter(
      (item: participantType) => item?.championship_sub_category_id === ctg?.id
    );
    return f;
  }, [Part, ctg]);

  const clickInput = () => {
    setGlobalInput(ctg?.id);
    if (creator === user?.id) {
      setInput(true);
    }
  };

  useEffect(() => {
    if (globalInput !== ctg?.id) {
      setInput(false);
    }
  }, [globalInput]);

  const clickCategory = () => {
    ctgClick(ctg?.id);
  };

  useEffect(() => {
    if (Input) {
      updateTextCategory(ctg?.name, "subCategory");
    }
  }, [Input]);

  const deleteCategoryClick = () => {
    let del = window.confirm(
      `Точно хотите удалить подкатегорию '${ctg?.name}'?`
    );
    const id = ctg?.id;
    if (del) {
      dispatch(DeleteSubCategory({ id }));
    }
  };

  const updateClick = () => {
    const id = ctg?.id,
      name = textCategory;
    dispatch(UpdateSubCategory({ id, name }));
    setInput(false);
  };

  const clickCancel = () => {
    setInput(false);
    updateTextCategory("", "subCategory");
  };

  return (
    <>
      {Input ? (
        <main>
          <input
            type="text"
            autoFocus
            id="champCategoryInput"
            value={textCategory}
            onChange={(e) => updateTextCategory(e.target.value, "subCategory")}
          />
          <FaCheck onClick={updateClick} />
          <ImCancelCircle onClick={clickCancel} />
        </main>
      ) : (
        <div
          id={championship_sub_category_id === ctg?.id ? "click" : ""}
          className="nameChampCategory"
          onClick={clickCategory}
        >
          {ctg?.name}{" "}
          {fPart?.length > 0 ? (
            <main
              className={
                gender === 1 ? "champPeopleLength" : "champPeopleLengthFemale"
              }
            >
              <MdPeopleAlt
                className={
                  gender === 1 ? "champPeopleIcon" : "champPeopleIconFemale"
                }
              />
              {fPart?.length}
            </main>
          ) : (
            ""
          )}{" "}
          {
            <main className="champRemoveCategoryIcons">
              {creator === user?.id && (
                <FaPencilAlt
                  className="PenChampCategory"
                  onClick={clickInput}
                />
              )}
              {creator === user?.id && (
                <MdDelete
                  onClick={deleteCategoryClick}
                  className="PenChampCategory"
                />
              )}
            </main>
          }
        </div>
      )}
    </>
  );
};
