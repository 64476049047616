import { FC, useEffect, useState } from "react";
import "./style.css";
import { createKTGType } from "../../../../../../store/settingSlice";
import { CheckboxLeftRight } from "../../../../setting/checkboxLeftRight";
import { useAppDispatch } from "../../../../../../store/hook";
import {
  cordCategoryType,
  createCordCategory,
  deleteCordCategory,
} from "../../../../../../store/setkaSlice";
import { FaFemale, FaMale } from "react-icons/fa";

type SelectDuelType = {
  category: createKTGType;
  clickCordId: number | undefined;
  fCordCategory: cordCategoryType[];
};

export const SelectDuel: FC<SelectDuelType> = ({
  category,
  clickCordId,
  fCordCategory,
}) => {
  let ffCordCategory: cordCategoryType =
    fCordCategory.find(
      (item: cordCategoryType) =>
        item?.championship_category_id === category?.id
    ) || {};

  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (ffCordCategory?.championship_category_id === category?.id) {
      setIsChecked(true);
    }
  }, [ffCordCategory, category]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      dispatch(
        createCordCategory({
          championship_cord_id: clickCordId,
          championship_category_id: category?.id,
        })
      );
    } else {
      dispatch(deleteCordCategory({ id: ffCordCategory?.id }));
    }
  };

  return (
    <>
      {(ffCordCategory?.championship_category_id === undefined ||
        (ffCordCategory?.championship_category_id === category?.id &&
          ffCordCategory?.championship_cord_id === clickCordId)) && (
        <div>
          <CheckboxLeftRight
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
          />
          <div>
            {category?.name}
            {category?.gender === 1 ? (
              <FaMale
                className="champGender"
                id={category?.gender === 1 ? "clickGenderMaleIcon" : ""}
              />
            ) : (
              <FaFemale
                id={category?.gender === 2 ? "clickGenderFemaleIcon" : ""}
                className="champGender"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
