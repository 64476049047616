import { FC, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../../store/hook";
import { Payment } from "./payment";
import { Users } from "../../../../../store/loginSlice";
import { paymentType } from "../../../../../store/payment";

type PaymentContainerType = {
  User: Users;
  Userter: Users[];
};

export const PaymentContainer: FC<PaymentContainerType> = ({
  User,
  Userter,
}) => {
  const paymentStore = useAppSelector((state) => state.payment.payment);

  const [dateUp, setDateUp] = useState("");
  const updateDateUp = (e: any) => {
    setDateUp(e);
  };
  const [dateDown, setDateDown] = useState("");
  const updateDateDown = (e: any) => {
    setDateDown(e);
  };
  let g = new Date();

  useEffect(() => {
    setDateUp(g.toISOString().slice(0, -16) + "01");
    setDateDown(g.toISOString().slice(0, -14));
  }, []);

  let fP = useMemo(() => {
    let k: paymentType[] = [];
    for (let i = 0; i < paymentStore.length; i++) {
      if (
        paymentStore[i].created_at.toString().slice(0, -9) >= dateUp &&
        paymentStore[i].created_at.toString().slice(0, -9) <= dateDown
      ) {
        k = [...k, paymentStore[i]];
      }
    }
    return k;
  }, [dateUp, dateDown, paymentStore]);

  let amount = useMemo(() => {
    let m = 0;
    for (let i = 0; i < fP.length; i++) {
      m = m + Number(fP[i]?.amount);
    }
    return m;
  }, [fP]);

  return (
    <div className="containerPayment">
      <div className="containerDate">
        <main>
          <div>с</div>
          <input
            type="date"
            value={dateUp}
            onChange={(e) => updateDateUp(e.target.value)}
          />
        </main>
        <main>
          <div>до</div>
          <input
            type="date"
            value={dateDown}
            onChange={(e) => updateDateDown(e.target.value)}
          />
        </main>
      </div>
      <div>
        <div id={amount > 0 ? "checkPayment" : "checkPaymentRed"}>
          Сумма: {amount}
        </div>
      </div>
      {(User?.position === 2 || User?.position === 3) &&
        fP.map((paym: any) => <Payment paym={paym} Userter={Userter} />)}
    </div>
  );
};
