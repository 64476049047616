import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AnyAction,
} from "@reduxjs/toolkit";
import { api, instanceGET, instancePOST } from "./axiosInstans";
import { url } from "./url";

const Apiq = url();

export type SType = {
  id?: any;
  championship_id?: number;
  creator?: number;
  championship_participant_id?: number;
  i_grid?: number;
  i_inside_each_grid?: number;
  place?: number;
  point?: number;
  message?: string;
  allBracket?: any[];
};
export type SBoolType = {
  status?: boolean | string;
};
export type SetkaStateType = {
  S: SBoolType;
  setka: SType[];
  delete: any;
  loading: boolean;
  error: null;
  admins: AdminType[];
  admissionAdmin: AdmissionAdminsType[];
  message: string;
  championshipDay: DayChampionshipType[];
  championshipCord: cordChampionshipType[];
  championshipCordCategory: cordCategoryType[];
};
export type KGtype = {
  kg: string;
  ktg: string;
  years: string;
};
export type dispatchData = {
  KG: KGtype;
  idChild: string;
  indexS: string;
  createID: string;
  idChamp: string;
  indexArr: any;
};

export type AdminType = {
  id?: number;
  admin?: number;
  creator?: number;
};
export type AdmissionAdminsType = {
  id?: number;
  championship_id?: number;
  child?: number;
  allow?: number;
};
export type cordChampionshipType = {
  id?: number;
  championship_id?: number;
  creator?: number;
  championship_day_id?: number;
  start_time?: string;
  fight_duration?: string;
  current_fight?: number;
  current_fight_time?: string;
  name?: string;
};

export type DayChampionshipType = {
  id?: number;
  championship_id?: number;
  creator?: number;
  name?: string;
};

export type cordCategoryType = {
  id?: number;
  championship_cord_id?: number;
  championship_category_id?: number;
  championshipCordIds?: any[];
};

export const setkaADD = createAsyncThunk<
  SType[],
  SType[],
  { rejectValue: string }
>("setka/setkaADD", async function (allBracket, { rejectWithValue }) {
  try {
    console.log({ allBracket });
    const response: any = await api.post(
      Apiq + "api/auth/AddChampionshipGrid",
      allBracket
    );
    return (await response.data.data) as SType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const setkaDelete = createAsyncThunk<
  SType,
  SType,
  { rejectValue: string }
>("setka/setkaDelete", async function (
  {
    championship_id,
    creator,
    championship_participant_id,
    i_grid,
    i_inside_each_grid,
    place,
  },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
      creator,
      championship_participant_id,
      i_grid,
      i_inside_each_grid,
      place,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteChampionshipGrid",
      jsonS
    );
    return (await response.data.data) as SType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getSetka = createAsyncThunk<SType[], any, { rejectValue: string }>(
  "setka/getSetka",
  async function ({ championship_id }, { rejectWithValue }) {
    try {
      const jsonS = {
        championship_id,
      };
      const response: any = await api.post(
        Apiq + "api/auth/GetChampionshipGrid",
        jsonS
      );
      // dispatch(addsetka(response.data));
      return (await response.data.data) as SType[];
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const getPageGrid = createAsyncThunk<
  SType[],
  any,
  { rejectValue: string }
>("setka/getPageGrid", async function ({ child }, { rejectWithValue }) {
  try {
    const jsonS = {
      child,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetPageChampionshipGrid",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as SType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const addAdmin = createAsyncThunk<
  AdminType,
  AdminType,
  { rejectValue: string }
>("setka/addAdmin", async function ({ admin }, { rejectWithValue }) {
  try {
    const jsonS = {
      admin,
    };

    const response: any = await api.post(Apiq + "api/auth/AddAdmin", jsonS);
    return (await response.data.data) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getAdmin = createAsyncThunk<
  AdminType[],
  undefined,
  { rejectValue: string }
>("setka/getAdmin", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetAdmin");

    return (await response.data.data) as AdminType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const deleteAdmin = createAsyncThunk<
  AdminType,
  AdminType,
  { rejectValue: string }
>("setka/deleteAdmin", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(Apiq + "api/auth/DeleteAdmin", jsonS);
    return (await response.data.data) as AdminType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const AddAdmissionAdmins = createAsyncThunk<
  AdmissionAdminsType,
  AdmissionAdminsType,
  { rejectValue: string }
>("setka/AddAdmissionAdmins", async function (
  { championship_id, child, allow },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
      child,
      allow,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddAdmissionAdmins",
      jsonS
    );
    return (await response.data.data) as AdmissionAdminsType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const GetAdmissionAdmins = createAsyncThunk<
  AdmissionAdminsType[],
  AdmissionAdminsType,
  { rejectValue: string }
>("setka/GetAdmissionAdmins", async function (
  { championship_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/GetAdmissionAdmins",
      jsonS
    );
    return (await response.data.data) as AdmissionAdminsType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const DeleteAdmissionAdmins = createAsyncThunk<
  AdmissionAdminsType,
  AdmissionAdminsType,
  { rejectValue: string }
>("setka/DeleteAdmissionAdmins", async function (
  { id, championship_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      championship_id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteAdmissionAdmins",
      jsonS
    );
    return (await response.data.data) as AdmissionAdminsType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const updateWinner = createAsyncThunk<
  SType[],
  SType,
  { rejectValue: string }
>("setka/updateWinner", async function ({ id, place }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      place,
    };
    const response: any = await api.post(
      Apiq + "api/auth/WinnerChampionshipGrid",
      jsonS
    );
    if (response.data.data !== undefined) {
      return (await response.data.data) as SType[];
    } else {
      return response;
    }
  } catch (error) {
    return rejectWithValue("");
  }
});
export const reverbUpdateWinner = createAsyncThunk<
  SType[],
  SType,
  { rejectValue: string }
>("setka/updateWinner", async function (data, { rejectWithValue }) {
  return data as SType[];
});
export const reverbUpdateLoser = createAsyncThunk<
  SType[],
  SType,
  { rejectValue: string }
>("setka/updateLoser", async function (data, { rejectWithValue }) {
  return data as SType[];
});
export const updateLoser = createAsyncThunk<
  SType,
  SType,
  { rejectValue: string }
>("setka/updateLoser", async function ({ id, place }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      place,
    };

    const response: any = await api.post(
      Apiq + "api/auth/LoserChampionshipGrid",
      jsonS
    );
    console.log({ response });
    if (response.data.data !== undefined) {
      return (await response.data.data) as SType;
    } else {
      return response;
    }
  } catch (error) {
    return rejectWithValue("");
  }
});
export const createDay = createAsyncThunk<
  DayChampionshipType,
  DayChampionshipType,
  { rejectValue: string }
>("setka/createDay", async function (
  { championship_id, name },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
      name,
    };

    const response: any = await api.post(
      Apiq + "api/auth/CreateChampionshipDay",
      jsonS
    );
    return (await response.data.data) as DayChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getDay = createAsyncThunk<
  DayChampionshipType[],
  DayChampionshipType,
  { rejectValue: string }
>("setka/getDay", async function ({ championship_id }, { rejectWithValue }) {
  try {
    const jsonS = {
      championship_id,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetChampionshipDay",
      jsonS
    );

    return (await response.data.data) as DayChampionshipType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const updateDay = createAsyncThunk<
  DayChampionshipType,
  DayChampionshipType,
  { rejectValue: string }
>("setka/updateDay", async function ({ id, name }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      name,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateChampionshipDay",
      jsonS
    );
    return (await response.data.data) as DayChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const deleteDay = createAsyncThunk<
  DayChampionshipType,
  DayChampionshipType,
  { rejectValue: string }
>("setka/deleteDay", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteChampionshipDay",
      jsonS
    );
    return (await response.data.data) as DayChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});

export const createCord = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/createCord", async function (
  { championship_id, name, championship_day_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
      name,
      championship_day_id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/CreateChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getCord = createAsyncThunk<
  cordChampionshipType[],
  cordChampionshipType,
  { rejectValue: string }
>("setka/getCord", async function ({ championship_id }, { rejectWithValue }) {
  try {
    const jsonS = {
      championship_id,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetChampionshipCord",
      jsonS
    );

    return (await response.data.data) as cordChampionshipType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const reverbCord = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/currentFight", async function (data, { rejectWithValue }) {
  return data as cordChampionshipType;
});
export const updateCord = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/updateCord", async function ({ id, name }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      name,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const deleteCord = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/deleteCord", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const startTime = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/startTime", async function ({ id, start_time }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      start_time,
    };

    const response: any = await api.post(
      Apiq + "api/auth/StartTimeChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const fightDuration = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/fightDuration", async function (
  { id, fight_duration },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      fight_duration,
    };

    const response: any = await api.post(
      Apiq + "api/auth/FightDurationChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const CancelFight = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/currentFight", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/CancelFightChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const currentFight = createAsyncThunk<
  cordChampionshipType,
  cordChampionshipType,
  { rejectValue: string }
>("setka/currentFight", async function (
  { id, current_fight },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      current_fight,
    };

    const response: any = await api.post(
      Apiq + "api/auth/CurrentFightTimeChampionshipCord",
      jsonS
    );
    return (await response.data.data) as cordChampionshipType;
  } catch (error) {
    return rejectWithValue("");
  }
});

export const createCordCategory = createAsyncThunk<
  cordCategoryType,
  cordCategoryType,
  { rejectValue: string }
>("setka/createCordCategory", async function (
  { championship_cord_id, championship_category_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_cord_id,
      championship_category_id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/CreateCordCategory",
      jsonS
    );
    return (await response.data.data) as cordCategoryType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getCordCategory = createAsyncThunk<
  cordCategoryType[],
  cordCategoryType,
  { rejectValue: string }
>("setka/getCordCategory", async function (
  { championshipCordIds },
  { rejectWithValue }
) {
  try {
    const jsonS = { championshipCordIds };

    const response: any = await api.post(
      Apiq + "api/auth/GetCordCategories",
      jsonS
    );
    return (await response.data.data) as cordCategoryType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const deleteCordCategory = createAsyncThunk<
  cordCategoryType,
  cordCategoryType,
  { rejectValue: string }
>("setka/deleteCordCategory", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteCordCategory",
      jsonS
    );
    return (await response.data.data) as cordCategoryType;
  } catch (error) {
    return rejectWithValue("");
  }
});

const initialState: SetkaStateType = {
  S: { status: true },
  setka: [],
  delete: { status: false },
  loading: false,
  error: null,
  admins: [],
  admissionAdmin: [],
  message: "",
  championshipDay: [],
  championshipCord: [],
  championshipCordCategory: [],
};

const setkaSlice = createSlice({
  name: "setka",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setkaADD.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setkaADD.fulfilled, (state, action) => {
        state.loading = false;

        // Удаляем старые записи с теми же championship_id и championship_participant_id
        state.setka = state.setka.filter(
          (item) =>
            !action.payload.some(
              (newItem) =>
                newItem.championship_id === item.championship_id &&
                newItem.championship_participant_id ===
                  item.championship_participant_id
            )
        );

        // Добавляем новые данные из ответа
        state.setka = [...state.setka, ...action.payload];
      })
      .addCase(setkaDelete.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setkaDelete.fulfilled, (state, action) => {
        state.setka = state.setka.filter(
          (t: SType) => t.id !== action.payload?.id
        );
      })
      .addCase(getSetka.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSetka.fulfilled, (state, action) => {
        state.setka = action.payload;
      })
      .addCase(getAdmin.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.admins = action.payload;
      })
      .addCase(getPageGrid.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPageGrid.fulfilled, (state, action) => {
        let d: any[] = state.setka;
        let g: any[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: any[] = d.filter((item: any) => item?.id === g[i].id);
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.setka = d;
      })
      .addCase(deleteAdmin.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.admins = state.admins.filter(
          (t: SType) => t.id !== action.payload?.id
        );
      })
      .addCase(GetAdmissionAdmins.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAdmissionAdmins.fulfilled, (state, action) => {
        state.admissionAdmin = action.payload;
      })
      .addCase(AddAdmissionAdmins.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAdmissionAdmins.fulfilled, (state, action) => {
        let d: AdmissionAdminsType[] = state.admissionAdmin;
        let g: AdmissionAdminsType = action.payload;
        if (g?.id !== undefined) {
          if (d.length === 0) {
            d = [...d, g];
            state.admissionAdmin = d;
          } else {
            let k: AdmissionAdminsType[] = [];
            for (let i = 0; i < d.length; i++) {
              if (d[i]?.id === g?.id) {
                k = [...k, g];
              }
            }
            if (k.length === 0) {
              d = [...d, g];
              state.admissionAdmin = d;
            }
          }
        }
        state.loading = false;
      })
      .addCase(DeleteAdmissionAdmins.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteAdmissionAdmins.fulfilled, (state, action) => {
        state.admissionAdmin = state.admissionAdmin.filter(
          (t: AdmissionAdminsType) => t.id !== action.payload?.id
        );
      })
      .addCase(updateWinner.fulfilled, (state, action) => {
        let d: SType[] = action.payload;
        for (let i = 0; i < d.length; i++) {
          if (d[i]?.message) {
            state.message = d[i]?.message || "";
          } else {
            const updateApproved: SType =
              state.setka.find((todo) => todo.id === d[i].id) || {};
            updateApproved!.place = d[i].place;
            updateApproved!.point = d[i].point;
          }
        }
      })
      .addCase(updateLoser.fulfilled, (state, action) => {
        console.log({ p: action.payload });
        if (action.payload.message) {
          state.message = action.payload.message;
        } else {
          const updateApproved: SType =
            state.setka.find((todo) => todo.id === action.payload.id) || {};
          updateApproved!.place = action.payload.place;
          updateApproved!.point = action.payload.point;
          console.log({ updateApproved });
          console.log({ setka: state.setka });
        }
      })
      .addCase(createDay.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDay.fulfilled, (state, action) => {
        state.championshipDay.push(action.payload);
      })
      .addCase(updateDay.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDay.fulfilled, (state, action) => {
        const updateC =
          state.championshipDay.find((todo) => todo.id === action.payload.id) ||
          {};
        updateC!.name = action.payload.name;
      })
      .addCase(deleteDay.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDay.fulfilled, (state, action) => {
        let fCord = state.championshipCord.filter(
          (t: cordChampionshipType) =>
            t.championship_day_id === action.payload?.id
        );

        const idsToRemove = new Set(fCord?.map((c) => c.id) || []);

        state.championshipCordCategory = state.championshipCordCategory.filter(
          (t: cordCategoryType) => !idsToRemove.has(t?.championship_cord_id)
        );
        state.championshipCord = state.championshipCord.filter(
          (t: cordChampionshipType) =>
            t.championship_day_id !== action.payload?.id
        );
        state.championshipDay = state.championshipDay.filter(
          (t: DayChampionshipType) => t.id !== action.payload?.id
        );
        console.log({ lolo: state.championshipCordCategory });
      })
      .addCase(getDay.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDay.fulfilled, (state, action) => {
        let d: DayChampionshipType[] = state.championshipDay;
        let g: DayChampionshipType[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: DayChampionshipType[] = d.filter(
            (item: DayChampionshipType) => item?.id === g[i].id
          );
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.championshipDay = d;
      })
      .addCase(createCord.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCord.fulfilled, (state, action) => {
        state.championshipCord.push(action.payload);
      })
      .addCase(updateCord.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCord.fulfilled, (state, action) => {
        const updateC =
          state.championshipCord.find(
            (todo) => todo.id === action.payload.id
          ) || {};
        updateC!.name = action.payload.name;
      })
      .addCase(deleteCord.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCord.fulfilled, (state, action) => {
        state.championshipCord = state.championshipCord.filter(
          (t: cordChampionshipType) => t.id !== action.payload?.id
        );
        state.championshipCordCategory = state.championshipCordCategory.filter(
          (t: cordCategoryType) => t.championship_cord_id !== action.payload?.id
        );
      })
      .addCase(getCord.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCord.fulfilled, (state, action) => {
        state.championshipCord = action.payload;
      })
      .addCase(startTime.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startTime.fulfilled, (state, action) => {
        const updateC =
          state.championshipCord.find(
            (todo) => todo.id === action.payload.id
          ) || {};
        updateC!.start_time = action.payload.start_time;
      })
      .addCase(fightDuration.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fightDuration.fulfilled, (state, action) => {
        const updateC =
          state.championshipCord.find(
            (todo) => todo.id === action.payload.id
          ) || {};
        updateC!.fight_duration = action.payload.fight_duration;
      })
      .addCase(currentFight.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(currentFight.fulfilled, (state, action) => {
        const updateC =
          state.championshipCord.find(
            (todo) => todo.id === action.payload.id
          ) || {};
        updateC!.current_fight = action.payload.current_fight;
        updateC!.current_fight_time = action.payload.current_fight_time;
      })
      .addCase(createCordCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCordCategory.fulfilled, (state, action) => {
        state.championshipCordCategory.push(action.payload);
      })
      .addCase(deleteCordCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCordCategory.fulfilled, (state, action) => {
        state.championshipCordCategory = state.championshipCordCategory.filter(
          (t: cordCategoryType) => t.id !== action.payload?.id
        );
        state.championshipCordCategory = state.championshipCordCategory.filter(
          (t: cordCategoryType) => t.id !== action.payload?.id
        );
      })
      .addCase(getCordCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCordCategory.fulfilled, (state, action) => {
        state.championshipCordCategory = action.payload;
      })
      .addMatcher(isError, (state, action: any) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// const { addsetka, getSetkiler } = setkaSlice.actions;

export default setkaSlice.reducer;
const isError = (action: AnyAction) => {
  return action.type.endsWith("rejectad");
};
