import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { BsCalendarWeek } from "react-icons/bs";
import { FaMedal } from "react-icons/fa";
import { TiFlowChildren } from "react-icons/ti";
import { Homework } from "./homeWork/page";
import { MyResult } from "../championship/myResult/page";
import { WorldChampionships } from "../championship/worldChampionships";
import { RiSettings2Line } from "react-icons/ri";
import { Setting } from "./setting";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import {
  SubscribeDelete,
  subscribeADD,
  subscribeType,
} from "../../../store/subscribeSlice";
import { MdGrade } from "react-icons/md";
import { Grade } from "./grade/page";
import { Children } from "./children/page";
import {
  AssociationType,
  Users,
  getAssociation,
  getProfileId,
} from "../../../store/loginSlice";
import { url } from "../../../store/url";
import { SubscribeShow } from "./components/subscribeShow";
import { GradeType, getGrade, pageOpenGrade } from "../../../store/gradeSlice";
import { notDublicate } from "../../notDublicate";

export const ProfileUp: FC<any> = ({
  id,
  subscribed,
  name,
  login,
  ava,
  uploader,
  pageData,
  userLogin,
  position,
  association,
}) => {
  const imageRef = useRef(null);
  const [medal, setMedal] = useState<any>(true);
  const [trophy, setTrophy] = useState<any>(false);
  const [grade, setGrade] = useState<any>(false);
  const [calendar, setCalendar] = useState<any>(false);
  const [childr, setChildr] = useState<any>(false);
  const [nastroika, setNastroika] = useState<any>(false);
  const dispatch = useAppDispatch();
  const Userter = useAppSelector((state: any) => state.login.log);
  const User = useAppSelector((state: any) => state.login.user);
  const [showSubs, setShowSubs] = useState(false);
  const [showSubsPage, setShowSubsPage] = useState(false);
  const [showAllCoachChild, setShowAllCoachChild] = useState(false);
  const [showAllCoach, setShowAllCoach] = useState(false);
  const [showAssoc, setShowAssoc] = useState(false);
  const Token = useAppSelector((state: any) => state.login.Token);
  const UsersStatus = useAppSelector((state: any) => state.login.UsersStatus);
  const nameAssociation = useAppSelector(
    (state: any) => state.login.Association
  );

  useEffect(() => {
    if (nameAssociation.length === 0) {
      dispatch(getAssociation());
    }
  }, []);

  let pageAssoc = useMemo(() => {
    let f: AssociationType =
      nameAssociation.find((item: any) => item?.id === association) || {};

    return f;
  }, [association, nameAssociation]);

  const clickShowSubs = () => setShowSubs(true);
  const clickShowSubsPage = () => setShowSubsPage(true);
  const clickShowAllCoachChild = () => setShowAllCoachChild(true);
  const clickShowAllCoach = () => setShowAllCoach(true);
  const clickShowAssoc = () => setShowAssoc(true);

  const clickMedal = () => {
    setCalendar(false);
    setTrophy(false);
    setMedal(true);
    setGrade(false);
    setChildr(false);
  };
  const clickTrophy = () => {
    setCalendar(false);
    setMedal(false);
    setTrophy(true);
    setGrade(false);
    setChildr(false);
  };
  const clickGrade = () => {
    setCalendar(false);
    setMedal(false);
    setTrophy(false);
    setGrade(true);
    setChildr(false);
  };
  const clickCalendar = () => {
    setMedal(false);
    setTrophy(false);
    setCalendar(true);
    setGrade(false);
    setChildr(false);
  };
  const clickChild = () => {
    setCalendar(false);
    setMedal(false);
    setTrophy(false);
    setGrade(false);
    setChildr(true);
  };
  const SettingClick = () => {
    setNastroika(true);
  };
  const SettingClose = () => {
    setNastroika(false);
    setShowSubs(false);
    setShowSubs(false);
    setShowSubsPage(false);
    setShowAllCoachChild(false);
    setShowAllCoach(false);
    setShowAssoc(false);
  };

  let userLog = false;
  if (login == userLogin) {
    userLog = true;
  }

  let Api = url();
  let date = new Date();
  let day = date.getDate();
  let NewMonth = date.getMonth();
  let NewYear = date.getFullYear();

  const subsAdd = () => {
    dispatch(subscribeADD({ id, type: 1 }));
  };
  const SubDelete = () => {
    dispatch(SubscribeDelete({ id, type: 1 }));
  };

  const subscribeCoach = () => {
    dispatch(subscribeADD({ id, type: 2 }));
  };
  const SubCoachDelete = () => {
    dispatch(SubscribeDelete({ id, type: 2 }));
  };

  const subscribeAssoc = () => {
    dispatch(subscribeADD({ id, type: 3 }));
  };
  const SubAssocDelete = () => {
    dispatch(SubscribeDelete({ id, type: 3 }));
  };
  let loadingSubs: boolean = useAppSelector(
    (state: any) => state.subscribe.loading
  );

  //subscribe\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  const subscribe = useAppSelector((state) => state.subscribe.subscribe);
  let subs = useMemo(() => {
    const f = subscribe.filter(
      (item: subscribeType) =>
        item?.follower_id === pageData && item?.type === 1
    );

    return f;
  }, [pageData, subscribe]);

  let subsPage = useMemo(() => {
    const f = subscribe.filter(
      (item: subscribeType) =>
        item?.following_id === pageData && item?.type === 1
    );

    return f;
  }, [pageData, subscribe]);

  const loadingSubscribe = useAppSelector(
    (state: any) => state.subscribe.loadingSubscribe
  );
  const statePageOpen = useAppSelector(
    (state: any) => state.subscribe.statePageOpen
  );

  let subsLL = useMemo(() => {
    let f = subscribe.filter(
      (item: subscribeType) =>
        item?.follower_id === id &&
        item?.following_id === subscribed &&
        item?.type === 1
    );

    return f;
  }, [subscribed, id, subscribe]);

  let fps = useMemo(() => {
    let f: string[] = statePageOpen.filter((item: string) => item === pageData);

    return f;
  }, [pageData, statePageOpen]);

  useEffect(() => {
    if (
      fps.length === 0 &&
      !loadingSubscribe &&
      pageData !== undefined &&
      Token === true
    ) {
      // dispatch(getSubscribe({ id }));
      // dispatch(pageOpenSubs({ id }));
    }
  }, [pageData, loadingSubscribe, Token, fps]);

  // trenerlerdin barlik okushilarin tabu
  let subsC = useMemo(() => {
    const f: subscribeType[] = subscribe.filter(
      (item: subscribeType) =>
        item?.follower_id === pageData &&
        item?.following_id === subscribed &&
        item?.type === 2
    );

    return f;
  }, [subscribe, pageData, subscribed]);

  let subsAllCoachChild = useMemo(() => {
    let f: subscribeType[] = [];
    if (position === 2) {
      f = subscribe.filter(
        (item: subscribeType) =>
          item?.follower_id === pageData && item?.approved === 1
      );
      f = notDublicate(f);
    }
    return f;
  }, [subscribe, pageData]);

  let subscribes = useMemo(() => {
    let f: subscribeType[] = [];
    if (position === 2) {
      f = subscribe.filter(
        (item: subscribeType) => item?.follower_id === pageData
      );
    }
    if (position === 3) {
      f = subscribe.filter(
        (item: subscribeType) => item?.follower_id === pageData
      );
    }

    return f;
  }, [subscribe, pageData]);

  const f = useMemo(() => {
    let d: subscribeType[] = [];
    if (position === 2) {
      d = subscribe.filter(
        (item: subscribeType) =>
          item?.follower_id === pageData &&
          item?.approved === null &&
          item?.type === 2
      );
    }
    if (position === 3) {
      d = subscribe.filter(
        (item: subscribeType) =>
          item?.follower_id === pageData &&
          item?.approved === null &&
          item?.type === 3
      );
    }

    return d;
  }, [subscribe, pageData]);

  let association_id: string | null = null; // trener men associationnin id

  const subsA = useMemo(() => {
    if (position === 3) {
      let f = subscribe.filter(
        (item: subscribeType) =>
          item?.follower_id === pageData &&
          item?.following_id === subscribed &&
          item?.type === 3
      );
      return f;
    }
    return [];
  }, [subscribe, pageData, subscribed]);

  let subsAllAssoc = useMemo(() => {
    if (position === 3) {
      let subsAssoc = subscribe.filter(
        (item: subscribeType) =>
          item?.follower_id === pageData &&
          item?.approved === 1 &&
          item?.type === 3
      );
      const f = notDublicate(subsAssoc);
      return f;
    }
    return [];
  }, [subscribe, pageData]);

  association_id = pageData;

  /////////////////////////////////trener\\\\\\\\\\\\\\\\\\\\\\\\\\
  // trenerdin Associasin tabamiz
  let filterAssocSubs = useMemo(() => {
    let f: subscribeType[] = subscribe.filter(
      (item: subscribeType) =>
        item?.following_id === pageData && item?.approved === 1
    );
    f = notDublicate(f);
    return f;
  }, [subscribe, pageData]);

  let { MyAssocForCoach, arrAssoc_id } = useMemo(() => {
    let f: any = [];
    let d: any = [];
    if (position === 2 && filterAssocSubs.length > 0) {
      for (let i = 0; i < filterAssocSubs.length; i++) {
        f = [...f, filterAssocSubs[i]];
        d = [...d, filterAssocSubs[i]?.follower_id];
      }
    }

    return { MyAssocForCoach: f, arrAssoc_id: d };
  }, [filterAssocSubs]);

  let loading_Login = useAppSelector((state: any) => state.login.loading_Login);
  ////////////////////association\\\\\\\\\\\\\\\\
  // assoctin barlik trenerlerin taby

  // useEffect(() => {
  //   if (position === 3) {
  //     for (let p = 0; p < subsAllAssoc.length; p++) {
  //       let id = subsAllAssoc[p].following_id;
  //       dispatch(getSubscribeType({ id, type: 2 })); // associcianin okushilarin bazaga zhiberu
  //     }
  //   }
  // }, [subscribe]); ...

  let arrChildAssoc: any[] = [];
  const p: number[] = useMemo(() => {
    if (position === 3) {
      for (let p = 0; p < subsAllAssoc.length; p++) {
        for (let t = 0; t < subscribe.length; t++) {
          if (
            subscribe[t]?.follower_id === subsAllAssoc[p]?.following_id &&
            subscribe[t]?.approved === 1 &&
            subscribe[t]?.type === 2
          ) {
            arrChildAssoc = [...arrChildAssoc, subscribe[t].following_id]; // associcianin okushilarin tabu
          }
        }
      }
    }
    return arrChildAssoc;
  }, [subscribe, subsAllAssoc, position]);

  // assoctin trenerlerinin oqushilari
  let subsCh: number[] = [];
  if (position === 3) {
    subsCh = p;
  }
  let k: any = useMemo(() => {
    let d: any[] = [];
    for (let i = 0; i < subsAllCoachChild.length; i++) {
      d = [...d, subsAllCoachChild[i]?.following_id];
    }
    return d;
  }, [subsAllCoachChild]);

  // trenerdin okushilarin id-lerin shigaru
  if (position === 2) {
    subsCh = k;
  }

  /////////////////// okushilar ///////////////////////////////
  // okushinin trenerin tabamiz
  let filterCoachSubs = useMemo(() => {
    let f: subscribeType[] = subscribe.filter(
      (item: subscribeType) =>
        item?.following_id === pageData &&
        item?.approved === 1 &&
        item?.type === 2
    );
    f = notDublicate(f);

    return f;
  }, [subscribe, pageData]);

  let MyCoachForChild: subscribeType[] = [];
  MyCoachForChild = useMemo(() => {
    if (position === 1 && filterCoachSubs.length > 0) {
      for (let i = 0; i < filterCoachSubs.length; i++) {
        MyCoachForChild = [...MyCoachForChild, filterCoachSubs[i]];
        let id = filterCoachSubs[i]?.follower_id;
        // dispatch(getSubscribeType({ id, type: 3 })); // trenerdin associasin tabu ...
      }
    }

    return MyCoachForChild;
  }, [subscribe, filterCoachSubs]);

  let arrChildCoachAssoc_id: any[] = useMemo(() => {
    let a: any[] = [];

    for (let r = 0; r < MyCoachForChild.length; r++) {
      let rr: subscribeType[] = subscribe.filter(
        (item: subscribeType) =>
          item?.following_id === MyCoachForChild[r].follower_id &&
          item?.approved === 1 &&
          item?.type === 3
      );
      for (let rrr = 0; rrr < rr.length; rrr++) {
        a = [...a, rr[rrr]?.follower_id];
      }
    }
    return a;
  }, [subscribe, MyCoachForChild]);

  arrChildCoachAssoc_id = notDublicate(arrChildCoachAssoc_id);

  const functionGetGrade = (id: any) => {
    dispatch(getGrade({ id }));
    dispatch(pageOpenGrade({ id }));
  };
  let statePageGrade = useAppSelector(
    (state: any) => state.grade.statePageGrade
  );
  let fchild: any[] = statePageGrade.filter((item: any) => item === pageData);
  useEffect(() => {
    if (fchild.length === 0) {
      functionGetGrade(pageData);
    }
  }, [statePageGrade, pageData]);
  const gradeAll: GradeType[] = useAppSelector(
    (state: any) => state.grade.grade
  );

  return (
    <>
      {UsersStatus === true && Token === false && <Navigate to={"/signin"} />}
      <div className="nameLogin">
        <div className="nameL">
          <div>
            {position === 2 && <main className="position">Coach</main>}
            {position === 3 &&
              association !== null &&
              association !== undefined && (
                <main className="position">{pageAssoc?.name}</main>
              )}
          </div>
          <div>{login}</div>
          <div className="setting">
            {userLog && (
              <RiSettings2Line className="IconSetting" onClick={SettingClick} />
            )}
          </div>
        </div>

        {/* <div className="fonBisness">
                </div> */}
      </div>
      {/* <div className="fixedFonBisness"><img className="fonImg" src="https://www.profguide.io/images/article/a/48/mEKfFaJvd6.webp" alt="" /></div> */}

      {nastroika && <Setting position={position} />}
      {showSubsPage && (
        <SubscribeShow
          subs={subsPage}
          Userter={Userter}
          showSubsPage={showSubsPage}
        />
      )}
      {showAllCoachChild && (position === 3 || position === 2) && (
        <SubscribeShow
          subs={position === 2 ? subsAllCoachChild : p}
          Userter={Userter}
          showAllCoachChild={showAllCoachChild}
          position={position}
        />
      )}
      {showAllCoach && (
        <SubscribeShow
          subs={position === 3 ? subsAllAssoc : filterCoachSubs}
          pageData={pageData}
          gradeAll={gradeAll}
          Userter={Userter}
          showAllCoach={showAllCoach}
          position={position}
        />
      )}
      {showAssoc && position !== 3 && (
        <SubscribeShow
          subs={position === 2 ? filterAssocSubs : arrChildCoachAssoc_id}
          pageData={pageData}
          gradeAll={gradeAll}
          position={position}
          Userter={Userter}
          showAssoc={showAssoc}
        />
      )}
      {showSubs && (
        <SubscribeShow subs={subs} Userter={Userter} showSubs={showSubs} />
      )}
      {(showSubsPage ||
        showAllCoachChild ||
        showAllCoach ||
        showAssoc ||
        showSubs ||
        nastroika) && (
        <div onClick={SettingClose} className="settingAbsolut"></div>
      )}
      <div className="inputFile">
        <div className="avaContainer">
          <div className="ava">
            {ava ? (
              <img
                className="avaImg"
                ref={imageRef}
                src={Api + "storage/avatar/aver_" + ava}
                alt=""
              />
            ) : (
              <img
                className="avaImg"
                ref={imageRef}
                src={"https://yaponaroll.ru/images/20200701_001.jpg"}
                alt=""
              />
            )}
            {userLog && (
              <label>
                <input
                  name="ava"
                  className="addFoto"
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    uploader(e);
                  }}
                />
                <div className="sloyAva"></div>
              </label>
            )}
          </div>
        </div>

        {/* <IoSettingsOutline  className="optionIcon" /> */}
        <div className="subscribe">
          <div>
            {position !== 3 && (
              <div onClick={clickShowAssoc}>
                <div>
                  {position === 2
                    ? filterAssocSubs.length
                    : arrChildCoachAssoc_id.length}
                </div>
                <div>Ассоциация</div>
              </div>
            )}
            {position !== 2 && (
              <div onClick={clickShowAllCoach}>
                <div>
                  {position === 3
                    ? subsAllAssoc.length
                    : filterCoachSubs.length}
                </div>
                <div>Учителей</div>
              </div>
            )}
            {(position === 2 || position === 3) && (
              <div onClick={clickShowAllCoachChild}>
                <div>
                  {position === 2 ? subsAllCoachChild.length : p.length}
                </div>
                <div>Учеников</div>
              </div>
            )}
          </div>
          <div>
            <div onClick={clickShowSubs}>
              <div>{subs.length}</div>
              <div>Подписчиков</div>
            </div>
            <div onClick={clickShowSubsPage}>
              <div>{subsPage.length}</div>
              <div>Подписки</div>
            </div>
          </div>
          {userLog && (
            <Link className="option" to="/remove">
              <ul className="optiondiv">Изменить профиль</ul>
            </Link>
          )}
          {pageData !== subscribed &&
            (subsLL.length === 0 ? (
              <input
                type="button"
                className="subsInput"
                onClick={subsAdd}
                value="Подписаться"
              />
            ) : (
              <input
                className="subsInput"
                type="button"
                onClick={SubDelete}
                value="Отписаться"
              />
            ))}
          {pageData !== subscribed &&
            position === 2 &&
            User?.position === 1 &&
            (subsC.length === 0 ? (
              <input
                type="button"
                id="orange"
                className="subsInput"
                onClick={subscribeCoach}
                value="Мой тренер"
              />
            ) : (
              <input
                id="orange"
                className="subsInput"
                type="button"
                onClick={SubCoachDelete}
                value="Отменить тренера"
              />
            ))}
          {pageData !== subscribed &&
            position === 3 &&
            User?.position === 2 &&
            (subsA.length === 0 ? (
              <input
                className="subsInput"
                id="orange"
                type="button"
                onClick={subscribeAssoc}
                value="Обьядиниться"
              />
            ) : (
              <input
                id="orange"
                className="subsInput"
                type="button"
                onClick={SubAssocDelete}
                value="Отказаться"
              />
            ))}
          {/* {pageData !== subscribed && (
            <Link id="gray" className="subsInput" to={"/massenger/" + pageData}>
              Написать
            </Link>
          )} */}
        </div>
      </div>
      <div className="characteristic">
        <div>{name}</div>
        {/* <div className="sportInfo">
                    {filterAssocSubs.map((s) => (
                        <AssocData s={s} Userter={Userter} />
                    ))}
                </div>
                <div>
                    <div>Таеквондо</div>
                    <div>SC: "Aqtobe Namys"</div>
                    <div>Тренер: Сабуов Актан</div>
                </div> */}
      </div>

      <div className="miniMenu">
        <ul className="headerUL" id="iconHeadeLi">
          <li>
            <FaMedal
              className="transparent"
              fill={medal && "#18c0dd"}
              onClick={clickMedal}
            />
          </li>
          {pageData === User?.id && (
            <li className="headerMob">
              <svg
                onClick={clickTrophy}
                stroke-width="0"
                viewBox="0 0 24 24"
                className="transparent"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                fill="white"
              >
                <path
                  fill="none"
                  stroke={trophy && "#18c0dd"}
                  stroke-width="2"
                  d="M12,15 C8.686,15 6,12.314 6,9 L6,1 L18,1 L18,9 C18,12.314 15.314,15 12,15 Z M6,3 L1,3 L1,7 C1,9.509 2.791,11 5,11 L6,11 L6,3 Z M18,11 L19,11 C21.209,11 23,9.509 23,7 L23,3 L18,3 L18,11 Z M5,23 L19,23 L19,19 L5,19 L5,23 Z M16,19 C16,16.790861 14.209139,15 12,15 C9.790861,15 8,16.790861 8,19"
                ></path>
              </svg>
            </li>
          )}
          <li className="headerMob">
            <BsCalendarWeek
              className="transparent"
              fill={calendar && "#18c0dd"}
              onClick={clickCalendar}
            />
          </li>
          <li className="headerMob">
            <svg
              className="transparent"
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 640 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M353.8 54.1L330.2 6.3c-3.9-8.3-16.1-8.6-20.4 0L286.2 54.1l-52.3 7.5c-9.3 1.4-13.3 12.9-6.4 19.8l38 37-9 52.1c-1.4 9.3 8.2 16.5 16.8 12.2l46.9-24.8 46.6 24.4c8.6 4.3 18.3-2.9 16.8-12.2l-9-52.1 38-36.6c6.8-6.8 2.9-18.3-6.4-19.8l-52.3-7.5zM256 256c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V288c0-17.7-14.3-32-32-32H256zM32 320c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H160c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zm416 96v64c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V416c0-17.7-14.3-32-32-32H480c-17.7 0-32 14.3-32 32z"></path>
            </svg>
          </li>
          {(position === 2 || position === 3) && pageData === User?.id && (
            <li className="headerMob">
              <TiFlowChildren
                className="transparent"
                fill={childr && "#18c0dd"}
                onClick={clickChild}
              />
            </li>
          )}
          <li className="headerMob">
            <MdGrade
              className="transparent"
              fill={grade && "#18c0dd"}
              onClick={clickGrade}
            />
          </li>

          {/* {profmap.map((prof) => (
                    <li>
                        <Link to={'/' + prof.login}>{prof.login}</Link>
                    </li>
                    ))} */}
        </ul>
      </div>
      {medal && <MyResult ava={ava} pageData={pageData} />}
      {trophy && <WorldChampionships />}
      {calendar && (
        <Homework
          MyCoachForChild={MyCoachForChild}
          MyAssocForCoach={MyAssocForCoach}
          arrChildCoachAssoc_id={arrChildCoachAssoc_id}
          subsAllCoachChild={subsAllCoachChild}
          position={position}
          pageData={pageData}
          Userter={Userter}
          User={User}
        />
      )}
      {childr && (
        <Children
          date={date}
          subsAllCoachChild={subsAllCoachChild}
          position={position}
          pageData={pageData}
          subsCh={subsCh}
          Userter={Userter}
          User={User}
          subsAllAssoc={subsAllAssoc}
          f={f}
          p={p}
        />
      )}
      {grade && (
        <Grade
          NewMonth={NewMonth}
          NewYear={NewYear}
          arrChildCoachAssoc_id={arrChildCoachAssoc_id}
          MyCoachForChild={MyCoachForChild}
          subsCh={subsCh}
          arrAssoc_id={arrAssoc_id}
          association_id={association_id}
          position={position}
          pageData={pageData}
          Userter={Userter}
          User={User}
          subscribed={subscribed}
          subsAllAssoc={subsAllAssoc}
          p={p}
        />
      )}
    </>
  );
};
