import { createSlice, createAsyncThunk, AnyAction } from "@reduxjs/toolkit";
import { api } from "./axiosInstans";
import { url } from "./url";

let Apiq = url();

export type subscribeType = {
  id?: any;
  follower_id?: number;
  following_id?: number;
  pageData?: any;
  subscribed?: any;
  date?: any;
  approved?: any;
  status?: any;
  type?: any;
};

export const subscribeADD = createAsyncThunk<
  any,
  subscribeType,
  { rejectValue: string }
>("subscribe/subscribeADD", async function ({ id, type }, { rejectWithValue }) {
  if (id) {
    try {
      const jsonS = {
        id,
        type,
      };
      const response: any = await api.post(Apiq + "api/auth/followed", jsonS);
      return (await response.data.data) as any;
    } catch (error) {
      return rejectWithValue("");
    }
  }
});
export const SubscribeDelete = createAsyncThunk<
  any,
  any,
  { rejectValue: string }
>("subscribe/SubscribeDelete", async function (
  { id, type },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      type,
    };
    console.log({ jsonS });
    const response: any = await api.post(Apiq + "api/auth/unfollowed", jsonS);
    // dispatch(addsetka(response.data));
    return (await response.data.data) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getSubscribe = createAsyncThunk<
  subscribeType[],
  undefined,
  { rejectValue: string }
>("subscribe/getSubscribe", async function (_, { rejectWithValue }) {
  try {
    // const jsonS = {
    //   id,
    // };
    const response: any = await api.post(Apiq + "api/auth/subscribe");
    return (await response.data.data) as subscribeType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const pageOpenSubs = createAsyncThunk<any, any, { rejectValue: string }>(
  "subscribe/pageOpenSubs",
  async function ({ id }, { rejectWithValue }) {
    try {
      return (await id) as any;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);
export const getSubscribeType = createAsyncThunk<
  subscribeType[],
  subscribeType,
  { rejectValue: string }
>("subscribe/getSubscribeType", async function (
  { id, type },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      type,
    };
    const response: any = await api.post(Apiq + "api/auth/subscribe", jsonS);
    return (await response.data.data) as subscribeType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const addApproved = createAsyncThunk<
  subscribeType,
  subscribeType,
  { rejectValue: string }
>("subscribe/addApproved", async function ({ id, type }, { rejectWithValue }) {
  try {
    const status = true;
    const forget = false;
    const jsonS = {
      id,
      type,
      status,
      forget,
    };

    const response: any = await api.post(Apiq + "api/auth/approved", jsonS);

    return (await response.data.data) as subscribeType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const DeleteApproved = createAsyncThunk<
  subscribeType,
  subscribeType,
  { rejectValue: string }
>("subscribe/DeleteApproved", async function (
  { id, type },
  { rejectWithValue }
) {
  try {
    const status = false;
    const forget = false;
    const jsonS = {
      id,
      type,
      status,
      forget,
    };

    const response: any = await api.post(Apiq + "api/auth/approved", jsonS);

    return (await response.data.data) as subscribeType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const forgetApproved = createAsyncThunk<
  subscribeType,
  subscribeType,
  { rejectValue: string }
>("subscribe/forgetApproved", async function (
  { id, type },
  { rejectWithValue }
) {
  try {
    const status = false;
    const forget = true;
    const jsonS = {
      id,
      type,
      status,
      forget,
    };

    const response: any = await api.post(Apiq + "api/auth/approved", jsonS);

    return (await response.data) as subscribeType;
  } catch (error) {
    return rejectWithValue("");
  }
});

export type subsStateType = {
  subscribe: subscribeType[];
  statePageOpen: string[];
  loadingSubscribe: boolean;
  loading: boolean;
  error: any;
  statePageOpenChildCoach: string[];
};

const initialState: subsStateType = {
  subscribe: [],
  statePageOpen: [],
  loading: false,
  loadingSubscribe: false,
  error: null,
  statePageOpenChildCoach: [],
};

const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribeADD.pending, (state: any) => {
        state.loadingSubscribe = true;
        state.error = null;
      })
      .addCase(subscribeADD.fulfilled, (state, action) => {
        state.subscribe.push(action.payload);
      })
      .addCase(SubscribeDelete.pending, (state: any) => {
        state.loadingSubscribe = true;
        state.error = null;
      })
      .addCase(SubscribeDelete.fulfilled, (state, action) => {
        state.subscribe = state.subscribe.filter(
          (t: any) => t.id !== action.payload.id
        );
      })
      .addCase(getSubscribe.pending, (state: any) => {
        state.loadingSubscribe = true;
        state.error = null;
      })
      .addCase(getSubscribe.fulfilled, (state, action) => {
        // let d: subscribeType[] = state.subscribe;
        // let g: subscribeType[] = action.payload

        // for (let i = 0; i < g.length; i++) {
        //     let fD: subscribeType[] = d.filter((item: subscribeType) => item?.id === g[i].id)
        //     if (fD.length === 0) {
        //         d = [...d, g[i]];
        //     }
        // }
        // state.subscribe = d;
        state.subscribe = action.payload;
        state.loadingSubscribe = false;
      })
      .addCase(getSubscribeType.pending, (state: any) => {
        state.loadingSubscribe = true;
        state.error = null;
      })
      .addCase(getSubscribeType.fulfilled, (state, action) => {
        let d: subscribeType[] = state.subscribe;
        let g: subscribeType[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: subscribeType[] = d.filter(
            (item: subscribeType) => item?.id === g[i].id
          );
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.subscribe = d;
        state.loadingSubscribe = false;
      })
      .addCase(pageOpenSubs.fulfilled, (state, action) => {
        state.statePageOpen.push(action.payload);
      })
      .addCase(addApproved.fulfilled, (state, action) => {
        const updateApproved = state.subscribe.find(
          (todo) => todo.id === action.payload.id
        ) || { approved: null };
        updateApproved!.approved = action.payload.approved;
      })
      .addCase(DeleteApproved.fulfilled, (state, action) => {
        const updateApproved = state.subscribe.find(
          (todo) => todo.id === action.payload.id
        ) || { approved: null };
        updateApproved!.approved = action.payload.approved;
      })
      .addCase(forgetApproved.fulfilled, (state, action) => {
        state.subscribe = state.subscribe.filter(
          (t: any) => t.id !== action.payload.id
        );
      })
      .addMatcher(isError, (state, action: any) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default subscribeSlice.reducer;
const isError = (action: AnyAction) => {
  return action.type.endsWith("rejectad");
};
