import { FC, useEffect, useMemo, useState } from "react";
import { CheckboxLeftRight } from "../setting/checkboxLeftRight";
import {
  AddParticipant,
  DeleteParticipant,
  participantType,
} from "../../../store/settingSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { subscribeType } from "../../../store/subscribeSlice";
import { Users } from "../../../store/loginSlice";

type ParticipantSelectType = {
  gender: number;
  Part: participantType[];
  s: subscribeType;
  championship_id: number;
  categoryABC: string;
  championship_category_id: number;
  championship_sub_category_id: number;
  login: Users[];
  user: Users;
};

export const ParticipantSelect: FC<ParticipantSelectType> = ({
  gender,
  Part,
  s,
  categoryABC,
  championship_category_id,
  championship_id,
  championship_sub_category_id,
  login,
  user,
}) => {
  const dispatch = useAppDispatch();

  let findChildPart = useMemo(() => {
    const f: participantType =
      Part.find(
        (item: participantType) =>
          item.child === s?.following_id &&
          item.championship_id === championship_id &&
          item.championship_category_id === championship_category_id &&
          item.categoryABC === categoryABC &&
          item.championship_sub_category_id === championship_sub_category_id
      ) || {};

    return f;
  }, [
    Part,
    s,
    championship_id,
    championship_category_id,
    categoryABC,
    championship_sub_category_id,
  ]);
  let anotherChildPart = useMemo(() => {
    const f: participantType[] = Part.filter(
      (item: participantType) =>
        item.championship_id === championship_id &&
        item.child === s?.following_id &&
        item?.categoryABC === categoryABC
    );

    return f;
  }, [Part, championship_id, s, categoryABC]);
  const myChild: Users =
    login.find((item: Users) => item?.id === s?.following_id) || {};

  let child: number = s?.following_id || 0;
  console.log({ child: findChildPart?.child });

  let dublic = false;
  if (anotherChildPart.length !== 0 && !findChildPart?.id) {
    dublic = true;
  } else {
    dublic = false;
  }
  console.log({ alalal: !!findChildPart?.id });

  const [isChecked, setIsChecked] = useState(!!findChildPart?.id);
  useEffect(() => {
    setIsChecked(!!findChildPart?.id);
  }, [findChildPart]);
  const handleCheckboxChange = () => {
    if (isChecked === false) {
      dispatch(
        AddParticipant({
          isChecked,
          championship_id,
          child,
          categoryABC,
          championship_category_id,
          championship_sub_category_id,
          gender,
        })
      );
    } else {
      const id = findChildPart?.id;
      if (isChecked === true) {
        dispatch(DeleteParticipant({ id, isChecked, championship_id }));
      }
    }
  };
  console.log({ isChecked });
  return (
    <>
      {!dublic && (
        <div>
          <CheckboxLeftRight
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
          />{" "}
          <div>
            {myChild?.name} {myChild?.year}
          </div>
        </div>
      )}
    </>
  );
};
