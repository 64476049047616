import { FC } from "react";
import "./style.css";
import {
  AssociationType,
  city,
  country,
  region,
} from "../../../../store/loginSlice";

type SelectChampionshipType = {
  Stor: (country | region | city | AssociationType)[];
  updateAdress: any;
  adressID: number;
  selectName: string;
};

export const SelectChampionship: FC<SelectChampionshipType> = ({
  Stor,
  updateAdress,
  adressID,
  selectName,
}) => {
  return (
    <div className="SettingFon">
      <div className="AllSubs">
        <h3 className="trOptions">{selectName}</h3>
        <div className="removeChildrens" id={0 === adressID ? "ChBlue" : ""}>
          <div
            className="selectCoachAndAss"
            onClick={(e) => updateAdress(0, selectName)}
          >
            Не выбрано
          </div>
        </div>
        {Stor.map((s) => (
          <div
            className="removeChildrens"
            id={s?.id === adressID ? "ChBlue" : ""}
          >
            <div
              className="selectCoachAndAss"
              onClick={(e) => updateAdress(s?.id, s?.name)}
            >
              {s?.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
