import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { FC, useEffect, useState } from "react";
import { RootState } from "../../../store";
import "./style.css";
import { errorSlice, meSlice } from "../../../store/loginSlice";

const Register: FC<any> = ({ signUp }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(errorSlice());
  }, [dispatch]);

  let Adam = {
    name: "",
    login: "",
    password: "",
    cpassword: "",
    // tel: "",
    email: "",
  };

  const [names, setNames] = useState(Adam);

  const updateSignin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
    setNames((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Поле обязательно к заполнению")
      .min(2, "Минимум 2 символа!")
      .max(50, "Максимум 50 символов!"),
    login: Yup.string()
      .required("Поле обязательно к заполнению")
      .matches(
        /^[A-Za-z]+([-_]?[A-Za-z0-9]+){0,2}\s$|^[A-Za-z]+([-_]?[A-Za-z0-9]+){0,2}$/,
        "Не правильное формат логина"
      )
      .min(2, "Минимум 2 символа!")
      .max(50, "Максимум 50 символов!"),
    password: Yup.string()
      .required("Поле обязательно к заполнению")
      .min(5, "Минимум 5 символа!")
      .max(50, "Максимум 50 символов!"),
    cpassword: Yup.string().oneOf<any>(
      [Yup.ref("password"), null],
      "Пароли не совпадают"
    ),
    // tel: Yup.string().required('Поле обязательно к заполнению').matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$|^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])\s$/, 'Не правильный номер телефона').min(8, 'Минимум 8 символов!').max(50, 'Максимум 50 символов!'),
    email: Yup.string()
      .required("Поле обязательно к заполнению")
      .matches(
        /\S+@\S+\.\S+/,
        "Введенное значение не соответствует формату электронной почты"
      )
      .min(4, "Минимум 4 символа!")
      .max(50, "Максимум 50 символов!"),
    birthday: Yup.string().required("Поле обязательно к заполнению"),
    // website: string().url().nullable(),
    // createdOn: date().default(() => new Date()),
  });
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    // reset,
    // control
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const login = useAppSelector((state: any) => state.login.resError);
  const StateError = useAppSelector((state: any) => state.login.error);
  if (login?.status) {
    return <Navigate to={"/signin"} />;
  }

  // const onSubmit = (data) => {
  //     alert(JSON.stringify(data));
  //     reset();
  // };

  return (
    <div className="flex">
      <div className="Form">
        <form onSubmit={handleSubmit(signUp)}>
          <h1 className="red">Регистрация</h1>

          <label className="labelForm">
            {names?.name === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Фамилия и имя</div>
            )}
            <input
              id="registerInput"
              placeholder="Фамилия и имя"
              {...register("name", {
                onChange: (e) => {
                  updateSignin(e, "name");
                },
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 2,
                  message: "Минимум 2 символа!",
                },
              })}
            />
          </label>
          {(errors as any)?.name && (
            <div className="regErr">
              <p>{(errors as any)?.name?.message || "Error!"}</p>
            </div>
          )}

          <label className="labelForm">
            {names?.login === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Придумайте логин</div>
            )}
            <input
              id="registerInput"
              placeholder="Придумайте логин"
              type="text"
              {...register("login", {
                onChange: (e) => {
                  updateSignin(e, "login");
                },
                pattern: {
                  value: /^[A-Za-z]+([-_]?[a-z0-9]+){0,2}\s$|^[a-z]+([-_]?[a-z0-9]+){0,2}$/,
                  message: "Не правильное формат логина",
                },
                minLength: {
                  value: 3,
                  message: "Минимум 3 символа!",
                },
              })}
            />
          </label>
          {errors?.login && (
            <div className="regErr">
              <p>{(errors as any)?.login?.message || "Error!"}</p>
            </div>
          )}
          {login?.fields == "login" && (
            <div className="regErr">
              <p>{login.message}</p>
            </div>
          )}

          <label className="labelForm">
            {names?.password === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Придумайте пароль</div>
            )}
            <input
              id="registerInput"
              type="password"
              placeholder="Придумайте пароль"
              {...register("password", {
                onChange: (e) => {
                  updateSignin(e, "password");
                },
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 6,
                  message: "Минимум 5 символов!",
                },
              })}
            />
          </label>
          {errors?.password && (
            <div className="regErr">
              <p>{(errors as any)?.password?.message || "Error!"}</p>
            </div>
          )}

          <label className="labelForm">
            {names?.cpassword === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Потверждение пароля</div>
            )}
            <input
              id="registerInput"
              type="password"
              placeholder="Потверждение пароля"
              {...register("cpassword", {
                onChange: (e) => {
                  updateSignin(e, "cpassword");
                },
                required: "Поле обязательно к заполнению",
                minLength: {
                  value: 5,
                  message: "Минимум 5 символов!",
                },
              })}
            />
          </label>
          {errors?.cpassword && (
            <div className="regErr">
              <p>{(errors as any)?.cpassword?.message || "Error!"}</p>
            </div>
          )}

          {/* <label className="labelForm">
            {names?.tel === '' ? <div className="registerInputDiv"></div> : <div className="registerInputDiv">Номер телефона</div>}
            <input id='registerInput'
              type="tel"
              placeholder='Номер телефона'
              {...register("tel", {
                onChange: (e) =>{ updateSignin(e, 'tel') },
                pattern: {
                  value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                  message: "Не правильное формат телефона"
                },
                minLength: {
                  value: 8,
                  message: 'Минимум 8 цифров!'
                }
              })} />
          </label>
            {errors?.tel && <div className='regErr'>
              <p>{(errors as any)?.tel?.message || "Error!"}</p>
            </div>}
            {login?.fields == 'phone' && <div className='regErr'><p>{login.message}</p></div>} */}

          <label className="labelForm">
            {names?.email === "" ? (
              <div className="registerInputDiv"></div>
            ) : (
              <div className="registerInputDiv">Электронная почта</div>
            )}
            <input
              id="registerInput"
              type="email"
              placeholder="Электронная почта"
              {...register("email", {
                onChange: (e) => {
                  updateSignin(e, "email");
                },
                required: "Поле обязательно к заполнению",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message:
                    "Введенное значение не соответствует формату электронной почты",
                },
                minLength: {
                  value: 5,
                  message: "Минимум 5 символов!",
                },
              })}
            />
          </label>
          {errors?.email && (
            <div className="regErr">
              <p>{(errors as any)?.email?.message || "Error!"}</p>
            </div>
          )}
          {login?.fields == "email" && (
            <div className="regErr">
              <p>{login.message}</p>
            </div>
          )}

          <label className="labelForm">
            <div className="registerInputDiv">Дата рождения</div>
            <input
              id="registerInput"
              type="date"
              {...register("birthday", {
                required: "Поле обязательно к заполнению",
              })}
            />
          </label>
          {errors?.birthday && (
            <div className="regErr">
              <p>{(errors as any)?.birthday?.message || "Error!"}</p>
            </div>
          )}

          {StateError && <div className="red errorText">{StateError}</div>}

          <input
            type="submit"
            id={isValid ? "submitIsValid" : "submitReg"}
            value="Отправить"
            disabled={!isValid}
          />
          <div className="textDivs">
            <Link to="/signin" id="texts">
              Если есть аккаунта то авторизуйтесь!
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
