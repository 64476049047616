import { FC, useEffect, useState } from "react";
import { Users } from "../../../store/loginSlice";
import { subscribeType } from "../../../store/subscribeSlice";
import { url } from "../../../store/url";
import { api } from "../../../store/axiosInstans";
import { ChildrenComponent } from "./components/childrenSearch";
import "./style.css";
import { useAppSelector } from "../../../store/hook";
import { Link } from "react-router-dom";

export const Search: FC<any> = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Users[]>([]);
  const baisal = url();
  const log = useAppSelector((state) => state.login.log);

  const transliterate = (text: string): string[] => {
    const map: Record<string, string | string[]> = {
      а: "a",
      б: "b",
      в: "v",
      г: "g",
      д: "d",
      е: "e",
      ё: ["e", "yo"],
      ж: ["zh", "j"],
      з: "z",
      и: ["i", "y"],
      й: ["y", "i"],
      к: "k",
      л: "l",
      м: "m",
      н: "n",
      о: "o",
      п: "p",
      р: "r",
      с: "s",
      т: "t",
      у: "u",
      ф: "f",
      х: "h",
      ц: "ts",
      ч: ["ch", "4"],
      ш: ["sh", "w"],
      щ: "sch",
      ы: "y",
      э: "e",
      ю: "yu",
      я: "ya",
    };

    // Создаём обратный мап (английские варианты → русские буквы)
    const reverseMap: Record<string, string[]> = {};
    Object.entries(map).forEach(([rus, eng]) => {
      if (Array.isArray(eng)) {
        eng.forEach((e) => {
          if (!reverseMap[e]) reverseMap[e] = [];
          reverseMap[e].push(rus);
        });
      } else {
        if (!reverseMap[eng]) reverseMap[eng] = [];
        reverseMap[eng].push(rus);
      }
    });

    // Функция генерации всех возможных комбинаций (рус -> англ)
    const generate = (word: string, index = 0, current = ""): string[] => {
      if (index === word.length) return [current];

      const char = word[index].toLowerCase();
      if (!map[char]) return generate(word, index + 1, current + char);

      const replacements: string[] = Array.isArray(map[char])
        ? (map[char] as string[])
        : [map[char] as string];

      return replacements.flatMap((replacement) =>
        generate(word, index + 1, current + replacement)
      );
    };

    // Функция генерации обратной транслитерации (англ -> рус)
    const reverseTransliterate = (word: string): string[] => {
      let results = [word.toLowerCase()];

      Object.keys(reverseMap).forEach((eng) => {
        results = results.flatMap((w) =>
          reverseMap[eng].map((rus) => w.split(eng).join(rus))
        );
      });

      return results;
    };

    const original = text.toLowerCase();
    const transliterated = generate(original); // Рус -> Англ
    const reverse = reverseTransliterate(original); // Англ -> Рус
    const reverseFromTransliterated = transliterated.flatMap(
      reverseTransliterate
    ); // Англ → Рус из вариантов

    return Array.from(
      new Set([
        original,
        ...transliterated,
        ...reverse,
        ...reverseFromTransliterated,
      ])
    ).filter(Boolean);
  };

  useEffect(() => {
    if (query.length > 2) {
      const searchVariants = transliterate(query.trim()); // Получаем все варианты

      // Фильтруем лог по `name` и `login`
      const filteredResults = log.filter((user) =>
        searchVariants.some(
          (variant) =>
            user.name.toLowerCase().includes(variant) ||
            user.login.toLowerCase().includes(variant)
        )
      );

      setResults(filteredResults);
    } else {
      setResults([]);
    }
  }, [query]);

  const [focused, setFocused] = useState(false);

  return (
    <div className={`search-container ${focused ? "focused" : ""}`}>
      <input
        type="text"
        placeholder="Поиск..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {query && (
        <button className="clear-btn" onClick={() => setQuery("")}>
          ✕
        </button>
      )}
      {focused && query && (
        <ul className="results">
          {results.length > 0 ? (
            results.map((item) => (
              <li key={item.id}>
                <Link
                  to={"/" + item?.login}
                  className="user-item"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <img
                    src={
                      item.ava
                        ? baisal + "storage/avatar/prev_" + item?.ava
                        : "https://yaponaroll.ru/images/20200701_001.jpg"
                    }
                    alt={item.name}
                    className="avatar"
                  />
                  <div className="user-info">
                    <span className="name">{item.name}</span>
                    <span className="login">@{item.login}</span>
                    <Link to={"/" + item?.login}>{item.login}</Link>
                  </div>
                </Link>
              </li>
            ))
          ) : (
            <li className="no-result">Нет результатов</li>
          )}
        </ul>
      )}
    </div>
  );
};
