import { FC } from "react";
import { AllGrades } from "../../AllGrades";
import { Users } from "../../../../../../../store/loginSlice";
import { GradeType } from "../../../../../../../store/gradeSlice";
import { number } from "yup";

type GradeContainerType = {
  Userter: Users[];
  Grades: GradeType[]; // osi aidin ocenka
  grade: any;
  classCh: any;
  subjectCh: any;
  GradesChild: GradeType[];
};

export const GradeContainer: FC<GradeContainerType> = ({
  GradesChild,
  Userter,
  Grades,
  grade,
  classCh,
  subjectCh,
}) => {
  // let monthDay: any[] = [];

  // for (let t = 0; t < arr31.length; t++) {
  //     // console.log(FGrades[0]?.created_at?.toString().slice(0, -11) + {t});
  //     if (!Number.isNaN(new Date(Date.parse(Grades[0]?.created_at?.toString().slice(0, -11) + arr31[t])).getDay())) {
  //         monthDay = [...monthDay, arr31[t]]
  //     }
  // }

  let filterMonth: any = [];
  for (let q = 0; q < Grades.length; q++) {
    const element = Grades[q]?.created_at?.toString().slice(8).slice(0, -9);
    filterMonth = [...filterMonth, element];
  }
  const sortAsc = (a: any, b: any) => {
    return a - b;
  };
  const uniqueArrMonth = filterMonth.filter(
    (item: any, index: any) => filterMonth.indexOf(item) === index
  );

  uniqueArrMonth.sort(sortAsc);
  return (
    <div className="tableGrade">
      {Grades.length > 0 && (
        <table>
          {/* <caption>MLW Alumni</caption> */}
          <thead>
            <tr>
              <th>Имя</th>
              <th className="theadName">За месяц</th>
              <th className="theadName">Общий балл</th>
              {/* {FGrades.map((ar: GradeType) => ( */}
              {uniqueArrMonth.map((ar: any) => (
                <th id="th">
                  <div className="grade7">
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 1 && "пн"}
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 2 && "вт"}
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 3 && "ср"}
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 4 && "чт"}
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 5 && "пт"}
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 6 && "сб"}
                    {new Date(
                      Date.parse(
                        Grades[0]?.created_at?.toString().slice(0, -11) + ar
                      )
                    ).getDay() === 0 && "вс"}
                  </div>
                  <div>{ar}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {GradesChild.map((g) => (
              <AllGrades
                monthDay={uniqueArrMonth}
                Grades={Grades}
                g={g}
                Userter={Userter}
                grade={grade}
                classCh={classCh}
                subjectCh={subjectCh}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
