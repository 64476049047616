import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AnyAction,
} from "@reduxjs/toolkit";
import { api, instanceGET, instancePOST } from "./axiosInstans";
import { url } from "./url";
let Apiq = url();

export type exercisesCoachType = {
  id?: string | number;
  creator?: string;
  name?: string;
  max_number?: any;
  forChild?: any;
  created_at?: any;
  updated_at?: any;
};

export type exercisesChildType = {
  id?: string | number;
  doing?: string;
  exercise_id?: string | number | undefined;
  quantity?: any;
  created_at?: any;
  updated_at?: any;
};

// Exercise Creator Slice
export const exercisesADD = createAsyncThunk<
  exercisesCoachType,
  exercisesCoachType,
  { rejectValue: string }
>("exercises/exercisesADD", async function (
  { name, max_number, forChild },
  { rejectWithValue }
) {
  try {
    console.log({ forChild });
    const jsonS = {
      name,
      max_number,
      forChild,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddExerciseCreator",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as exercisesCoachType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exercisesDelete = createAsyncThunk<
  exercisesCoachType,
  exercisesCoachType,
  { rejectValue: string }
>("exercises/exercisesDelete", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteExerciseCreator",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exercisesGet = createAsyncThunk<
  exercisesCoachType[],
  exercisesCoachType,
  { rejectValue: string }
>("exercises/exercisesGet", async function ({ creator }, { rejectWithValue }) {
  try {
    const jsonS = {
      creator,
    };

    const response: any = await api.post(
      Apiq + "api/auth/GetExerciseCreator",
      jsonS
    );

    return (await response.data.data) as exercisesCoachType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exercisesPageOpen = createAsyncThunk<
  any,
  any,
  { rejectValue: string }
>("exercises/pageOpenExercises", async function (
  { creator },
  { rejectWithValue }
) {
  try {
    return (await creator) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exercisesUpdate = createAsyncThunk<
  exercisesCoachType,
  exercisesCoachType,
  { rejectValue: string }
>("exercises/exercisesUpdate", async function (
  { id, name },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      name,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateExerciseCreator",
      jsonS
    );

    return (await response.data.data) as exercisesCoachType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exercisesUpdateSquats = createAsyncThunk<
  exercisesCoachType,
  exercisesCoachType,
  { rejectValue: string }
>("exercises/exercisesUpdateSquats", async function (
  { id, max_number },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      max_number,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateExerciseCreator",
      jsonS
    );

    return (await response.data.data) as exercisesCoachType;
  } catch (error) {
    return rejectWithValue("");
  }
});

// Doing Exercise Slice !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const exerChildADD = createAsyncThunk<
  exercisesChildType,
  exercisesChildType,
  { rejectValue: string }
>("exercises/exerChildADD", async function (
  { doing, quantity, exercise_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      doing,
      exercise_id,
      quantity,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddDoingExercise",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as exercisesChildType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exerChildDelete = createAsyncThunk<
  exercisesChildType,
  exercisesChildType,
  { rejectValue: string }
>("exercises/exerChildDelete", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteDoingExercise",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exerChildGet = createAsyncThunk<
  exercisesChildType[],
  exercisesChildType,
  { rejectValue: string }
>("exercises/exerChildGet", async function ({ doing }, { rejectWithValue }) {
  try {
    const jsonS = {
      doing,
    };

    const response: any = await api.post(
      Apiq + "api/auth/GetDoingExercise",
      jsonS
    );

    return (await response.data.data) as exercisesChildType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exerChildPageOpen = createAsyncThunk<
  any,
  any,
  { rejectValue: string }
>("exercises/exerChildPageOpen", async function ({ id }, { rejectWithValue }) {
  try {
    return (await id) as any;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const exerChildUpdate = createAsyncThunk<
  exercisesChildType,
  exercisesChildType,
  { rejectValue: string }
>("exercises/exerChildUpdate", async function (
  { id, quantity },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      quantity,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateDoingExercise",
      jsonS
    );

    return (await response.data.data) as exercisesChildType;
  } catch (error) {
    return rejectWithValue("");
  }
});

type exercisesStateType = {
  exercisesCoach: exercisesCoachType[];
  stateOpenPageExerCoach: string[];
  exercisesChild: exercisesChildType[];
  stateOpenPageExerChild: string[];
  loading: boolean;
  loadingSave: boolean;
  error: any;
};

const initialState: exercisesStateType = {
  exercisesCoach: [],
  stateOpenPageExerCoach: [],
  exercisesChild: [],
  stateOpenPageExerChild: [],
  loading: false,
  loadingSave: false,
  error: null,
};

const exercisesSlice = createSlice({
  name: "exercises",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exercisesADD.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exercisesADD.fulfilled, (state, action) => {
        state.exercisesCoach.push(action.payload);
      })
      .addCase(exercisesGet.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exercisesGet.fulfilled, (state, action) => {
        let d: exercisesCoachType[] = state.exercisesCoach;
        let g: exercisesCoachType[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: exercisesCoachType[] = d.filter(
            (item: exercisesCoachType) => item?.id === g[i].id
          );
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.exercisesCoach = d;
        state.loading = false;
      })
      .addCase(exercisesDelete.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exercisesDelete.fulfilled, (state, action) => {
        state.exercisesCoach = state.exercisesCoach.filter(
          (t: exercisesCoachType) => t.id !== action.payload.id
        );
        state.exercisesChild = state.exercisesChild.filter(
          (t: exercisesChildType) => t.exercise_id !== action.payload.id
        );
      })
      .addCase(exercisesUpdate.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exercisesUpdate.fulfilled, (state, action) => {
        console.log(action.payload);
        const update = state.exercisesCoach.find(
          (todo) => todo.id === action.payload.id
        ) || { name: "" };
        update!.name = action.payload.name;
        state.loading = false;
      })
      .addCase(exercisesUpdateSquats.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exercisesUpdateSquats.fulfilled, (state, action) => {
        const update = state.exercisesCoach.find(
          (todo) => todo.id === action.payload.id
        ) || { max_number: "" };
        update!.max_number = action.payload.max_number;
        state.loading = false;
      })
      .addCase(exercisesPageOpen.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exercisesPageOpen.fulfilled, (state, action) => {
        state.stateOpenPageExerCoach.push(action.payload);
        state.loading = true;
      })
      .addCase(exerChildADD.pending, (state: any) => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(exerChildADD.fulfilled, (state, action) => {
        state.exercisesChild.push(action.payload);
        state.loadingSave = false;
      })
      .addCase(exerChildGet.pending, (state: any) => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(exerChildGet.fulfilled, (state, action) => {
        let d: exercisesChildType[] = state.exercisesChild;
        let g: exercisesChildType[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: exercisesChildType[] = d.filter(
            (item: exercisesChildType) => item?.id === g[i].id
          );
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.exercisesChild = d;
        state.loadingSave = false;
      })
      .addCase(exerChildDelete.pending, (state: any) => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(exerChildDelete.fulfilled, (state, action) => {
        state.exercisesChild = state.exercisesChild.filter(
          (t: any) => t.id !== action.payload.id
        );
        state.loadingSave = false;
      })
      .addCase(exerChildUpdate.pending, (state: any) => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(exerChildUpdate.fulfilled, (state, action) => {
        const update: exercisesChildType = state.exercisesChild.find(
          (todo) => todo.id === action.payload.id
        ) || { quantity: "" };
        update!.quantity = action.payload.quantity;
        state.loadingSave = false;
      })
      .addCase(exerChildPageOpen.pending, (state: any) => {
        state.loadingSave = true;
        state.error = null;
      })
      .addCase(exerChildPageOpen.fulfilled, (state, action) => {
        state.stateOpenPageExerChild.push(action.payload);
        state.loadingSave = false;
      })
      .addMatcher(isError, (state, action: any) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default exercisesSlice.reducer;
const isError = (action: AnyAction) => {
  return action.type.endsWith("rejectad");
};
