import { FC, useMemo } from "react";
import { Users } from "../../../../../store/loginSlice";
import { subscribeType } from "../../../../../store/subscribeSlice";
import { MdDownloadDone } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { Link } from "react-router-dom";
import { url } from "../../../../../store/url";

type schType = {
  ch: subscribeType;
  Userter: Users[];
  User: Users;
  addApprov: any;
  forgetApprov: any;
  pageData: string;
};

export const SubsChild: FC<schType> = ({
  ch,
  Userter,
  User,
  addApprov,
  forgetApprov,
  pageData,
}) => {
  let subsU = useMemo(() => {
    let f = Userter.find((item: Users) => item.id === ch.following_id) || {};
    return f;
  }, [Userter, ch]);

  const Api = url();

  const addChild = () => {
    if (User?.position === 3) {
      let type = 3;
      addApprov(subsU?.id, type);
    }
    if (User?.position === 2) {
      let type = 2;
      addApprov(subsU?.id, type);
    }
  };

  const forgetChild = () => {
    let del = window.confirm(`Точно хотите удалить "${subsU?.name}"?`);
    if (del) {
      if (User?.position === 3) {
        let type = 3;
        forgetApprov(subsU?.id, type);
      }
      if (User?.position === 2) {
        let type = 2;
        forgetApprov(subsU?.id, type);
      }
    }
  };
  return (
    <div className="childData">
      <main className="ava">
        {subsU?.ava ? (
          <img
            className="avaImg"
            src={Api + "storage/avatar/prev_" + subsU?.ava}
            alt=""
          />
        ) : (
          <img
            className="avaImg"
            src={"https://yaponaroll.ru/images/20200701_001.jpg"}
            alt=""
          />
        )}
      </main>
      <div className="childText">
        <div>{subsU?.name}</div>
        <Link className="loginLink" to={"/" + subsU?.login}>
          {subsU?.login}
        </Link>
      </div>
      {((User?.position === 2 && pageData === User?.id) ||
        (User?.position === 3 && pageData === User?.id)) && (
        <MdDownloadDone
          className="TwotoneDelete"
          id="TwotoneAddChild"
          onClick={addChild}
        />
      )}
      {((User?.position === 2 && pageData === User?.id) ||
        (User?.position === 3 && pageData === User?.id)) && (
        <TiDelete
          className="TwotoneDelete"
          id="TwotoneDeleteChild"
          onClick={forgetChild}
        />
      )}
    </div>
  );
};
