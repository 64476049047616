import Echo from "laravel-echo";
import { url } from "./store/url";

// Определяем глобальный объект Pusher, если он не установлен
// Это полезно для совместимости с Laravel Echo и Reverb
// Убедитесь, что "pusher-js" установлен, если используется Pusher
declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = require("pusher-js");

const echoo = new Echo({
  broadcaster: "reverb",
  key: "a7zhwfmwkmv2jz9a6wep",
  wsHost: "baisal.com",
  wsPort: 443, // HTTPS (WSS) использует порт 443
  forceTLS: true, // Включает WebSocket через SSL
  disableStats: true,
  wsPath: "/reverb",
  encrypted: true, // Гарантирует безопасное соединение
  enabledTransports: ["ws", "wss"], // Разрешает WebSockets
  authEndpoint: url() + "broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("attt")}`,
    },
  },
});

export default echoo;
