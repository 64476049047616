import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import { SubsChild } from "./components/SubsCildren";
import {
  DeleteApproved,
  addApproved,
  forgetApproved,
  subscribeType,
} from "../../../../store/subscribeSlice";
import "./style.css";
import { MyChild } from "./components/MyChild";
import { Payment } from "./components/payment";
import { MyCoach } from "./components/MyCoach";
import { getPayment, pageOpenPayment } from "../../../../store/payment";
import { PaymentContainer } from "./components/paymentContainer";

export const Children: FC<any> = ({
  pageData,
  Userter,
  User,
  statePageOpenCoach,
  subsAllCoachChild,
  subsAllAssoc,
  f,
  p,
  position,
  date,
}) => {
  const dispatch = useAppDispatch();
  date = Number(date);
  const [myChild, setMyChild] = useState(true);
  const [myCoach, setMyCoach] = useState(false);
  const [allChild, setAllChild] = useState(false);
  const [payment, setPayment] = useState(false);

  const openMyChild = () => {
    setMyChild(true);
    setAllChild(false);
    setPayment(false);
    setMyCoach(false);
  };
  const openAllChild = () => {
    setMyChild(false);
    setAllChild(true);
    setPayment(false);
    setMyCoach(false);
  };
  const openPayment = () => {
    setMyChild(false);
    setAllChild(false);
    setPayment(true);
    setMyCoach(false);
  };
  const openCoach = () => {
    setMyChild(false);
    setAllChild(false);
    setPayment(false);
    setMyCoach(true);
  };
  const addApprov = (id: any, type: number) => {
    dispatch(addApproved({ id, type }));
  };
  const deleteApprov = (id: any, type: number) => {
    dispatch(DeleteApproved({ id, type }));
  };
  const forgetApprov = (id: any, type: number) => {
    dispatch(forgetApproved({ id, type }));
  };

  const statePageOpen = useAppSelector(
    (state: any) => state.payment.statePageOpen
  );
  let pageOpenFilter = useMemo(() => {
    let f: string[] = statePageOpen.filter((item: string) => item === pageData);

    return f;
  }, [statePageOpen, pageData]);

  useEffect(() => {
    if (
      (User?.position === 2 || User?.position === 3) &&
      User?.id === pageData &&
      pageOpenFilter.length === 0
    ) {
      const id = User?.id;
      dispatch(getPayment({}));
      dispatch(pageOpenPayment({ id }));
    }
  }, [pageData]);

  const paymentGet = useAppSelector((state: any) => state.payment.payment);
  let myCheck = useMemo(() => {
    let f: any[] = paymentGet.filter(
      (item: any) => item?.supplier_id === pageData
    );

    return f;
  }, [paymentGet, pageData]);

  return (
    <div className="children">
      <ul className="coachUl">
        {(position === 2 || position === 3) && (
          <li className={myChild ? "clickLI" : "ß"} onClick={openMyChild}>
            Ученики
          </li>
        )}
        {position === 3 && (
          <li className={myCoach ? "clickLI" : "ß"} onClick={openCoach}>
            Учителя
          </li>
        )}
        {pageData === User?.id && (position === 2 || position === 3) && (
          <li className={allChild ? "clickLI" : "ß"} onClick={openAllChild}>
            Запросы
          </li>
        )}
        {pageData === User?.id && (position === 2 || position === 3) && (
          <li className={payment ? "clickLI" : "ß"} onClick={openPayment}>
            Оплата
          </li>
        )}
      </ul>
      {position === 3 &&
        myChild &&
        p.map((ch: any) => (
          <MyChild
            myCheck={myCheck}
            pageData={pageData}
            ch={ch}
            Userter={Userter}
            User={User}
            deleteApprov={deleteApprov}
          />
        ))}
      {position === 2 &&
        myChild &&
        subsAllCoachChild.map((ch: subscribeType) => (
          <MyChild
            myCheck={myCheck}
            pageData={pageData}
            ch={ch?.following_id}
            Userter={Userter}
            User={User}
            deleteApprov={deleteApprov}
          />
        ))}
      {position === 3 &&
        myCoach &&
        subsAllAssoc.map((ch: any) => (
          <MyCoach
            myCheck={myCheck}
            pageData={pageData}
            ch={ch}
            Userter={Userter}
            User={User}
            deleteApprov={deleteApprov}
          />
        ))}
      {(User?.position === 2 || User?.position === 3) &&
        allChild &&
        f.map((ch: any) => (
          <SubsChild
            pageData={pageData}
            ch={ch}
            Userter={Userter}
            User={User}
            addApprov={addApprov}
            forgetApprov={forgetApprov}
          />
        ))}
      {payment && <PaymentContainer User={User} Userter={Userter} />}
    </div>
  );
};
