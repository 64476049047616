import { FC, useMemo } from "react";
import { subscribeType } from "../../../../store/subscribeSlice";
import { Users } from "../../../../store/loginSlice";
import { url } from "../../../../store/url";
import { Link } from "react-router-dom";
import { GradeType } from "../../../../store/gradeSlice";

type SubsMapType = {
  ss: subscribeType | any;
  Userter: Users[];
  showAssoc?: boolean;
  showSubsPage?: boolean;
  showAllCoachChild?: boolean;
  showAllCoach?: boolean;
  showSubs?: boolean;
  position?: number;
  gradeAll?: GradeType[] | undefined;
  pageData?: string;
};

export const SubsMap: FC<SubsMapType> = ({
  ss,
  Userter,
  showSubsPage,
  showAllCoachChild,
  showAllCoach,
  showAssoc,
  showSubs,
  position,
  gradeAll,
  pageData,
}) => {
  let f: Users | undefined = {};

  if (
    showSubs ||
    (position === 2 && showAllCoachChild) ||
    (position === 3 && showAllCoach)
  ) {
    f = Userter.find((item: Users) => item?.id === ss?.following_id);
  }
  if (
    showSubsPage ||
    (position !== 3 && showAllCoach) ||
    (position === 2 && showAssoc)
  ) {
    f = Userter.find((item: Users) => item?.id === ss?.follower_id);
  }
  if (position !== 2 && (showAssoc || showAllCoachChild)) {
    f = Userter.find((item: Users) => item?.id === ss);
  }
  let filterGrade: GradeType[] = [];
  if ((position === 1 || position === 0) && showAssoc && gradeAll && pageData) {
    filterGrade = gradeAll.filter(
      (item: GradeType) => item?.assoc === ss && item?.child === pageData
    );
  }
  if (
    (position === 1 || position === 0) &&
    showAllCoach &&
    gradeAll &&
    pageData
  ) {
    filterGrade = gradeAll.filter(
      (item: GradeType) =>
        item?.coach === ss?.follower_id && item?.child === pageData
    );
  }

  let findPage: Users =
    Userter.find((item: Users) => item?.id === pageData) || {};
  let Time = useMemo(() => {
    let t = 0;
    for (let i = 0; i < filterGrade.length; i++) {
      t = Number(filterGrade[i].study_time) + t;
    }
    return t;
  }, [gradeAll]);

  let t = Time / 60 / 60;
  let time1 = Math.floor(t);
  let t2 = 60 - 60 * (t - time1);
  let time2 = Math.floor(t2);
  let Api = url();
  return (
    <Link to={"/" + f?.login} className="mapSubsShow">
      <div className="avatarsSubs">
        {f?.ava ? (
          <img
            className="avaImg"
            src={Api + "storage/avatar/prev_" + f?.ava}
            alt=""
          />
        ) : (
          <img
            className="avaImg"
            src={"https://yaponaroll.ru/images/20200701_001.jpg"}
            alt=""
          />
        )}
      </div>
      <div className="resultSubs">
        <div className="nameSubs">{f?.name}</div>
        <div className="loginSubs">@{f?.login}</div>
        {f?.position === 2 && <main className="positionMini">Coach</main>}
        {f?.position === 3 && <main className="positionMini">Association</main>}
        {/* {(showAssoc || showAllCoach) && <div className='dateSubs'>Время посещения: {time1} ч. {t2 !== 60 && time2 + " мин."} </div>} */}
      </div>
    </Link>
  );
};
