

// Функция для форматирования времени в hh:mm:ss
export const formatTime = (date: any) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

// Функция для форматирования даты в dd.MM.yyyy
export const formatDate = (date: any) => {
  const day = String(date.getDate()).padStart(2, "0"); // День
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяц (getMonth возвращает 0–11)
  const year = date.getFullYear(); // Год
  return `${day}.${month}.${year}`;
};

export const formatMilliseconds = (ms: number): string => {
  const totalSeconds = Math.floor(ms / 1000); // Переводим миллисекунды в секунды
  const hours = Math.floor(totalSeconds / 3600); // Часы
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Минуты
  const seconds = totalSeconds % 60; // Секунды

  // Форматируем в формате hh:mm:ss
  return [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");
};
