import { FC, useMemo, useState } from "react";
import { Users } from "../../../../../store/loginSlice";
import { subscribeType } from "../../../../../store/subscribeSlice";
import { AiTwotoneDelete } from "react-icons/ai";
import { subscribe } from "diagnostics_channel";
import { url } from "../../../../../store/url";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/hook";
import { PaymentADD, paymentType } from "../../../../../store/payment";
import { ShowChild } from "./showCheck";

type schType = {
  ch: number | undefined;
  Userter: Users[];
  User: Users;
  deleteApprov: any;
  pageData: string;
  myCheck: paymentType[];
};

export const MyChild: FC<schType> = ({
  ch,
  Userter,
  User,
  deleteApprov,
  pageData,
  myCheck,
}) => {
  let subsU = useMemo(() => {
    let f = Userter.find((item: Users) => item.id === ch) || {};
    return f;
  }, [Userter, ch]);

  const Api = url();
  const deleteChild = () => {
    let del = window.confirm(`Точно хотите убрать "${subsU?.name}"?`);
    if (del) {
      deleteApprov(subsU?.id, 2);
    }
  };
  const dispatch = useAppDispatch();

  const [amount, setAmount] = useState("");
  const updatePayment = (e: string) => {
    setAmount(e);
  };
  const [title, setTitle] = useState("");
  const updateComment = (e: string) => {
    setTitle(e);
  };
  // const [payment_date, setPayment_date] = useState(new Date().toISOString().split('T')[0]);

  const btnPayment = () => {
    let client_id: number = ch || 0;
    if (client_id !== undefined && amount !== "") {
      dispatch(PaymentADD({ client_id, title, amount }));
      setAmount("");
      setTitle("");
    }
  };

  let ChildCheck = useMemo(() => {
    let f: paymentType[] = myCheck.filter(
      (item: paymentType) => item?.client_id === ch
    );

    return f;
  }, [myCheck, ch]);

  let money = useMemo(() => {
    let money: number = 0;
    for (let i = 0; i < ChildCheck.length; i++) {
      Date.parse(ChildCheck[i]?.created_at);
      let date = new Date(ChildCheck[i]?.created_at);

      let newDate = new Date();
      let month = date.getMonth();
      let newMonth = newDate.getMonth();
      let year = date.getFullYear();
      let newYear = newDate.getFullYear();
      if (month === newMonth && year === newYear) {
        money = money + Number(ChildCheck[i]?.amount);
      }
    }
    return money;
  }, [ChildCheck]);
  const [show, setShow] = useState(false);
  const ShowCheckAll = () => {
    setShow(!show);
  };

  return (
    <div className="childData">
      {show && (
        <ShowChild
          dispatch={dispatch}
          ShowCheckAll={ShowCheckAll}
          ChildCheck={ChildCheck}
          name={subsU?.name}
          ava={subsU?.ava}
        />
      )}
      <main className="ava avaCh">
        {subsU?.ava ? (
          <img
            className="avaImg"
            src={Api + "storage/avatar/prev_" + subsU?.ava}
            alt=""
          />
        ) : (
          <img
            className="avaImg"
            src={"https://yaponaroll.ru/images/20200701_001.jpg"}
            alt=""
          />
        )}
      </main>
      <div className="childText">
        <Link className="loginLink" to={"/" + subsU?.login}>
          {subsU?.login}
        </Link>
        <div>{subsU?.name}</div>
        {User?.id === pageData && (
          <div className="paymentDiv">
            <div>
              <input
                type="number"
                value={amount}
                id="paymentNum"
                placeholder="Payment"
                onChange={(e) => updatePayment(e.target.value)}
              />
              <input
                type="text"
                value={title}
                id="paymentComment"
                placeholder="Comment"
                onChange={(e) => updateComment(e.target.value)}
              />
            </div>
            <input
              type="button"
              id="buttonPayment"
              value="Оплатил"
              onClick={btnPayment}
            />
          </div>
        )}
        {User?.id === pageData && (
          <div className="checkDown">
            {money ? (
              <div className={money > 0 ? "checkMonth" : "checkMonthRed"}>
                На этот месяц {money}тг
              </div>
            ) : (
              <div className="checkMonthRed">Не оплачень</div>
            )}
            <div className="check" onClick={ShowCheckAll}>
              Чек
            </div>
          </div>
        )}
      </div>
      {User?.position === 2 && pageData === User?.id && (
        <AiTwotoneDelete
          className="TwotoneDelete"
          id="TwotoneDeleteChild"
          onClick={deleteChild}
        />
      )}
    </div>
  );
};
