import echoo from "./echoo";

type CallbackFunction = (data: any) => void;

class SubscriptionManager {
  private channels: Record<string, ReturnType<typeof echoo["channel"]>>;

  constructor() {
    this.channels = {};
  }

  subscribe(
    channelName: string,
    eventName: string,
    callback: CallbackFunction
  ): void {
    if (!this.channels[channelName]) {
      this.channels[channelName] = echoo.private(channelName); // Используем private
    } else {
      console.log(`Using existing channel: ${channelName}`); // Отладочный лог
    }
    console.log({ asd: this.channels[channelName] });

    this.channels[channelName].listen("." + eventName, (data: any) => {
      callback(data);
    });
  }

  unsubscribe(channelName: string): void {
    if (this.channels[channelName]) {
      echoo.leaveChannel(channelName);
      delete this.channels[channelName];
    }
  }
}

const subscriptionManager = new SubscriptionManager();
export default subscriptionManager;
