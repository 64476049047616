import { SType } from "../../../../../store/setkaSlice";
import {
  Bracket,
  participantType,
  subCategoryType,
} from "../../../../../store/settingSlice";

export const fullBracketsFunction = (
  fSubCategory: subCategoryType[],
  mergedGrid: SType[] & participantType[]
) => {
  let fullBrackets: Bracket[] = [];

  for (let s = 0; s < fSubCategory.length; s++) {
    let fMergedGridBySubCategory: SType[] &
      participantType[] = mergedGrid.filter(
      (item: SType & participantType) =>
        item?.championship_sub_category_id === fSubCategory[s]?.id
    );
    if (fMergedGridBySubCategory.length > 1) {
      const maxI_grid = Math.max(
        ...fMergedGridBySubCategory.map((item: any) => item.i_grid ?? 0) // Используем 0, если i_grid — undefined
      );
      for (let ig = 0; ig <= maxI_grid; ig++) {
        let fGridBySubCategoryAndI_grid: SType[] &
          participantType[] = fMergedGridBySubCategory.filter(
          (item: SType & participantType) => item?.i_grid === ig
        );
        const maxPlaceGrid: number = fGridBySubCategoryAndI_grid[0]?.place || 0;

        let dd: any[] = [];
        let ddd: Bracket = {
          category:
            fGridBySubCategoryAndI_grid[0]?.championship_category_id || 0,
          subCategory: fSubCategory[s]?.id || 0,
          i_grid: ig,
          maxPlace: maxPlaceGrid,
          grid: [],
        };
        // u = 8, 4, 2
        for (let u = maxPlaceGrid; u > 1; u /= 2) {
          let bll = maxPlaceGrid / u; // 1, 2, 4, 8
          let p: any[] = [];
          // 2, 4, 6, 8
          for (let index = bll; index <= maxPlaceGrid; index += bll) {
            let k: any[] = [];
            for (let v = index - bll; v < index; v++) {
              let findGrid: SType & participantType =
                fGridBySubCategoryAndI_grid.find(
                  (item: SType & participantType) =>
                    item?.i_inside_each_grid === v
                ) || {};
              if (findGrid?.id) {
                k = [...k, findGrid];
              } else {
                k = [...k, v];
              }
            }
            p = [...p, k];
          }
          dd = [...dd, p];
        }
        ddd.grid = dd;
        fullBrackets = [...fullBrackets, ddd];
      }
    }
  }
  return fullBrackets;
};
