import { FC, useMemo } from "react";
import "./style.css";
import { Users } from "../../../../../../store/loginSlice";
import { AdmissionAdminsType, SType } from "../../../../../../store/setkaSlice";
import { participantType } from "../../../../../../store/settingSlice";
import { Link } from "react-router-dom";
import { url } from "../../../../../../store/url";
import { useAppSelector } from "../../../../../../store/hook";

type ParticipantMapType = {
  participant: SType & participantType;
  Users: Users[];
  AdmissionAdmin: AdmissionAdminsType[];
  championship_id: number;
};

const Api = url();

export const ParticipantMap: FC<ParticipantMapType> = ({
  participant,
  Users,
  AdmissionAdmin,
  championship_id,
}) => {
  const Category = useAppSelector(
    (state) => state.setting.championshipCategory
  );
  const SubCategory = useAppSelector(
    (state) => state.setting.championshipSubCategory
  );
  let c = useMemo(() => {
    let f = Users.find((item) => item?.id === participant?.child) || {};
    return f;
  }, [Users, participant]);

  let coach = useMemo(() => {
    let f = Users.find((item) => item?.id === participant?.coach) || {};
    return f;
  }, [Users, participant]);

  let fAdmissionAdmin = useMemo(() => {
    let f: AdmissionAdminsType =
      AdmissionAdmin.find(
        (item: AdmissionAdminsType) =>
          item?.championship_id === championship_id && item?.child === c?.id
      ) || {};

    return f;
  }, [AdmissionAdmin, championship_id, c]);

  let findCategory = useMemo(() => {
    let f =
      Category.find(
        (item) => item?.id === participant?.championship_category_id
      ) || {};

    return f;
  }, [Category, participant]);

  let findSubCategory = useMemo(() => {
    let f =
      SubCategory.find(
        (item) => item?.id === participant?.championship_sub_category_id
      ) || {};

    return f;
  }, [SubCategory, participant]);

  return (
    <div className="participantMapContainer">
      <span className="ava" id="participantAva">
        {c?.ava ? (
          <img
            className="avaImg"
            src={Api + "storage/avatar/prev_" + c?.ava}
            alt=""
          />
        ) : (
          <img
            className="avaImg"
            src={"https://yaponaroll.ru/images/20200701_001.jpg"}
            alt=""
          />
        )}
      </span>
      <span className="dataParticipant">
        <span className="nameDataPart">{c?.name}</span>
        <Link to={"/" + c?.login} className="loginDataParti">
          login: {c?.login}
        </Link>
        <span className="yearDataParti">year: {c?.year}</span>
        <span className="coachDataParti">coach: {coach?.name}</span>
        <span className="categoriesChild">
          <span>{findCategory?.categoryABC}</span>
          <span>{findCategory?.name}</span>
          <span>{findSubCategory?.name}</span>
        </span>

        {fAdmissionAdmin?.allow === 1 ? (
          <span className="allowAdmin">Allow</span>
        ) : (
          <></>
        )}
      </span>
    </div>
  );
};
