import React, { useState, useEffect } from "react";
import { findTimeZone, getUTCOffset, listTimeZones } from "timezone-support";

type TimezoneSelectType = {
  creator_timezone: string;
  setCreator_timezone: any;
};

const TimezoneSelect: React.FC<TimezoneSelectType> = ({
  creator_timezone,
  setCreator_timezone,
}) => {
  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCreator_timezone(userTimezone); // Автоматически устанавливаем часовой пояс пользователя
  }, []);

  // Получаем список всех временных зон
  const timezones = listTimeZones().map((tz) => {
    const zone = findTimeZone(tz);
    const offset = -getUTCOffset(new Date(), zone).offset; // Смещение в минутах

    // Форматируем смещение в "+05:00"
    const sign = offset >= 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offset) / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (Math.abs(offset) % 60).toString().padStart(2, "0");

    return {
      name: tz,
      formatted: `${tz} ${sign}${hours}:${minutes}`,
    };
  });

  return (
    <div>
      <label>Выберите часовой пояс:</label>
      <select
        value={creator_timezone}
        onChange={(e) => setCreator_timezone(e.target.value)}
      >
        {timezones.map((tz) => (
          <option key={tz.name} value={tz.name}>
            {tz.formatted}
          </option>
        ))}
      </select>
      {creator_timezone && <p>Вы выбрали: {creator_timezone}</p>}
    </div>
  );
};

export default TimezoneSelect;
