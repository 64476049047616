import { FC, useEffect, useMemo, useState } from "react";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../../../../store/hook";
import {
  AdminType,
  DayChampionshipType,
  SType,
  cordCategoryType,
  cordChampionshipType,
  createCord,
  createDay,
  deleteCord,
  deleteDay,
  fightDuration,
  getCord,
  getCordCategory,
  getDay,
  reverbCord,
  startTime,
  updateCord,
  updateDay,
} from "../../../../../store/setkaSlice";
import { FaCheck, FaPencilAlt } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { MdAccessTime, MdDateRange, MdDelete } from "react-icons/md";
import {
  Bracket,
  LType,
  createChampType,
  createKTGType,
  participantType,
  subCategoryType,
} from "../../../../../store/settingSlice";
import { Users } from "../../../../../store/loginSlice";
import { SelectDuel } from "./SelectDuel/page";
import { fullBracketsFunction } from "./functionBrackets";
import { MatchChampionships } from "./MatchChampionships/page";
import subscriptionManager from "../../../../../SubscriptionManager";
import { LuSwords } from "react-icons/lu";

type TimeChampType = {
  championship_id: number;
  championship: createChampType;
  user: Users;
  fCategory: createKTGType[];
  fSubCategoryChamp: subCategoryType[];
  mergedGrid: SType[] & participantType[];
  login: Users[];
  fAdmin: AdminType[];
  MyChild: (SType & participantType)[];
  AssocChild: (SType & participantType)[];
};

export const Time: FC<TimeChampType> = ({
  mergedGrid,
  championship_id,
  championship,
  user,
  fCategory,
  fSubCategoryChamp,
  login,
  fAdmin,
  MyChild,
  AssocChild,
}) => {
  const myUser = useAppSelector((state) => state.login.user);
  const [h, setH] = useState(Number(new Date()));

  setInterval(() => {
    setH(Number(new Date()));
  }, 60000);

  function isEven(value: any) {
    if (value % 2 == 0) return true;
    else return false;
  }

  const [name, setName] = useState("");
  const [nameDay, setNameDay] = useState("");
  const [clickDayId, setClickDayId] = useState<number>(0);
  const [clickCordId, setClickCordId] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setClickCordId(0);
  }, [clickDayId]);

  const save = () => {
    dispatch(
      createCord({
        championship_id,
        name,
        championship_day_id: clickDayId,
      })
    );
    setName("");
  };

  const saveDay = () => {
    dispatch(createDay({ championship_id, name: nameDay }));
    setNameDay("");
  };

  const Days = useAppSelector((state: any) => state.setka.championshipDay);
  let fDays = useMemo(() => {
    let f: DayChampionshipType[] = Days.filter(
      (item: DayChampionshipType) => item?.championship_id === championship_id
    );
    return f;
  }, [Days]);

  const Cords = useAppSelector((state: any) => state.setka.championshipCord);
  let fCords = useMemo(() => {
    let f: cordChampionshipType[] = Cords.filter(
      (item: cordChampionshipType) => item?.championship_id === championship_id
    );
    return f;
  }, [Cords]);

  let findFCord: cordChampionshipType = useMemo(() => {
    if (fCords?.length > 0 && clickCordId > 0) {
      let f: cordChampionshipType =
        fCords.find((item: cordChampionshipType) => item?.id === clickCordId) ||
        {};
      return f;
    }
    return {};
  }, [clickCordId, fCords]);

  const [selectDuel, setSelectDuel] = useState(false);
  const [start_time, setStart_time] = useState("");
  const [fight_duration, setFight_duration] = useState("");

  let st = Number(new Date(start_time));

  // Функция для преобразования времени hh:mm:ss в миллисекунды
  const convertToMilliseconds = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return (hours * 3600 + minutes * 60 + seconds) * 1000;
  };

  useEffect(() => {
    setStart_time(findFCord?.start_time ? findFCord?.start_time : "");
    setFight_duration(
      findFCord?.fight_duration ? findFCord?.fight_duration : ""
    );
  }, [findFCord]);

  let fCordsClick = useMemo(() => {
    let f: cordChampionshipType[] = fCords.filter(
      (item: cordChampionshipType) => item?.championship_day_id === clickDayId
    );
    return f;
  }, [fCords, clickDayId]);

  useEffect(() => {
    dispatch(getDay({ championship_id }));
    dispatch(getCord({ championship_id }));
  }, [championship_id]);

  const CordCategory = useAppSelector(
    (state: any) => state.setka.championshipCordCategory
  );

  let fCordCategory = useMemo(() => {
    // Создаём множество идентификаторов из fCords
    const cordIdsToFilter = new Set(fCords?.map((cord) => cord.id) || []);

    // Фильтруем CordCategory, проверяя наличие championship_cord_id в Set
    let fCordCategoryMemo: cordCategoryType[] = CordCategory.filter(
      (item: cordCategoryType) =>
        cordIdsToFilter.has(item?.championship_cord_id)
    );

    return fCordCategoryMemo;
  }, [CordCategory, Cords, fCords]);

  const Category = useAppSelector(
    (state) => state.setting.championshipCategory
  );
  const SubCategory = useAppSelector(
    (state: any) => state.setting.championshipSubCategory
  );

  let fSubCategory = useMemo(() => {
    // Создаём множество championship_category_id из fCordCategory
    const categoryIdsToFilter = new Set(
      fCordCategory?.map((c) => c.championship_category_id) || []
    );

    // Фильтруем SubCategory, проверяя наличие championship_category_id в Set
    let fSubCategoryMemo: subCategoryType[] = SubCategory.filter(
      (item: subCategoryType) =>
        categoryIdsToFilter.has(item?.championship_category_id)
    );

    return fSubCategoryMemo;
  }, [CordCategory, fCordCategory, SubCategory]);

  useEffect(() => {
    if (fCords.length > 0 && fCordCategory.length === 0) {
      const championshipCordIds = fCords.map((item: any) => item?.id);
      dispatch(getCordCategory({ championshipCordIds }));
    }
  }, [Cords]);

  let fullBracket: Bracket[] = useMemo(() => {
    return fullBracketsFunction(fSubCategory, mergedGrid);
  }, [fSubCategory, mergedGrid]);

  let matches: LType[] = useMemo(() => {
    let l: LType[] = [];
    let adjustedIndex: number = 0;
    let currentCord: number | null = fCords[0]?.id ?? 0;
    for (let c = 0; c < fCords.length; c++) {
      // bp = 8, 4, 2
      for (let bp = Number(mergedGrid[0]?.place); bp > 1; bp /= 2) {
        for (let i = 0; i < fullBracket.length; i++) {
          for (let g = 0; g < fullBracket[i].grid.length; g++) {
            if (fullBracket[i].grid[g].length === bp) {
              const maxPlaseBracked = fullBracket[i].grid[0].length;
              for (let gg = 0; gg < fullBracket[i].grid[g].length; gg++) {
                for (let j = 0; j < fCordCategory.length; j++) {
                  let a: LType = {
                    number: 0,
                    cord: 0,
                    category: 0,
                    subCategory: 0,
                    place: 0,
                    participant1: [],
                    participant2: [],
                    child1: 0,
                    child2: 0,
                    day_id: 0,
                    adjustedIndex: 0,
                    msFightDuration: 0,
                    start_time: "",
                  };
                  //esli pervih boyah ----------------------------------------------
                  if (
                    maxPlaseBracked === bp &&
                    isEven(gg) &&
                    fullBracket[i]?.grid[g][gg][0]?.id &&
                    fullBracket[i]?.grid[g][gg + 1][0]?.id &&
                    fullBracket[i]?.grid[g][gg][0]?.championship_category_id ===
                      fCordCategory[j]?.championship_category_id &&
                    fCords[c]?.id === fCordCategory[j]?.championship_cord_id
                  ) {
                    if (
                      l.length === 0 ||
                      l[l.length - 1]?.cord !== fCords[c]?.id
                    ) {
                      a.number = 1;
                    } else {
                      a.number = Number(l[l.length - 1]?.number) + 1;
                    }
                    a.place = bp;
                    a.cord = fCordCategory[j]?.championship_cord_id || 0;
                    a.category =
                      fullBracket[i].grid[g][gg][0]?.championship_category_id ||
                      0;
                    a.subCategory =
                      fullBracket[i].grid[g][gg][0]
                        ?.championship_sub_category_id || 0;
                    a.participant1 = [fullBracket[i].grid[g][gg][0]?.id];
                    a.participant2 = [fullBracket[i].grid[g][gg + 1][0]?.id];
                    a.child1 = fullBracket[i].grid[g][gg][0]?.child;
                    a.child2 = fullBracket[i].grid[g][gg + 1][0]?.child;
                    a.maxPlace = maxPlaseBracked;
                    a.day_id = fCords[c]?.championship_day_id;

                    // Создаём множества для быстрой проверки идентификаторов
                    const part1Ids = new Set(a.participant1);
                    const part2Ids = new Set(a.participant2);

                    // Функция для поиска участника по условиям
                    const findParticipant = (
                      idsSet: Set<number>,
                      placeCondition: (itemPlace: number) => boolean
                    ): SType & participantType =>
                      mergedGrid.find(
                        (item: SType & participantType) =>
                          idsSet.has(item?.id) &&
                          placeCondition(Number(item?.place))
                      ) || { id: null };

                    // Ищем участников с совпадающим местом
                    let findPart1: SType & participantType = findParticipant(
                      part1Ids,
                      (place) => place === Number(a?.place)
                    );
                    let findPart2: SType & participantType = findParticipant(
                      part2Ids,
                      (place) => place === Number(a?.place)
                    );
                    a.findPart1 = findPart1;
                    a.findPart2 = findPart2;

                    // Ищем участников с местом меньше, чем у текущего match
                    let partOld1: SType & participantType = findParticipant(
                      part1Ids,
                      (place) => place < Number(a?.place)
                    );
                    let partOld2: SType & participantType = findParticipant(
                      part2Ids,
                      (place) => place < Number(a?.place)
                    );
                    a.partOld1 = partOld1;
                    a.partOld2 = partOld2;
                    a.start_time = fCords[c]?.start_time;
                    a.msFightDuration = convertToMilliseconds(
                      fCords[c]?.fight_duration ?? "" // Если undefined, передастся ""
                    );

                    if (!a.partOld1?.id && !a.partOld2?.id) {
                      if (a.cord !== currentCord) {
                        currentCord = a.cord || null;
                        adjustedIndex = 1; // Сбрасываем индекс на 1 для нового значения cord
                      } else {
                        adjustedIndex++; // Увеличиваем индекс для того же значения cord
                      }
                      a.adjustedIndex = adjustedIndex;
                    }

                    if (a?.cord || 0 > 0) {
                      l = [...l, a];
                    }
                  }

                  // vtorih i ostalnih boyah ----------------------------------------------
                  if (maxPlaseBracked > bp && isEven(gg)) {
                    // number
                    if (
                      l.length === 0 ||
                      l[l.length - 1]?.cord !== fCords[c]?.id
                    ) {
                      a.number = 1;
                    } else {
                      a.number = Number(l[l.length - 1]?.number) + 1;
                    }
                    a.place = bp;
                    a.cord = 0;
                    a.category = 0;
                    a.subCategory = 0;
                    a.child1 = 0;
                    a.child2 = 0;
                    a.child1 = 0;
                    a.child2 = 0;
                    a.day_id = 0;
                    a.adjustedIndex = 0;
                    a.msFightDuration = 0;
                    a.maxPlace = maxPlaseBracked;
                    a.start_time = "";

                    let participant1: any[] = [];
                    let participant2: any[] = [];
                    if (fullBracket[i].grid[g].length === bp) {
                      let category_id = null;
                      for (
                        let ggi = 0;
                        ggi < fullBracket[i].grid[g][gg].length;
                        ggi++
                      ) {
                        // up -----------------------------------------------
                        if (
                          fullBracket[i].grid[g][gg][ggi]?.id &&
                          fullBracket[i].grid[g][gg][ggi]
                            ?.championship_category_id ===
                            fCordCategory[j]?.championship_category_id &&
                          fCords[c]?.id ===
                            fCordCategory[j]?.championship_cord_id
                        ) {
                          // if (fullBracket[i].grid[g][gg][ggi]?.id) {
                          participant1 = [
                            ...participant1,
                            fullBracket[i].grid[g][gg][ggi]?.id,
                          ];
                          // }
                          a.participant1 = participant1;

                          if (fullBracket[i].grid[g][gg][ggi]?.place === bp) {
                            a.child1 = fullBracket[i].grid[g][gg][ggi]?.child;
                          }

                          if (category_id === null) {
                            category_id =
                              fullBracket[i].grid[g][gg][ggi]
                                ?.championship_category_id;
                          }
                        }

                        // down -----------------------------------------------
                        if (
                          fullBracket[i].grid[g][gg + 1][ggi]?.id &&
                          fullBracket[i].grid[g][gg + 1][ggi]
                            ?.championship_category_id ===
                            fCordCategory[j]?.championship_category_id &&
                          fCords[c]?.id ===
                            fCordCategory[j]?.championship_cord_id
                        ) {
                          // if (fullBracket[i].grid[g][gg + 1][ggi]?.id) {
                          participant2 = [
                            ...participant2,
                            fullBracket[i].grid[g][gg + 1][ggi]?.id,
                          ];
                          // }
                          a.participant2 = participant2;
                          if (
                            fullBracket[i].grid[g][gg + 1][ggi]?.place === bp
                          ) {
                            a.child2 =
                              fullBracket[i].grid[g][gg + 1][ggi]?.child;
                          }
                          if (category_id === null) {
                            category_id =
                              fullBracket[i].grid[g][gg + 1][ggi]
                                ?.championship_category_id;
                          }
                        }

                        // if (
                        //   a.category === 0 &&
                        //   fullBracket[i].grid[g][gg][ggi]?.id
                        // ) {
                        //   a.category = fullBracket[i]?.category || 0;
                        //   a.subCategory = fullBracket[i]?.subCategory || 0;
                        // } else {
                        //   if (
                        //     a.category === 0 &&
                        //     fullBracket[i].grid[g][gg + 1][ggi]?.id
                        //   ) {
                        //     a.category = fullBracket[i]?.category || 0;
                        //     a.subCategory = fullBracket[i]?.subCategory || 0;
                        //   }
                        // }

                        // if (
                        //   (fullBracket[i].grid[g][gg][ggi]?.id ||
                        //     fullBracket[i].grid[g][gg + 1][ggi]?.id) &&
                        //   (fullBracket[i].grid[g][gg + 1][ggi]
                        //     ?.championship_category_id ===
                        //     fCordCategory[j]?.championship_category_id ||
                        //     fullBracket[i].grid[g][gg][ggi]
                        //       ?.championship_category_id ===
                        //       fCordCategory[j]?.championship_category_id) &&
                        //   fCords[c]?.id ===
                        //     fCordCategory[j]?.championship_cord_id &&
                        //   p === false
                        // ) {
                      }

                      // dalshe dlya ustanovlenya vremya ----------------------------------------------
                      if (
                        // (fullBracket[i].grid[g][gg][ggi]?.id ||
                        //   fullBracket[i].grid[g][gg + 1][ggi]?.id) &&
                        // (fullBracket[i].grid[g][gg][ggi]
                        //   ?.championship_category_id ===
                        //   fCordCategory[j]?.championship_category_id ||
                        category_id &&
                        category_id ===
                          fCordCategory[j]?.championship_category_id &&
                        fCords[c]?.id === fCordCategory[j]?.championship_cord_id
                      ) {
                        // Создаём множества для быстрой проверки идентификаторов
                        const part1Ids = new Set(a.participant1);
                        const part2Ids = new Set(a.participant2);

                        // Функция для поиска участника по условиям
                        const findParticipant = (
                          idsSet: Set<number>,
                          placeCondition: (itemPlace: number) => boolean
                        ): SType & participantType =>
                          mergedGrid.find(
                            (item: SType & participantType) =>
                              idsSet.has(item?.id) &&
                              placeCondition(Number(item?.place))
                          ) || { id: null };

                        // Ищем участников с совпадающим местом
                        let findPart1: SType &
                          participantType = findParticipant(
                          part1Ids,
                          (place) => place === Number(a?.place)
                        );
                        let findPart2: SType &
                          participantType = findParticipant(
                          part2Ids,
                          (place) => place === Number(a?.place)
                        );
                        a.findPart1 = findPart1;
                        a.findPart2 = findPart2;

                        // Ищем участников с местом меньше, чем у текущего match
                        let partOld1: SType & participantType = findParticipant(
                          part1Ids,
                          (place) => place < Number(a?.place)
                        );
                        let partOld2: SType & participantType = findParticipant(
                          part2Ids,
                          (place) => place < Number(a?.place)
                        );
                        a.partOld1 = partOld1;
                        a.partOld2 = partOld2;
                        // console.log({
                        //   O1: a.partOld1?.id,
                        //   O2: a.partOld2?.id,
                        //   F1: a.findPart1?.id,
                        //   F2: a.findPart2?.id,
                        //   currentCord,
                        //   adjustedIndex,
                        //   full1: fullBracket[i].grid[g][gg][ggi]?.id,
                        //   full2: fullBracket[i].grid[g][gg + 1][ggi]?.id,
                        // });

                        a.cord = fCordCategory[j]?.championship_cord_id || 0;
                        a.day_id = fCords[c]?.championship_day_id;
                        a.msFightDuration = convertToMilliseconds(
                          fCords[c]?.fight_duration ?? "" // Если undefined, передастся ""
                        );
                        a.category = fullBracket[i]?.category || 0;
                        a.subCategory = fullBracket[i]?.subCategory || 0;
                        a.start_time = fCords[c]?.start_time;
                      }
                      if (a.cord > 0) {
                        if (!a.partOld1?.id && !a.partOld2?.id) {
                          if (a.cord !== currentCord) {
                            currentCord = a.cord;
                            adjustedIndex = 1; // Сбрасываем индекс на 1 для нового значения cord
                          } else {
                            adjustedIndex++; // Увеличиваем индекс для того же значения cord
                          }
                          a.adjustedIndex = adjustedIndex;
                        } else {
                          a.adjustedIndex = 0;
                        }

                        console.log({ adjustedIndex });
                        console.log({ a });
                        l = [...l, a];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return l;
  }, [fCordCategory, mergedGrid, fullBracket, Cords]);

  let fmatches = useMemo(() => {
    let f: LType[] = [];
    if (clickDayId === 0) {
      if (user?.position === 3) {
        AssocChild.map((item: participantType) => {
          let matchCoach = matches.filter(
            (m: any) => m?.child1 === item.child || m?.child2 === item.child
          );
          f = [...f, ...matchCoach];
        });
        const uniqueItems: LType[] = f.filter(
          (item, index, array) =>
            array.findIndex(
              (i) => i.number === item.number && i.cord === item.cord
            ) === index
        );

        const filteredItems = uniqueItems.filter(
          (item) => item.number !== undefined
        );

        // Вычисляем newTime и сортируем только по нему
        const sortedItems = filteredItems.sort((a, b) => {
          const newTimeA = (a.msFightDuration ?? 0) * (a.adjustedIndex ?? 1);
          const newTimeB = (b.msFightDuration ?? 0) * (b.adjustedIndex ?? 1);
          return newTimeA - newTimeB;
        });

        return sortedItems;
      }
      if (user?.position === 2) {
        MyChild.map((item: participantType) => {
          let matchCoach = matches.filter(
            (m: any) => m?.child1 === item.child || m?.child2 === item.child
          );
          f = [...f, ...matchCoach];
        });
        const uniqueItems: LType[] = f.filter(
          (item, index, array) =>
            array.findIndex(
              (i) => i.number === item.number && i.cord === item.cord
            ) === index
        );

        const filteredItems = uniqueItems.filter(
          (item) => item.number !== undefined
        );

        // Вычисляем newTime и сортируем только по нему
        const sortedItems = filteredItems.sort((a, b) => {
          const newTimeA = (a.msFightDuration ?? 0) * (a.adjustedIndex ?? 1);
          const newTimeB = (b.msFightDuration ?? 0) * (b.adjustedIndex ?? 1);
          return newTimeA - newTimeB;
        });

        return sortedItems;
      }
      if (user?.position === 1) {
        f = matches.filter(
          (item: LType) =>
            item?.child1 === myUser?.id || item?.child2 === myUser?.id
        );
        return f;
      }
    }
    f = matches.filter((item: LType) => item?.cord === clickCordId);
    return f;
  }, [
    matches,
    fCords,
    fCordCategory,
    mergedGrid,
    fullBracket,
    clickCordId,
    clickDayId,
    MyChild,
  ]);

  let fCordCategoryCordId: cordCategoryType[] = useMemo(() => {
    let fC: cordCategoryType[] = CordCategory.filter(
      (item: cordCategoryType) => item?.championship_cord_id === clickCordId
    );
    return fC;
  }, [clickCordId, CordCategory]);

  const saveStartTime = () => {
    if (start_time !== "" && clickCordId > 0) {
      dispatch(startTime({ id: clickCordId, start_time }));
    }
  };

  const saveFight_duration = () => {
    if (fight_duration !== "" && clickCordId > 0) {
      dispatch(fightDuration({ id: clickCordId, fight_duration }));
    }
  };

  const date_start_time = new Date(start_time);

  // Форматируем дату в нужный вид: DD.MM.YYYY HH:MM
  const formattedStartDate = date_start_time.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedStartTime = date_start_time.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // function formatDateToISO(date: any) {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // добавляем 1, так как месяцы от 0 до 11
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");

  //   return `${year}-${month}-${day}T${hours}:${minutes}`;
  // }

  useEffect(() => {
    const channelName = "channel-cord-championship." + championship_id;
    const eventName = "cord-event";

    // Подписка на событие
    subscriptionManager.subscribe(channelName, eventName, (data) => {
      dispatch(reverbCord(data.data));
    });

    // Отписка при размонтировании компонента
    return () => {
      subscriptionManager.unsubscribe(channelName);
    };
  }, []);

  return (
    <div className="container_time">
      {h < st ? (
        <div className="cord_start_time">
          <div>Начало соревнований</div>
          <div>
            <MdDateRange className="IconCord" /> {formattedStartDate}
          </div>
          <div>
            <MdAccessTime className="IconCord" />
            {formattedStartTime}
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {championship?.creator === user?.id ? (
        <form action="" className="formTime">
          <input
            type="text"
            value={nameDay}
            onChange={(e) => setNameDay(e.target.value)}
            id="registerInput"
            placeholder="Day"
          />
          <input type="button" onClick={saveDay} value="Save" />
        </form>
      ) : null}
      <div className="sportInfo">
        {user?.position === 3 && (
          <div
            className="nameChampCategory"
            id={clickDayId === 0 ? "childFightClick" : "childFight"}
            onClick={(e) => setClickDayId(0)}
          >
            Наши ученики
          </div>
        )}
        {user?.position === 2 && (
          <div
            className="nameChampCategory"
            id={clickDayId === 0 ? "childFightClick" : "childFight"}
            onClick={(e) => setClickDayId(0)}
          >
            Мои ученики
          </div>
        )}
        {user?.position === 1 && (
          <div
            className="nameChampCategory"
            id={clickDayId === 0 ? "childFightClick" : "childFight"}
            onClick={(e) => setClickDayId(0)}
          >
            Моя схватка
          </div>
        )}

        {fDays.map((c) => (
          <ChampDayMap
            name={c?.name}
            id={c?.id}
            championship={championship}
            user={user}
            setClickDayId={setClickDayId}
            clickDayId={clickDayId}
          />
        ))}
      </div>
      {clickDayId > 0 && championship?.creator === user?.id ? (
        <form action="" className="formTime">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="registerInput"
            placeholder="Cord name"
          />
          <input type="button" onClick={save} value="Save" />
        </form>
      ) : null}
      <div className="sportInfo">
        {fCordsClick.map((c) => (
          <CordMap
            matches={matches}
            name={c?.name}
            id={c?.id}
            championship={championship}
            user={user}
            setClickCordId={setClickCordId}
            clickCordId={clickCordId}
          />
        ))}
      </div>
      {championship?.creator === user?.id && Number(clickCordId) > 0 && (
        <div className="cord_date_time">
          <input
            type="button"
            value={"Добавить поединок"}
            onClick={(e) => setSelectDuel(true)}
          />
          <div>
            <label htmlFor="">Начало соревнований</label>
            <input
              type="datetime-local"
              value={start_time}
              onChange={(e) => setStart_time(e.target.value)}
            />
            <input type="button" value="Save" onClick={saveStartTime} />
          </div>
          <div>
            <label htmlFor="">Продолжительность одного боя</label>
            <input
              type="time"
              value={fight_duration}
              step="1"
              onChange={(e) => setFight_duration(e.target.value)}
            />
            <input type="button" value="Save" onClick={saveFight_duration} />
          </div>
        </div>
      )}
      {selectDuel && (
        <>
          {" "}
          <div
            className="selectMyChild"
            onClick={(e) => setSelectDuel(false)}
          ></div>
          <div className="selChild">
            {fCategory.map((category) => (
              <SelectDuel
                category={category}
                clickCordId={clickCordId}
                fCordCategory={fCordCategory}
              />
            ))}
          </div>
        </>
      )}

      <div className="containerMatches">
        {fmatches.map((match: any) => (
          <MatchChampionships
            h={h}
            clickCordId={clickCordId}
            championship={championship}
            user={user}
            fAdmin={fAdmin}
            match={match}
            login={login}
            Category={Category}
            SubCategory={SubCategory}
            fCords={fCords}
            clickDayId={clickDayId}
          />
        ))}
      </div>
    </div>
  );
};

type DayMapType = {
  name: string | undefined;
  id: number | undefined;
  championship: createChampType;
  user: Users;
  setClickDayId: any;
  clickDayId: any;
};

const ChampDayMap: FC<DayMapType> = ({
  name,
  id,
  championship,
  user,
  setClickDayId,
  clickDayId,
}) => {
  const [updateName, setUpdateName] = useState(name);
  const [Input, setInput] = useState(false);

  useEffect(() => {
    if (Input) {
      setUpdateName(updateName);
    }
  }, [Input, name]);

  const dispatch = useAppDispatch();

  const deleteDayChamp = () => {
    let del = window.confirm(`Точно хотите удалить ${name}?`);
    if (del) {
      dispatch(deleteDay({ id }));
    }
  };
  const updateDayChamp = () => {
    dispatch(updateDay({ id, name: updateName }));
    setInput(false);
  };

  return (
    <>
      {Input ? (
        <main>
          <input
            type="text"
            autoFocus
            id="champCategoryInput"
            value={updateName}
            onChange={(e) => setUpdateName(e.target.value)}
          />
          <FaCheck onClick={updateDayChamp} />
          <ImCancelCircle onClick={(e) => setInput(false)} />
        </main>
      ) : (
        <div
          id={clickDayId === id ? "click" : ""}
          className="nameChampCategory"
          onClick={(e) => setClickDayId(id)}
        >
          {name}
          {
            <main className="champRemoveCategoryIcons">
              {championship?.creator === user?.id && (
                <FaPencilAlt
                  className="PenChampCategory"
                  onClick={(e) => setInput(true)}
                />
              )}
              {championship?.creator === user?.id && (
                <MdDelete
                  className="PenChampCategory"
                  onClick={deleteDayChamp}
                />
              )}
            </main>
          }
        </div>
      )}
    </>
  );
};

type CordMapType = {
  name: string | undefined;
  id: number | undefined;
  championship: createChampType;
  user: Users;
  setClickCordId: any;
  clickCordId: any;
  matches: LType[];
};

const CordMap: FC<CordMapType> = ({
  name,
  id,
  championship,
  user,
  setClickCordId,
  clickCordId,
  matches,
}) => {
  const [updateName, setUpdateName] = useState(name);
  const [Input, setInput] = useState(false);

  useEffect(() => {
    if (Input) {
      setUpdateName(updateName);
    }
  }, [Input, name]);

  const dispatch = useAppDispatch();

  const deleteCordChamp = () => {
    let del = window.confirm(`Точно хотите удалить ${name}?`);
    if (del) {
      dispatch(deleteCord({ id }));
    }
  };
  const updateCordChamp = () => {
    dispatch(updateCord({ id, name: updateName }));
    setInput(false);
  };

  let match: LType = useMemo(() => {
    let f = matches.find(
      (item) => item?.cord === id && item?.adjustedIndex === 1
    ) || {
      child1: 0,
      child2: 0,
      adjustedIndex: 0,
      msFightDuration: 0,
    };

    return f;
  }, [matches, id]);

  return (
    <>
      {Input ? (
        <main>
          <input
            type="text"
            autoFocus
            id="champCategoryInput"
            value={updateName}
            onChange={(e) => setUpdateName(e.target.value)}
          />
          <FaCheck onClick={updateCordChamp} />
          <ImCancelCircle onClick={(e) => setInput(false)} />
        </main>
      ) : (
        <div
          id={clickCordId === id ? "click" : ""}
          className="nameChampCategory"
          onClick={(e) => setClickCordId(id)}
        >
          {name}
          {match?.number ? (
            <main
              className="swordChampTime"
              id={clickCordId === id ? "clickSwordChampTime" : ""}
            >
              <LuSwords className="cordLuSwords" /> №{match?.number}
            </main>
          ) : null}
          {
            <main className="champRemoveCategoryIcons">
              {championship?.creator === user?.id && (
                <FaPencilAlt
                  className="PenChampCategory"
                  onClick={(e) => setInput(true)}
                />
              )}
              {championship?.creator === user?.id && (
                <MdDelete
                  className="PenChampCategory"
                  onClick={deleteCordChamp}
                />
              )}
            </main>
          }
        </div>
      )}
    </>
  );
};
