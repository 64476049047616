import axios from "axios";
import { url } from "./url";
import { Navigate } from "react-router-dom";

let Apiq = url();

export const api = axios.create();

// Переменные для контроля обновления токена
let isRefreshing = false;
let subscribers: any[] = [];

// Функция для подписки на обновление токена
function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb);
}

// Функция для оповещения всех подписчиков
function onRefreshed(newToken: any) {
  subscribers.forEach((cb) => cb(newToken));
  subscribers = [];
}

// 🚀 **Request Interceptor** (добавляет токен в заголовок)
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("attt");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 🚀 **Response Interceptor** (обрабатывает ошибки 401 и обновляет токен)
api.interceptors.response.use(
  (response) => response, // Если нет ошибки, просто возвращаем ответ
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      (error.response.data.message === "Token has expired" ||
        error.response.data.message === "Unauthenticated.")
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await axios.post(
            Apiq + "api/auth/refresh",
            {},
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem("attt")}`,
              },
            }
          );

          const newToken = response.data.access_token;
          localStorage.setItem("attt", newToken);
          onRefreshed(newToken); // Оповещаем всех подписчиков

          isRefreshing = false;
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((newToken: any) => {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          resolve(api(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export const apiBot = axios.create();

apiBot.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("attt")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("attt")}`;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      return <Navigate to={"/signin"} />;
    }
  }
);

//  end Request
apiBot.interceptors.response.use(
  (config) => {
    if (localStorage.getItem("attt")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("attt")}`;
    }
    return config;
  },
  (error) => {
    const originalRequest = error.config;

    if (
      error.response.data.message === "Token has expired" ||
      error.response.data.message === "Unauthenticated." ||
      error.response.data.message === "The token has been blacklisted"
    ) {
      // return <Navigate to={"/signin"} />
      return axios
        .post(
          Apiq + "api/auth/refresh",
          {},
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("attt")}`,
            },
          }
        )
        .then((res) => {
          console.log("Aktan");
          localStorage.setItem("attt", res.data.access_token);
          error.config.headers.Authorization = `Bearer ${localStorage.getItem(
            "attt"
          )}`;

          return apiBot.request(error.config);
        })
        .catch((error) => {
          // apiBot.request(error.config);
          error.config.headers.Authorization = `Bearer ${localStorage.getItem(
            "attt"
          )}`;
          const originalRequest2 = error.config;

          const retryResponse = axios.post(
            originalRequest.url,
            originalRequest.data,
            {
              headers: originalRequest2.headers,
            }
          );
          return retryResponse;
        });
    }
    console.log(error);
  }
);

export const instancePOST = axios.create({
  baseURL: Apiq,
  headers: {
    authorization: `Bearer ${localStorage.getItem("attt")}`,
    "Content-Type": "multipart/form-data",
  },
});
export const instanceJsonPOST = axios.create({
  baseURL: Apiq,
  headers: {
    authorization: `Bearer ${localStorage.getItem("attt")}`,
    "Content-type": "application/json",
  },
});
export const instanceGET = axios.create({
  baseURL: Apiq,
  headers: { "Content-type": "application/json" },
});
