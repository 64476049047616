import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { regStatusType } from "./loginSlice";
import { instancePOST, instanceGET, api } from "./axiosInstans";
import { url } from "./url";
import { SType } from "./setkaSlice";

const Apiq = url();

interface MyError {
  response?: {
    data: {
      message: string;
    };
  };
}

export type LType = {
  place?: number;
  number?: number;
  cord?: number;
  category?: number;
  subCategory?: number;
  participant1?: any[];
  participant2?: any[];
  maxPlace?: number;
  child1: number;
  child2: number;
  day_id?: number;
  start_time?: string;
  findPart1?: SType & participantType;
  findPart2?: SType & participantType;
  partOld1?: SType & participantType;
  partOld2?: SType & participantType;
  adjustedIndex: number;
  msFightDuration: number;
};

export type Bracket = {
  category: number;
  subCategory: number;
  i_grid: number;
  maxPlace: number;
  grid: any[];
};

export type KGType = {
  id?: any;
  kg?: any;
  years?: any;
  ktg?: any;
  sport?: any;
};
export type participantType = {
  id?: any;
  coach?: any;
  championship_id?: any;
  child?: any;
  categoryABC?: string;
  gender?: number;
  championship_category_id?: any;
  championship_sub_category_id?: any;
  isChecked?: boolean;
};
export type createChampType = {
  password?: string;
  name?: any;
  creator?: any;
  ranking?: any;
  checkA?: any;
  checkB?: any;
  checkC?: any;
  foto?: any;
  restric?: any;
  tournament_regulations?: any;
  close_register?: any;
  open_tournament?: any;
  close_tournament?: any;
  id?: any;
  country?: any;
  region?: any;
  city?: any;
  association?: number | null;
  creator_timezone?: string;
};
export type createKTGType = {
  id?: number;
  name?: string;
  championship_id?: number;
  categoryABC?: string;
  creator?: number;
  gender?: number;
};
export type subCategoryType = {
  id?: number;
  name?: string;
  championship_id?: number;
  championship_category_id?: number;
  creator?: number;
};
export const getChampionships = createAsyncThunk<
  createChampType[],
  undefined,
  { rejectValue: string }
>("setting/getChampionships", async function (_, { rejectWithValue }) {
  try {
    const response: any = await api.post(Apiq + "api/auth/GetChampionship");
    // dispatch(getAllChamp(response.data));
    return (await response.data.data) as createChampType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const DeleteChampionships = createAsyncThunk<
  createChampType,
  createChampType,
  { rejectValue: string }
>("setting/DeleteChampionships", async function (
  { id, password },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      password,
    };
    const response: any = await api.post(
      Apiq + "api/auth/DeleteChampionship",
      jsonS
    );
    // dispatch(getAllChamp(response.data));
    return (await response.data.data) as createChampType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getParticipant = createAsyncThunk<
  participantType[],
  participantType,
  { rejectValue: string }
>("setting/getParticipant", async function (
  { championship_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetChampionshipParticipant",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as participantType[];
  } catch (error) {
    return rejectWithValue("");
  }
});
export const AddParticipant = createAsyncThunk<
  participantType,
  participantType,
  { rejectValue: string }
>("login/AddParticipant", async function (
  {
    isChecked,
    championship_id,
    child,
    categoryABC,
    championship_category_id,
    championship_sub_category_id,
    gender,
  },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      isChecked,
      championship_id,
      child,
      categoryABC,
      championship_category_id,
      championship_sub_category_id,
      gender,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddChampionshipParticipant",
      jsonS
    );
    console.log({ response });
    // dispatch(addsetka(response.data));
    return (await response.data.data) as participantType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const DeleteParticipant = createAsyncThunk<
  participantType,
  participantType,
  { rejectValue: string }
>("login/DeleteParticipant", async function (
  { id, isChecked, championship_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      isChecked,
      championship_id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddChampionshipParticipant",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as participantType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const createCHampSlice = createAsyncThunk<
  regStatusType,
  createChampType,
  { rejectValue: string }
>("setting/createCHampSlice", async function (
  {
    foto,
    tournament_regulations,
    name,
    ranking,
    checkA,
    checkB,
    checkC,
    restric,
    close_register,
    open_tournament,
    close_tournament,
    country,
    region,
    city,
    creator_timezone,
  },
  { rejectWithValue }
) {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("ranking", ranking);
    formData.append("checkA", checkA);
    formData.append("checkB", checkB);
    formData.append("checkC", checkC);
    formData.append("restric", restric);
    formData.append("close_register", close_register);
    formData.append("open_tournament", open_tournament);
    formData.append("close_tournament", close_tournament);
    formData.append("foto", foto);
    formData.append("tournament_regulations", tournament_regulations);
    formData.append("country", country);
    formData.append("region", region);
    formData.append("city", city);
    formData.append("creator_timezone", creator_timezone || "");

    const response = await instancePOST.post(
      "api/auth/AddChampionship",
      formData
    );
    // dispatch(createChamp(response.data));
    return (await response.data.data) as regStatusType;
  } catch (error) {
    const error2: any = error;
    if (
      error2.response.data?.message === "Token has expired" ||
      error2.response.data.message === "Unauthenticated." ||
      error2.response.data.message === "Wrong number of segments"
    ) {
      return axios
        .post(
          Apiq + "api/auth/refresh",
          {},
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("attt")}`,
            },
          }
        )
        .then(async (res) => {
          localStorage.setItem("attt", res.data.access_token);
          error2.config.headers.Authorization = `Bearer ${localStorage.getItem(
            "attt"
          )}`;

          const res_1 = await instancePOST.request(error2.config);
          return res_1.data.data as createChampType;
        });
    }
    return rejectWithValue("");
  }
});
export const closeChamp = createAsyncThunk<any>(
  "setting/closeChamp",
  async function (_, {}) {
    return;
  }
);
export const AddCategory = createAsyncThunk<
  createKTGType,
  createKTGType,
  { rejectValue: string }
>("setting/AddCategory", async function (
  { name, championship_id, categoryABC, creator, gender },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      name,
      championship_id,
      categoryABC,
      creator,
      gender,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddChampionshipCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const UpdateCategory = createAsyncThunk<
  createKTGType,
  createKTGType,
  { rejectValue: string }
>("setting/UpdateCategory", async function ({ id, name }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
      name,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateChampionshipCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const DeleteCategory = createAsyncThunk<
  createKTGType,
  createKTGType,
  { rejectValue: string }
>("setting/DeleteCategory", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };
    console.log({ jsonS });
    const response: any = await api.post(
      Apiq + "api/auth/DeleteChampionshipCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getCategory = createAsyncThunk<
  createKTGType[],
  createKTGType,
  { rejectValue: string }
>("setting/getCategory", async function (
  { championship_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetChampionshipCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType[];
  } catch (error) {
    return rejectWithValue("");
  }
});

export const AddSubCategory = createAsyncThunk<
  subCategoryType,
  subCategoryType,
  { rejectValue: string }
>("setting/AddSubCategory", async function (
  { name, championship_id, championship_category_id, creator },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      name,
      championship_id,
      championship_category_id,
      creator,
    };

    const response: any = await api.post(
      Apiq + "api/auth/AddChampionshipSubCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const UpdateSubCategory = createAsyncThunk<
  subCategoryType,
  subCategoryType,
  { rejectValue: string }
>("setting/UpdateSubCategory", async function (
  { id, name },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      id,
      name,
    };

    const response: any = await api.post(
      Apiq + "api/auth/UpdateChampionshipSubCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const DeleteSubCategory = createAsyncThunk<
  subCategoryType,
  subCategoryType,
  { rejectValue: string }
>("setting/DeleteSubCategory", async function ({ id }, { rejectWithValue }) {
  try {
    const jsonS = {
      id,
    };

    const response: any = await api.post(
      Apiq + "api/auth/DeleteChampionshipSubCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType;
  } catch (error) {
    return rejectWithValue("");
  }
});
export const getSubCategory = createAsyncThunk<
  subCategoryType[],
  subCategoryType,
  { rejectValue: string }
>("setting/getSubCategory", async function (
  { championship_id },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      championship_id,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetChampionshipSubCategory",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as createKTGType[];
  } catch (error) {
    return rejectWithValue("");
  }
});

export const getPageParticipant = createAsyncThunk<
  participantType[],
  any,
  { rejectValue: string }
>("setting/getPageParticipant", async function (
  { child },
  { rejectWithValue }
) {
  try {
    const jsonS = {
      child,
    };
    const response: any = await api.post(
      Apiq + "api/auth/GetPageChampionshipParticipant",
      jsonS
    );
    // dispatch(addsetka(response.data));
    return (await response.data.data) as participantType[];
  } catch (error) {
    return rejectWithValue("");
  }
});

// const setError = (state, action) => {
//     state.status = 'rejected';
//     state.error = action.payload;
// }
export type stateSettingType = {
  participant: participantType[];
  championshipCategory: createKTGType[];
  championshipSubCategory: subCategoryType[];
  champCreateStatus?: regStatusType;
  myCreateChumpId?: any;
  championships: createChampType[];
  loading?: any;
  error?: any;
};

const initialState: stateSettingType = {
  participant: [],
  championshipCategory: [],
  championshipSubCategory: [],
  champCreateStatus: {
    status: false,
  },
  championships: [],
  myCreateChumpId: null,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    // createChamp(state, action) {
    //     state.champCreateStatus = action.payload
    // },
    // // getAllChamp(state, action) {
    // //     state.championships = action.payload
    // // },
    // getAllKG(state, action) {
    //     state.kg = action.payload
    // },
    // getAllParticipant(state, action) {
    //     state.participant = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChampionships.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getChampionships.fulfilled, (state, action) => {
        state.championships = action.payload;
      })
      .addCase(DeleteChampionships.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteChampionships.fulfilled, (state, action) => {
        state.championships = state.championships.filter(
          (t: any) => t.id !== action.payload?.id
        );
      })
      .addCase(getPageParticipant.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPageParticipant.fulfilled, (state, action) => {
        let d: any[] = state.participant;
        let g: any[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: any[] = d.filter((item: any) => item?.id === g[i].id);
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.participant = d;
      })
      .addCase(getParticipant.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getParticipant.fulfilled, (state, action) => {
        state.participant = action.payload;
      })
      .addCase(AddParticipant.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddParticipant.fulfilled, (state, action) => {
        let d: participantType[] = state.participant;

        let fD: participantType[] = d.filter(
          (item: participantType) => item?.id === action.payload.id
        );
        if (fD.length === 0) {
          d = [...d, action.payload];
        }

        state.participant = d;
      })
      .addCase(DeleteParticipant.fulfilled, (state, action) => {
        state.participant = state.participant.filter(
          (t: any) => t.id !== action.payload?.id
        );
      })
      .addCase(createCHampSlice.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCHampSlice.fulfilled, (state, action) => {
        console.log({ ch: action.payload });
        state.championships.push(action.payload);
        console.log(state.championships);
        state.champCreateStatus = { status: true };
        state.myCreateChumpId = action.payload?.id;
      })
      .addCase(closeChamp.fulfilled, (state, action) => {
        state.champCreateStatus = { status: false };
      })
      .addCase(AddCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddCategory.fulfilled, (state, action) => {
        state.championshipCategory.push(action.payload);
      })
      .addCase(UpdateCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateCategory.fulfilled, (state, action) => {
        const updateC =
          state.championshipCategory.find(
            (todo) => todo.id === action.payload.id
          ) || {};
        updateC!.name = action.payload.name;
      })
      .addCase(DeleteCategory.fulfilled, (state, action) => {
        state.championshipCategory = state.championshipCategory.filter(
          (t: createKTGType) => t.id !== action.payload?.id
        );
        state.championshipSubCategory = state.championshipSubCategory.filter(
          (t: subCategoryType) =>
            t.championship_category_id !== action.payload?.id
        );
      })
      .addCase(getCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        let d: createKTGType[] = state.championshipCategory;
        let g: createKTGType[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: createKTGType[] = d.filter(
            (item: createKTGType) => item?.id === g[i].id
          );
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.championshipCategory = d;
      })
      .addCase(AddSubCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddSubCategory.fulfilled, (state, action) => {
        state.championshipSubCategory.push(action.payload);
      })
      .addCase(UpdateSubCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateSubCategory.fulfilled, (state, action) => {
        const updateC =
          state.championshipSubCategory.find(
            (todo) => todo.id === action.payload.id
          ) || {};
        updateC!.name = action.payload.name;
      })
      .addCase(DeleteSubCategory.fulfilled, (state, action) => {
        state.championshipSubCategory = state.championshipSubCategory.filter(
          (t: any) => t.id !== action.payload?.id
        );
      })
      .addCase(getSubCategory.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        let d: createKTGType[] = state.championshipSubCategory;
        let g: createKTGType[] = action.payload;

        for (let i = 0; i < g.length; i++) {
          let fD: createKTGType[] = d.filter(
            (item: createKTGType) => item?.id === g[i].id
          );
          if (fD.length === 0) {
            d = [...d, g[i]];
          }
        }
        state.championshipSubCategory = d;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// const { createChamp, getAllChamp, getAllKG, getAllParticipant } = settingSlice.actions;

export default settingSlice.reducer;
const isError = (action: AnyAction) => {
  return action.type.endsWith("rejectad");
};
