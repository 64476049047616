import { Link } from "react-router-dom";
import { CheckboxLeftRight } from "./checkboxLeftRight";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Users, settingUser } from "../../../store/loginSlice";

const schema = Yup.object().shape({
  position: Yup.string(),
  association: Yup.string().required("Выберите вид ассоциацию"),
  country: Yup.string().required("Выберите страну"),
  region: Yup.string().required("Выберите регион"),
  city: Yup.string().required("Выберите город"),
});

export const SettingProfile: FC<any> = () => {
  const userg = useAppSelector((state: any) => state.login.user);
  const users = useAppSelector((state: any) => state.login.log);
  const Associations = useAppSelector((state: any) => state.login.Association);

  let findUser: Users =
    users.find((item: Users) => item?.id === userg?.id) || {};
  let dataUserCopy: Users = {};
  const [User, setUser] = useState(dataUserCopy);

  useEffect(() => {
    setUser({
      ...dataUserCopy,
      ...userg,
      ...{ association: findUser?.association },
      ...{ country: findUser?.country },
      ...{ region: findUser?.region },
      ...{ city: findUser?.city },
    });
  }, [userg]);

  const [touchedFields, setTouchedFields] = useState({
    association: false,
    country: false,
    region: false,
    city: false,
  });
  const handleFieldChange = (fieldName: string) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [fieldName]: true,
    }));
  };

  const updateLogin = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
    let data: Users = { ...User };
    if (name === "position") {
      data.position = parseInt(e.target.value);
      setUser(data);
    }
    if (name === "association") {
      data.association = parseInt(e.target.value);
      setUser(data);
    }
    if (name === "country") {
      data.country = parseInt(e.target.value);
      data.region = 0;
      data.city = 0;
      setRegion(0);
      // setCity(0)
      setCountry(parseInt(e.target.value));
      setUser(data);
    }
    if (name === "region") {
      data.region = parseInt(e.target.value);
      data.city = 0;
      setRegion(parseInt(e.target.value));
      setUser(data);
    }
    if (name === "city") {
      data.city = parseInt(e.target.value);
      setUser(data);
    }
  };

  const dispatch = useAppDispatch();

  const removeProfile = () => {
    let country,
      region,
      city,
      association = User?.association,
      position = User.position;

    if (User.country !== userg.country) {
      country = User.country;
    }
    if (User.region !== userg.region) {
      region = User.region;
    }
    if (User.city !== userg.city) {
      city = User.city;
    }

    if (position !== 3) {
      dispatch(
        settingUser({
          position,
          association: null,
          country: null,
          region: null,
          city: null,
        })
      );
    } else {
      dispatch(settingUser({ position, association, country, region, city }));
    }
  };

  let dateParse = Date.parse(userg?.birthday);
  let date = new Date(dateParse);
  let newDate = new Date();

  let getYear: number = Number(date.getFullYear());
  let NewGetYear: number = Number(newDate.getFullYear());

  let yearMinus17 = NewGetYear - 17;

  const CountryStor = useAppSelector((state: any) => state.login.country);
  const CityStor = useAppSelector((state: any) => state.login.city);
  const RegionStor = useAppSelector((state: any) => state.login.region);

  const [country, setCountry] = useState(findUser?.country);
  // const [city, setCity] = useState(0);
  const [region, setRegion] = useState(findUser?.region);
  useEffect(() => {
    setCountry(findUser?.country);
    setRegion(findUser?.region);
  }, [findUser]);
  let regionFilter = [];
  let cityFilter = [];
  regionFilter = RegionStor.filter((item: any) => item.country === country);
  cityFilter = CityStor.filter((item: any) => item.region === region);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    // reset,
    // control
  } = useForm({
    mode: "onBlur",
  });
  console.log(User?.association);
  return (
    <div className="flex">
      <div className="Form">
        <form onSubmit={handleSubmit(removeProfile)}>
          <h1 className="red">Настройка</h1>
          <div className="selectContainer">
            {/* <Link to="ass">Учебная ассоциация</Link> */}
            <label>
              <div className="typeAssoc">Тип</div>
              <select
                className="settingSel"
                id="registerInput"
                value={User?.position}
                {...register("position", {
                  onChange: (e) => {
                    updateLogin(e, "position");
                  },
                })}
              >
                <option value="0">не выбрано</option>
                <option value="1">Ученик(-ца)</option>
                {yearMinus17 >= getYear && <option value="2">Учитель</option>}
                {yearMinus17 > getYear && <option value="3">Ассоциация</option>}
              </select>
            </label>
            {(errors as any)?.position && (
              <div className="regErr">
                <p>{(errors as any)?.position?.message || "Error!"}</p>
              </div>
            )}
            {userg?.fields === "position" && (
              <div className="regErr">
                <p>{userg?.message || "Error!"}</p>
              </div>
            )}

            <label>
              {User?.position === 3 && (
                <select
                  className="s settingSel"
                  id="registerInput"
                  value={User?.association}
                  {...register("association", {
                    required:
                      touchedFields.association === true ||
                      User?.association === null
                        ? "Выберите вид ассоциацию"
                        : false, // Изменяем сообщение об ошибке в зависимости от touched
                    onChange: (e) => {
                      updateLogin(e, "association");
                      handleFieldChange("association");
                    },
                  })}
                >
                  <option value="">вид (не выбрано)</option>
                  {Associations.map((o: any) => (
                    <option value={o?.id}>{o?.name}</option>
                  ))}
                </select>
              )}
              {errors.association && (
                <span className="regErr">
                  {(errors as any).association.message}
                </span>
              )}
            </label>

            <label>
              {User?.position === 3 && (
                <select
                  className="s settingSel"
                  id="registerInput"
                  value={User?.country}
                  {...register("country", {
                    required: touchedFields.country ? "Выберите страну" : false, // Изменяем сообщение об ошибке в зависимости от touched
                    onChange: (e) => {
                      updateLogin(e, "country");
                      handleFieldChange("country");
                    },
                  })}
                >
                  <option value="">Страна</option>
                  {CountryStor.map((o: any) => (
                    <option value={o?.id}>{o?.name}</option>
                  ))}
                </select>
              )}
              {errors.country && (
                <span className="regErr">
                  {(errors as any).country.message}
                </span>
              )}
            </label>

            {User?.position === 3 && (
              <select
                className="s settingSel"
                id="registerInput"
                value={User?.region}
                {...register("region", {
                  required: "Выберите регион",
                  onChange: (e) => {
                    updateLogin(e, "region");
                    handleFieldChange("region");
                  },
                })}
              >
                <option value="">Город</option>
                {regionFilter.map((or: any) => (
                  <option value={or?.id}>{or?.name}</option>
                ))}
              </select>
            )}
            {errors.region && (
              <span className="regErr">{(errors as any).region.message}</span>
            )}

            {User?.position === 3 && (
              <select
                className="s settingSel"
                id="registerInput"
                value={User?.city}
                {...register("city", {
                  required: "Выберите город",
                  onChange: (e) => {
                    updateLogin(e, "city");
                    handleFieldChange("city");
                  },
                })}
              >
                <option value="">Село</option>
                {cityFilter.map((oc: any) => (
                  <option value={oc?.id}>{oc?.name}</option>
                ))}
              </select>
            )}
            {errors.city && (
              <span className="regErr">{(errors as any).city.message}</span>
            )}
            {userg?.error && (
              <div className="JustefayCenter">
                <div className="red">{userg?.error}</div>
              </div>
            )}

            <input
              type="submit"
              id={isValid ? "submitIsValid" : "submitReg"}
              disabled={!isValid}
              value="Сохранить"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
