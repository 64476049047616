import { useState, useEffect } from "react";
import "./App.css";

import Header from "./components/header/header";
import {
  addNewChild,
  signInSlice,
  axiosUser,
  CountrySlise,
  CitySlise,
  RegionSlise,
  AddAva,
  recoverPass,
  hashPassword,
  nullEmailRecover,
  out,
  registerOpenSlise,
  allLogin,
  meSlice,
  getAssociation,
} from "./store/loginSlice";
import { Navigate } from "react-router-dom";
import { getChampionships } from "./store/settingSlice";
import { useAppDispatch, useAppSelector } from "./store/hook";
import { Users } from "./store/loginSlice";
import { getAdmin } from "./store/setkaSlice";
import { getSubscribe } from "./store/subscribeSlice";

export function App() {
  const [massenger, setMassenger] = useState("");
  const [userpage, setUserpage] = useState("");
  const [ava, setAva] = useState();
  const { loading, error } = useAppSelector((state: any) => state.login);
  const dispatch = useAppDispatch();
  const logros = useAppSelector((state: any) => state.login.resError);
  const user = useAppSelector((state: any) => state.login.user);
  const Token = useAppSelector((state: any) => state.login.Token);
  const subscribe = useAppSelector((state) => state.subscribe.subscribe);
  const [newName, setNewName] = useState("");
  let userId = user?.id;

  // const Sp = useAppSelector(state => state.namys.Sportsmens);
  //   const clickNewSportsmen =()=>{
  //     dispatch(registerName({newName, userId}))
  //     setNewName('')
  // }
  const updateNewName = (e: any) => {
    setNewName(e);
  };

  const [BS, setBS] = useState(false);

  useEffect(() => {
    if (BS === false) {
      dispatch(allLogin());
      dispatch(meSlice());
      dispatch(getSubscribe());
      dispatch(axiosUser());
      dispatch(getAssociation());
      dispatch(getAdmin());
      dispatch(getChampionships());
      dispatch(RegionSlise());
      dispatch(CountrySlise());
      dispatch(CitySlise());
      setBS(true);
    } else {
      if (subscribe.length === 0 || user.length === 0) {
        dispatch(allLogin());
        dispatch(meSlice());
        dispatch(getSubscribe());
        dispatch(getChampionships());
        dispatch(RegionSlise());
        dispatch(CountrySlise());
        dispatch(CitySlise());
        dispatch(axiosUser());
        dispatch(getAssociation());
        dispatch(getAdmin());
        setBS(true);
      }
    }
  }, [Token]);

  function uploader(e: any) {
    if (e.target.files[0]) {
      setAva(e.target.files[0]);
    }
  }

  const registerOpen = () => {
    dispatch(registerOpenSlise());
  };
  const outLog = () => {
    dispatch(out());
  };
  useEffect(() => {
    if (ava) {
      let id = user.id;
      dispatch(AddAva({ id, ava }));
    }
  }, [ava]);

  const nullEmail = () => {
    dispatch(nullEmailRecover());
  };
  const hashPass = (data: any) => {
    let hash_pass = data.hash;
    dispatch(hashPassword({ hash_pass }));
  };

  const recover = (data: Users) => {
    let email = data.email;
    dispatch(recoverPass({ email }));
  };

  const signIn = (data: Users) => {
    let login = data.login,
      password = data.password;
    dispatch(signInSlice({ login, password }));
  };
  // const removeProfile = (data) => {
  //   let firstName = data.firstName,
  //       lastName = data.lastName,
  //       coach = data.coach,
  //       patronymic = data.patronymic,
  //       login = data.login,
  //       tel = data.tel,
  //       email	 = data.email;
  //       // thisCoach = data.thisCoach,
  //       // sportsName = data.sportsName,
  //       // day = data.day,
  //       // month = data.month,
  //       // year = data.year,
  //       // kg = data.kg;

  //        dispatch(addNewChild({firstName, lastName, coach, patronymic, login, tel, email}));
  //    }
  //    if(login.trim().length) {
  //     dispatch(addNewTodo(text));
  //   }

  const signUp = (data: Users) => {
    let name = data.name,
      login = data.login,
      tel = data.tel,
      email = data.email,
      birthday = data.birthday,
      password = data.password;
    dispatch(addNewChild({ name, login, tel, email, password, birthday }));
    if (logros?.status) {
      return <Navigate to={"/signin"} />;
    }
  };

  return (
    <div className="App">
      <Header
        signIn={signIn}
        signUp={signUp}
        uploader={uploader}
        pageId={setUserpage}
        newName={newName}
        updateNewName={updateNewName}
        userId={userId}
        valueMass={massenger}
        updateMass={setMassenger}
        recover={recover}
        hashPass={hashPass}
        nullEmail={nullEmail}
        outLog={outLog}
        registerOpen={registerOpen}
        subscribe={subscribe}
        // getMassenger={getMassenger}
      />
    </div>
  );
}
