import { FC, useEffect, useRef, useState } from "react";
import "./profile.css";
import { ProfileUp } from "./profileUp";
import { useAppDispatch } from "../../../store/hook";
import { getProfile } from "../../../store/loginSlice";

const Profile: FC<any> = ({
  uploader,
  userLogin,
  prof,
  userID,
  profmap,
  userThisCoach,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (prof?.status === null) {
      let id: any = prof?.id;
      dispatch(getProfile({ id }));
    }
  }, []);

  return (
    <div className="flex">
      <div className="Form">
        <form className="profile">
          <ProfileUp
            {...prof}
            pageData={prof?.id}
            subscribed={userID}
            userLogin={userLogin}
            uploader={uploader}
            userThisCoach={userThisCoach}
          />
        </form>
      </div>
    </div>
  );
};

export default Profile;

// const [result, setResult] = useState("");
// const [err, setErr] = useState("");
// const [name, setName] = useState("");

// function uploader(e) {
//   if(!e.target.files[0].name.includes(".HEIC")){
//     const imageFile = e.target.files[0];
//     const reader = new FileReader();
//     reader.addEventListener("load", (e) => {
//       setResult(e.target.result);
//     });

//     reader.readAsDataURL(imageFile);
//   } else{
//     let Error = "Этот файл не поддерживается",
//     NameFile = "Не удалось загрузить файл " + e.target.files[0].name;
//     setName(NameFile);
//     setErr(Error);
//   }

// const [result, setResult] = useState("");

// function uploader(e) {
//   if(e.target.files[0]){
//     const imageFile = e.target.files[0];
//     const reader = new FileReader();
//     reader.addEventListener("load", (e) => {
//       setResult(e.target.result);
//     });
//     reader.readAsDataURL(imageFile);
//   }
// }
