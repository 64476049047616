import { FC, useEffect, useState } from "react";
import "./style.css";
import { RemoveGrade } from "./components/remove";
import { FaMedal } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../../../../store/hook";
import {
  GradeType,
  classADDType,
  getChildClass,
  getClass,
  getGrade,
  getSubject,
  pageOpen,
  pageOpenChildClass,
  pageOpenGrade,
} from "../../../../store/gradeSlice";
import {
  getChildren,
  getSubsChild,
  subsCoachType,
} from "../../../../store/childrenSlice";
import { Users, getProfileId } from "../../../../store/loginSlice";
import { subscribeType } from "../../../../store/subscribeSlice";
import { ShowAllGrade } from "./components/showAllGrade/showAllGrade";
import { MyGrade } from "./components/myGrade/MyGrade";

type GradeJSXType = {
  arrChildCoachAssoc_id: any[];
  MyCoachForChild: any;
  pageData: string;
  Userter: Users[];
  User: Users;
  subsCh: number[];
  subscribed: any;
  subsAllAssoc: subscribeType[];
  p: number[];
  position: number;
  association_id: any;
  arrAssoc_id: any[];
  NewMonth?: any;
  NewYear?: any;
};

export const Grade: FC<GradeJSXType> = ({
  NewMonth,
  NewYear,
  arrChildCoachAssoc_id,
  MyCoachForChild,
  pageData,
  Userter,
  User,
  subsCh,
  subscribed,
  subsAllAssoc,
  p,
  position,
  association_id,
  arrAssoc_id,
}) => {
  const dispatch = useAppDispatch();
  let clas: any = useAppSelector((state: any) => state.grade.class);
  const subj = useAppSelector((state: any) => state.grade.subject);

  let cl: classADDType[] = [];
  let sj: classADDType[] = [];
  const [ass, setAss] = useState(0);
  const [coach, setCoach] = useState(0);
  const functionAssAndCoach = (status: any, a: any) => {
    status === "assoc" && setAss(a);
    status === "coach" && setCoach(a);
  };
  useEffect(() => {
    position === 3 &&
      setCoach(
        subsAllAssoc[0]?.following_id ? subsAllAssoc[0]?.following_id : 0
      );
    position === 1 &&
      setCoach(
        MyCoachForChild[0]?.follower_id ? MyCoachForChild[0]?.follower_id : 0
      );
  }, [subsAllAssoc, MyCoachForChild]);

  useEffect(() => {
    position === 3 && setAss(arrAssoc_id[0] ? arrAssoc_id[0] : 0);
    position === 2 && setAss(arrAssoc_id[0] ? arrAssoc_id[0] : 0);
    position === 1 &&
      setAss(arrChildCoachAssoc_id[0] ? arrChildCoachAssoc_id[0] : 0);
  }, [subsAllAssoc, MyCoachForChild]);
  if (position === 3) {
    cl = clas.filter((item: classADDType) => item.assoc_id === association_id);
    sj = subj.filter((item: classADDType) => item.assoc_id === association_id);
  }
  if (position !== 3) {
    cl = clas.filter((item: classADDType) => item.assoc_id === ass);
    sj = subj.filter((item: classADDType) => item.assoc_id === ass);
  }

  let arrChildOrCoach_Assoc: string[] = [];
  if (position === 2) {
    arrChildOrCoach_Assoc = arrAssoc_id;
  }
  if (position === 1) {
    arrChildOrCoach_Assoc = arrChildCoachAssoc_id;
  }

  const getClassSubjectPage = (assoc_id: any) => {
    dispatch(getClass({ assoc_id }));
    dispatch(getSubject({ assoc_id }));
    dispatch(pageOpen({ assoc_id }));
  };

  const StatepageOpen = useAppSelector(
    (state: any) => state.grade.stateOpenPage
  );
  useEffect(() => {
    let page = StatepageOpen.filter((i: any) => i === association_id);
    if (page.length === 0 && position === 3) {
      getClassSubjectPage(association_id);
    }
  }, []);
  useEffect(() => {
    for (let iAss = 0; iAss < arrChildOrCoach_Assoc.length; iAss++) {
      let page = StatepageOpen.filter(
        (i: string) => i === arrChildOrCoach_Assoc[iAss]
      );
      if (page.length === 0 && position !== 3) {
        getClassSubjectPage(arrChildOrCoach_Assoc[iAss]);
      }
    }
  }, []);

  let id: any = pageData;
  // let getSubsCh = useAppSelector(state => state.children.getSubsChildState);
  // let y: any = getSubsCh.filter((item: any) => item === id)
  // if (y.length === 0) {
  //     dispatch(getChildren({ id }))
  //     dispatch(getSubsChild({ id }))
  // }

  let getChildClasss = useAppSelector(
    (state: any) => state.grade.stateGetChildClassPage
  );
  let q: any = getChildClasss.filter((item: any) => item === id);
  if (q.length === 0) {
    dispatch(getChildClass({ id }));
    dispatch(pageOpenChildClass({ id }));
  }

  const functionGetGrade = (id: any) => {
    dispatch(getGrade({ id }));
    dispatch(pageOpenGrade({ id }));
  };
  let statePageGrade = useAppSelector(
    (state: any) => state.grade.statePageGrade
  );
  let fchild: any[] = statePageGrade.filter((item: any) => item === pageData);
  useEffect(() => {
    if (
      fchild.length === 0 &&
      (position === 1 || position === 2 || position === 3)
    ) {
      functionGetGrade(pageData);
    }
  }, [statePageGrade, pageData]);

  const gradeAll: GradeType[] = useAppSelector(
    (state: any) => state.grade.grade
  );
  let grade: GradeType[] = [];
  if (position === 2) {
    grade = gradeAll.filter(
      (item: any) => item?.assoc === ass && item?.coach === pageData
    );
  }
  if (position === 3) {
    grade = gradeAll.filter(
      (item: any) => item?.assoc === pageData && item?.coach === coach
    );
  }
  if (position === 1) {
    grade = gradeAll.filter(
      (item: any) =>
        item?.assoc === ass && item?.coach === coach && item?.child === pageData
    );
  }

  const [openGradeState, setOpenGradeState] = useState(false);
  const [openGradesState, setOpenGradesState] = useState(true);
  const [openMyGradesState, setOpenMyGradesState] = useState(false);

  useEffect(() => {
    if (position === 1) {
      setOpenGradeState(false);
      setOpenGradesState(false);
      setOpenMyGradesState(true);
    }
  }, []);

  const openGrade = () => {
    setOpenGradeState(true);
    setOpenGradesState(false);
    setOpenMyGradesState(false);
  };
  const openGrades = () => {
    setOpenGradeState(false);
    setOpenGradesState(true);
    setOpenMyGradesState(false);
  };
  const openMyGrades = () => {
    setOpenGradeState(false);
    setOpenGradesState(false);
    setOpenMyGradesState(true);
  };

  let year = ["2023", "2024"];
  let month = [
    { id: "0", title: "Январь" },
    { id: "1", title: "Февраль" },
    { id: "2", title: "Март" },
    { id: "3", title: "Апрель" },
    { id: "4", title: "Май" },
    { id: "5", title: "Июнь" },
    { id: "6", title: "Июль" },
    { id: "7", title: "Август" },
    { id: "8", title: "Сентябрь" },
    { id: "9", title: "Октябрь" },
    { id: "10", title: "Ноябрь" },
    { id: "11", title: "Декабрь" },
  ];

  return (
    <div className="grade">
      <ul className="coachUl">
        {User?.id === pageData && position === 2 && (
          <li
            className={openGradeState ? "onHeaderLi" : ""}
            onClick={openGrade}
          >
            Оцентка на сегодня
          </li>
        )}
        {(position === 3 || position === 2) && (
          <li
            className={openGradesState ? "onHeaderLi" : ""}
            onClick={openGrades}
          >
            Оцентки
          </li>
        )}
        {position === 1 && (
          <li
            className={openMyGradesState ? "onHeaderLi" : ""}
            onClick={openMyGrades}
          >
            Оцентки
          </li>
        )}
      </ul>
      {openGradeState && User?.id === pageData && position === 2 && (
        <RemoveGrade
          functionAssAndCoach={functionAssAndCoach}
          position={position}
          ass={ass}
          arrAssoc_id={arrAssoc_id}
          grade={grade}
          cl={cl}
          sj={sj}
          p={p}
          MyChildFilter={subsAllAssoc}
          association_id={association_id}
          pageData={pageData}
          subsCh={subsCh}
          Userter={Userter}
          User={User}
          subscribed={subscribed}
        />
      )}
      {openGradesState && (
        <ShowAllGrade
          year={year}
          month={month}
          NewMonth={NewMonth}
          NewYear={NewYear}
          MyChildFilter={subsAllAssoc}
          functionAssAndCoach={functionAssAndCoach}
          coach={coach}
          position={position}
          ass={ass}
          arrAssoc_id={arrAssoc_id}
          grade={grade}
          cl={cl}
          sj={sj}
          User={User}
          Userter={Userter}
          pageData={pageData}
        />
      )}
      {position !== 3 && openMyGradesState && (
        <MyGrade
          year={year}
          month={month}
          NewMonth={NewMonth}
          NewYear={NewYear}
          coach={coach}
          MyCoachForChild={MyCoachForChild}
          position={position}
          functionAssAndCoach={functionAssAndCoach}
          ass={ass}
          arrChildOrCoach_Assoc={arrChildOrCoach_Assoc}
          grade={grade}
          cl={cl}
          sj={sj}
          User={User}
          Userter={Userter}
          pageData={pageData}
        />
      )}
    </div>
  );
};
