import { MapAllChamp } from "./mapAllChamp";
import { Link } from "react-router-dom";
import { FC, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../store";
import { createChampType, getChampionships } from "../../../store/settingSlice";
import { Users } from "../../../store/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { subscribeType } from "../../../store/subscribeSlice";
import { BsFilterSquare } from "react-icons/bs";
import "./style.css";
import { SelectChampionship } from "./selectChampionship/page";

type WorldChampionshipsType = {};

export const WorldChampionships: FC<WorldChampionshipsType> = ({}) => {
  const champ = useAppSelector((state) => state.setting.championships);
  const login = useAppSelector((state) => state.login.log);
  console.log({ champ });
  const TypeStor = useAppSelector((state) => state.login.Association);
  const CountryStor = useAppSelector((state) => state.login.country);
  const RegionStor = useAppSelector((state) => state.login.region);
  const CityStor = useAppSelector((state) => state.login.city);

  const [type, setType] = useState(1);
  const [typeName, setTypeName] = useState("Taekwondo WT");

  const updateType = (e: number, name: string) => {
    setType(e);
    setTypeName(name);
  };

  const [country, setCountry] = useState(1);
  const [region, setRegion] = useState(0);
  const [city, setCity] = useState(0);

  const [countryName, setCountryName] = useState("Kazakhstan");
  const [regionName, setRegionName] = useState("");
  const [cityName, setCityName] = useState("");

  let { regionFilter, cityFilter } = useMemo(() => {
    const f = RegionStor.filter((item) => item.country === country);
    const g = CityStor.filter((item) => item.region === region);

    return { regionFilter: f, cityFilter: g };
  }, [RegionStor, CityStor, country, region]);

  const updateCountry = (e: number, name: string) => {
    setRegion(0);
    setCity(0);
    setCountry(e);
    setCountryName(name);
    setRegionName("");
    setCityName("");
  };

  const updateRegion = (e: number, name: string) => {
    setCity(0);
    setRegion(e);
    setRegionName(name);
    setCityName("");
  };

  const updateCity = (e: number, name: string) => {
    setCity(e);
    setCityName(name);
  };

  const [shadow, setShadow] = useState(false);
  const [filterChamp, setFilterChamp] = useState("Все");
  const [typeShadow, setTypeShadow] = useState(false);
  const [filterChShadow, setFilterChShadow] = useState(false);
  const [countryChampShadow, setCountryChampShadow] = useState(false);
  const [regionChampShadow, setRegionChampShadow] = useState(false);
  const [cityChampShadow, setCityChampShadow] = useState(false);

  const filterChampClick = () => {
    setShadow(true);
    setFilterChShadow(true);
    setCountryChampShadow(false);
    setRegionChampShadow(false);
    setCityChampShadow(false);
    setTypeShadow(false);
  };

  const typeChampClick = () => {
    setShadow(true);
    setFilterChShadow(false);
    setCountryChampShadow(false);
    setRegionChampShadow(false);
    setCityChampShadow(false);
    setTypeShadow(true);
  };

  const countryChampClick = () => {
    setShadow(true);
    setFilterChShadow(false);
    setCountryChampShadow(true);
    setRegionChampShadow(false);
    setCityChampShadow(false);
    setTypeShadow(false);
  };

  const regionChampClick = () => {
    setShadow(true);
    setCountryChampShadow(false);
    setFilterChShadow(false);
    setRegionChampShadow(true);
    setCityChampShadow(false);
    setTypeShadow(false);
  };

  const cityChampClick = () => {
    setShadow(true);
    setCountryChampShadow(false);
    setFilterChShadow(false);
    setRegionChampShadow(false);
    setCityChampShadow(true);
    setTypeShadow(false);
  };

  const shadowOf = () => {
    setShadow(false);
    setFilterChShadow(false);
    setCountryChampShadow(false);
    setRegionChampShadow(false);
    setCityChampShadow(false);
    setTypeShadow(false);
  };
  const dateString = "2025-02-08"; // Дата из базы (без времени)
  const userTimezoneOffset = new Date().getTimezoneOffset(); // Смещение в минутах (от UTC)

  // Создаём объект Date в UTC
  const utcDate = new Date(`${dateString}T00:00:00Z`);

  // Применяем смещение пользователя
  const timestampWithUserTimezone =
    utcDate.getTime() + userTimezoneOffset * 60 * 1000;

  console.log({ timestampWithUserTimezone });

  const timestamp = Number(new Date());
  const date = new Date(timestampWithUserTimezone);

  const formattedDate = date.toLocaleString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  let champReverse = useMemo(() => {
    let champs = [...(champ as createChampType[])];
    champs.reverse();

    return champs;
  }, [champ]);

  let filterChampionships = useMemo(() => {
    const newDate = Number(new Date());
    let f = champReverse.filter(
      (item) =>
        (type !== 0 ? item?.association === type : true) &&
        (country !== 0 ? item?.country === country : true) &&
        (region !== 0 ? item?.region === region : true) &&
        (city !== 0 ? item?.city === city : true) &&
        (filterChamp === "Актуальные"
          ? Date.parse(item?.close_tournament) + 86400000 >= newDate
          : true) &&
        (filterChamp === "Прошедшие"
          ? Date.parse(item?.close_tournament) + 86400000 <= newDate
          : true)
    );

    return f;
  }, [champReverse, type, filterChamp, country, region, city]);

  return (
    <div className="RRR">
      {/* <div className="selectTrophy">
                <select onChange={(e) => updateCountry(parseInt(e.target.value))} className="s" name="" id="">
                    <option value="">Страна</option>
                    {CountryStor.map((o) => (
                        <option value={o?.id}>{o?.name}</option>
                    ))}
                </select>
                <select onChange={(e) => updateRegion(parseInt(e.target.value))} className="s" name="" id="">
                    <option value="">Город</option>
                    {regionFilter.map((or) => (
                        <option value={or?.id}>{or?.name}</option>
                    ))}
                </select>
                <select onChange={(e) => updateCity(parseInt(e.target.value))} className="s" name="" id="">
                    <option value="">Село</option>
                    {cityFilter.map((oc) => (
                        <option value={oc?.id}>{oc?.name}</option>
                    ))}
                </select>
            </div> */}
      {shadow ? (
        <div onClick={shadowOf} className="settingAbsolut"></div>
      ) : null}
      {shadow && filterChShadow ? (
        <div className="SettingFon">
          <div className="AllSubs">
            <h3 className="trOptions">Фильтрация</h3>
            <div
              className="removeChildrens"
              id={filterChamp === "Все" ? "ChBlue" : ""}
            >
              <div
                className="selectCoachAndAss"
                onClick={(e) => setFilterChamp("Все")}
              >
                Все
              </div>
            </div>
            <div
              className="removeChildrens"
              onClick={(e) => setFilterChamp("Актуальные")}
              id={filterChamp === "Актуальные" ? "ChBlue" : ""}
            >
              <div className="selectCoachAndAss">Актуальные</div>
            </div>
            <div
              className="removeChildrens"
              onClick={(e) => setFilterChamp("Прошедшие")}
              id={filterChamp === "Прошедшие" ? "ChBlue" : ""}
            >
              <div className="selectCoachAndAss">Прошедшие</div>
            </div>
          </div>
        </div>
      ) : null}
      {shadow && typeShadow ? (
        <SelectChampionship
          Stor={TypeStor}
          updateAdress={updateType}
          adressID={type}
          selectName={"Вид"}
        />
      ) : null}
      {shadow && countryChampShadow ? (
        <SelectChampionship
          Stor={CountryStor}
          updateAdress={updateCountry}
          adressID={country}
          selectName={"Страна"}
        />
      ) : null}
      {shadow && regionChampShadow ? (
        <SelectChampionship
          Stor={regionFilter}
          updateAdress={updateRegion}
          adressID={region}
          selectName={"Регион"}
        />
      ) : null}
      {shadow && cityChampShadow ? (
        <SelectChampionship
          Stor={cityFilter}
          updateAdress={updateCity}
          adressID={city}
          selectName={"Город"}
        />
      ) : null}
      <main className="nameIcon">Мероприятия</main>
      <div className="selectGrade">
        <div className="classSubject" onClick={filterChampClick}>
          <h3>{filterChamp}</h3>
        </div>
        <div className="classSubject" onClick={typeChampClick}>
          <h3>{typeName ? typeName : "Вид"}</h3>
        </div>
        <div className="classSubject" onClick={countryChampClick}>
          <h3>{countryName ? countryName : "Страна"}</h3>
        </div>
        <div className="classSubject" onClick={regionChampClick}>
          <h3>{regionName ? regionName : "Регион"}</h3>
        </div>
        <div className="classSubject" onClick={cityChampClick}>
          <h3>{cityName ? cityName : "Город"}</h3>
        </div>
      </div>
      {filterChampionships.map((ch) => (
        <Link className="linkChamp" to={"/championship/" + ch.id}>
          <MapAllChamp ch={ch} login={login} />
        </Link>
      ))}
    </div>
  );
};
