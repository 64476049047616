import { FC, useMemo } from "react";
import "./style.css";
import { AdmissionAdminsType, SType } from "../../../../../store/setkaSlice";
import { participantType } from "../../../../../store/settingSlice";
import { Users } from "../../../../../store/loginSlice";
import { ParticipantMap } from "./ParticipantMap/page";

type MyParticipantType = {
  participant: participantType[];
  Users: Users[];
  user: Users;
  championship_id: number;
  AdmissionAdmin: AdmissionAdminsType[];
};

export const MyParticipant: FC<MyParticipantType> = ({
  Users,
  participant,
  user,
  championship_id,
  AdmissionAdmin,
}) => {
  let MyPart = useMemo(() => {
    let f = participant.filter(
      (item) =>
        item?.coach === user?.id && item?.championship_id === championship_id
    );

    return f;
  }, [participant, user, championship_id]);

  return (
    <div className="ChildParticipant">
      {MyPart.map((p) => (
        <ParticipantMap
          participant={p}
          Users={Users}
          AdmissionAdmin={AdmissionAdmin}
          championship_id={championship_id}
        />
      ))}
    </div>
  );
};
