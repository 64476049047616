import { FC, useEffect, useMemo, useRef, useState } from "react";
import "./style.css";
import {
  LType,
  createChampType,
  createKTGType,
  participantType,
  subCategoryType,
} from "../../../../../../store/settingSlice";
import {
  AdminType,
  CancelFight,
  DayChampionshipType,
  SType,
  cordCategoryType,
  cordChampionshipType,
  currentFight,
  updateLoser,
  updateWinner,
} from "../../../../../../store/setkaSlice";
import {
  Users,
  city,
  country,
  region,
} from "../../../../../../store/loginSlice";
import { url } from "../../../../../../store/url";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hook";
import { ImCheckmark, ImCross } from "react-icons/im";
import { LuSword } from "react-icons/lu";
import { ChampTimeF } from "../ChampTimeF";

type MatchChampionshipsType = {
  match: LType;
  login: Users[];
  user: Users;
  championship: createChampType;
  fAdmin: AdminType[];
  clickCordId: number;
  Category: createKTGType[];
  SubCategory: subCategoryType[];
  fCords: cordChampionshipType[];
  h: number;
  clickDayId: number;
};

let Api = url();

export const MatchChampionships: FC<MatchChampionshipsType> = ({
  match,
  login,
  user,
  championship,
  fAdmin,
  clickCordId,
  Category,
  SubCategory,
  fCords,
  h,
  clickDayId,
}) => {
  let User1: Users =
    login.find((item: Users) => item?.id === match?.child1) || {};

  let User2: Users =
    login.find((item: Users) => item?.id === match?.child2) || {};

  let st = match?.start_time ? Number(new Date(match.start_time)) : NaN;
  console.log({ startTime: match?.start_time });
  console.log({ st });
  let findFCord: cordChampionshipType = useMemo(() => {
    let f: cordChampionshipType =
      fCords.find((item: cordChampionshipType) => item?.id === match?.cord) ||
      {};
    return f;
  }, [fCords, match]);

  const fightTimeFormat: string =
    ChampTimeF(
      h,
      findFCord,
      match?.msFightDuration,
      match?.adjustedIndex - 1,
      st
    ) || "";

  const countries = useAppSelector((state: any) => state.login.country);
  const regions = useAppSelector((state: any) => state.login.region);
  const cities = useAppSelector((state: any) => state.login.city);
  let country: country | any =
    countries.find((item: any) => item.id === User1?.country) || {};
  let region: region | any =
    regions.find((item: any) => item.id === User1?.region) || {};
  let city: city | any =
    cities.find((item: any) => item.id === User1?.city) || {};
  let coach: Users =
    login.find((item: Users) => item.id === match?.findPart1) || {};

  let country2: country | any =
    countries.find((item: any) => item.id === User2?.country) || {};
  let region2: region | any =
    regions.find((item: any) => item.id === User2?.region) || {};
  let city2: city | any =
    cities.find((item: any) => item.id === User2?.city) || {};
  let coach2: Users =
    login.find((item: Users) => item.id === match?.findPart2) || {};

  let categoryChild =
    Category.find((item: any) => item?.id === match?.category) || {};

  let subCategoryChild =
    SubCategory.find((item: any) => item?.id === match?.subCategory) || {};

  let cordChild = fCords.find((item) => item?.id === match?.cord) || {};

  const cordDays = useAppSelector((state) => state.setka.championshipDay);

  let cordDayChild = useMemo(() => {
    let f =
      cordDays.find(
        (item: DayChampionshipType) =>
          item?.id === cordChild?.championship_day_id
      ) || {};

    return f;
  }, [cordDays, cordChild]);

  const dispatch = useAppDispatch();

  const winner = (id: number, place: number, name: string) => {
    let w = window.confirm(`Точно ${name} выграл(-а)?`);
    if (w) {
      dispatch(updateWinner({ id, place }));
    }
  };
  const loser = (id: number, place: number, name: string) => {
    let l = window.confirm(`Точно ${name} проиграл(-а)?`);
    if (l) {
      dispatch(updateLoser({ id, place }));
    }
  };

  // console.log({ format_milliseconds });
  const startFighting = () => {
    dispatch(currentFight({ id: clickCordId, current_fight: match?.number }));
  };
  const cancelFighting = () => {
    dispatch(CancelFight({ id: clickCordId }));
  };

  return Number(match?.adjustedIndex) !== 0 ? (
    <div className="DataSetkiContainer">
      <main className="styleTextGrid">№ {match?.number}</main>
      <main className="container_current_fight_minut">
        {/* <div>{Number(milliseconds)}</div> */}
        <main>{cordDayChild?.name}</main>
        <main>{cordChild?.name}</main>
        {/* <main>{match?.maxPlace}</main> */}
        {/* <main>{match?.place}</main> */}
        <main className="">{categoryChild?.name}</main>
        <main className="">{subCategoryChild?.name}</main>
      </main>
      {match?.msFightDuration > 0 ? (
        <main>
          {match?.adjustedIndex > 1 ? (
            <main className="current_fight_minut">{fightTimeFormat}</main>
          ) : null}
        </main>
      ) : null}
      {(fAdmin.length > 0 || championship?.creator === user?.id) &&
      match?.adjustedIndex === 1 &&
      clickDayId > 0 ? (
        <div>
          <input type="button" value={"Начать бой"} onClick={startFighting} />
          <input
            type="button"
            value={"Отменить бой"}
            onClick={cancelFighting}
          />
        </div>
      ) : null}

      <div id="DataBrackedContainerBlue">
        {(fAdmin.length > 0 || championship?.creator === user?.id) &&
          User1?.name && (
            <nav className="gridCheckmarkContainer">
              <ImCheckmark
                className="GridImCheckmark"
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "2px",
                  fill: "rgb(3, 145, 98)",
                }}
                onClick={(e) =>
                  winner(
                    match?.findPart1?.id,
                    Number(match?.findPart1?.place),
                    User1?.name
                  )
                }
              />

              {match?.maxPlace === undefined && (
                <ImCross
                  className="GridImCheckmark"
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "3px",
                    fill: "red",
                  }}
                  onClick={(e) =>
                    loser(
                      match?.findPart1?.id,
                      Number(match?.findPart1?.place),
                      User1?.name
                    )
                  }
                />
              )}
            </nav>
          )}
        <nav className="DataGridNameBlue DataSetkiName timeBrackedChild">
          <main className="ava noskew" id="avaShowSetka">
            {User1?.ava ? (
              <img
                className="avaImg"
                src={Api + "storage/avatar/prev_" + User1?.ava}
                alt=""
              />
            ) : (
              <img
                className="avaImg"
                src={"https://yaponaroll.ru/images/20200701_001.jpg"}
                alt=""
              />
            )}
          </main>
          {User1?.name ? (
            <div className="dSetka">
              {" "}
              <p className="nameDataGrid">
                {User1?.name} {match?.findPart1?.id}
              </p>
              <p className="yearDataGrid">year: {User1?.year}</p>
              <p className="coachDataGrid">coach: {coach?.name}</p>
              <p>{match?.findPart1?.place}</p>
              <p className="adressGrid">
                {region?.name} {city?.name}
              </p>
            </div>
          ) : (
            <div className="dSetka">
              <p className="nameDataGrid">Sopernik eshe ne viyavleno</p>
            </div>
          )}
        </nav>
        {findFCord?.current_fight === match?.number ? (
          <div className="containerSword">
            <div className="rotated-icon">
              <LuSword className="move-animation" style={{ fill: "blue" }} />
            </div>
            <div className="rotated-icon2">
              <LuSword className="moveD-animationD" style={{ fill: "red" }} />
            </div>
          </div>
        ) : null}

        {(fAdmin.length > 0 || championship?.creator === user?.id) &&
          User2?.name && (
            <nav className="gridCheckmarkContainer">
              <ImCheckmark
                className="GridImCheckmark"
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "2px",
                  fill: "rgb(3, 145, 98)",
                }}
                onClick={(e) =>
                  winner(
                    match?.findPart2?.id,
                    Number(match?.findPart2?.place),
                    User2?.name
                  )
                }
              />

              {match?.maxPlace === undefined && (
                <ImCross
                  className="GridImCheckmark"
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "3px",
                    fill: "red",
                  }}
                  onClick={(e) =>
                    loser(
                      match?.findPart2?.id,
                      Number(match?.findPart2?.place),
                      User2?.name
                    )
                  }
                />
              )}
            </nav>
          )}
        <nav className="DataGridNameRed DataSetkiName timeBrackedChild">
          <main className="ava noskew" id="avaShowSetka">
            {User2?.ava ? (
              <img
                className="avaImg"
                src={Api + "storage/avatar/prev_" + User2?.ava}
                alt=""
              />
            ) : (
              <img
                className="avaImg"
                src={"https://yaponaroll.ru/images/20200701_001.jpg"}
                alt=""
              />
            )}
          </main>
          {User2?.name ? (
            <main className="dSetka">
              {" "}
              <p className="nameDataGrid">
                {User2?.name} {match?.findPart2?.id}
              </p>
              <p className="yearDataGrid">year: {User2?.year}</p>
              <p className="coachDataGrid">coach: {coach2?.name}</p>
              <p>{match?.findPart2?.place}</p>
              <p className="adressGrid">
                {region2?.name} {city2?.name}
              </p>
            </main>
          ) : (
            <main className="dSetka">
              <p className="nameDataGrid">Sopernik eshe ne viyavleno</p>
            </main>
          )}
        </nav>
      </div>
    </div>
  ) : null;
};
