import { DateTime } from "luxon";
import { cordChampionshipType } from "../../../../../store/setkaSlice";
import { formatDate, formatTime } from "./formatDate";

export const ChampTimeF = (
  currentDate: number,
  findFCord: cordChampionshipType,
  millisecondsToAdd: any,
  adjustedIndex: number,
  st: number
) => {
  let dateString = findFCord?.current_fight_time
    ? findFCord?.current_fight_time
    : "";

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Преобразуем строку в объект DateTime
  const date = DateTime.fromFormat(dateString, "yyyy-MM-dd HH:mm:ss", {
    zone: "UTC",
  });

  // Устанавливаем нужную временную зону, например, 'Asia/Almaty'
  const dateInTimezone = date.setZone(userTimezone);

  // Получаем метку времени в миллисекундах для этой временной зоны
  let current_fight_time = dateInTimezone.toMillis();
  // console.log({ curr: findFCord, num: match?.number });

  let time = currentDate - current_fight_time;

  let tt = time > millisecondsToAdd ? millisecondsToAdd : time;

  let dateFighting = new Date(millisecondsToAdd * adjustedIndex + st);

  let format_time = formatTime(dateFighting);
  let format_date = formatDate(dateFighting);

  let milliseconds = millisecondsToAdd * adjustedIndex - tt;
  if (Number.isNaN(current_fight_time)) {
    milliseconds = millisecondsToAdd * adjustedIndex;
  }

  // Вычисляем часы, минуты и секунды
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));

  // Формируем строку
  let format_milliseconds = "";
  if (hours > 0) {
    format_milliseconds += `${hours} час `; // Добавляем часы, если они есть
  }
  if (minutes > 0) {
    format_milliseconds += hours > 0 ? ` ${minutes} мин` : `${minutes} минут`; // Добавляем пробел, если есть часы
  }
  if (minutes === 0) {
    format_milliseconds = "Готовится";
  }
  console.log({ st, currentDate });
  if (st < currentDate || Number.isNaN(st)) {
    if (adjustedIndex > 0) {
      return format_milliseconds;
    }
  } else {
    return format_time;
  }
};
