import { FC } from "react";
import "./style.css";
import { participantType } from "../../../../../store/settingSlice";
import { SType } from "../../../../../store/setkaSlice";

type ReportType = {
  mergedGrid: (SType & participantType)[];
};

export const Report: FC<ReportType> = ({ mergedGrid }) => {
  type Report = {
    gold: Record<string, number>;
    silver: Record<string, number>;
    bronze: Record<string, number>;
  };
  // Функция для формирования отчёта
  const generateReport = (data: (SType & participantType)[]): Report => {
    const gridCounts: Record<string, number> = {};

    // 1. Группируем участников по уникальной сетке (championship_sub_category_id + i_grid + categoryABC)
    data.forEach(({ championship_sub_category_id, i_grid, categoryABC }) => {
      const key = `${championship_sub_category_id}-${i_grid}-${categoryABC}`;
      gridCounts[key] = (gridCounts[key] || 0) + 1;
    });

    // 2. Инициализируем отчёт по категориям
    const report: Report = { gold: {}, silver: {}, bronze: {} };

    // 3. Заполняем отчёт
    Object.entries(gridCounts).forEach(([grid, count]) => {
      const category = grid.split("-")[2]; // Берём категорию ABC

      // Инициализируем, если ещё нет
      report.gold[category] = (report.gold[category] || 0) + 1;
      report.silver[category] =
        (report.silver[category] || 0) + (count >= 2 ? 1 : 0);
      report.bronze[category] =
        (report.bronze[category] || 0) + (count >= 4 ? 2 : count === 3 ? 1 : 0);
    });

    return report;
  };

  // Вызываем функцию и выводим результат
  const report = generateReport(mergedGrid);
  console.log(report);
  const gold: number =
    (report?.gold?.A || 0) + (report?.gold?.B || 0) + (report?.gold?.C || 0);
  const silver: number =
    (report?.silver?.A || 0) +
    (report?.silver?.B || 0) +
    (report?.silver?.C || 0);
  const bronze: number =
    (report?.bronze?.A || 0) +
    (report?.bronze?.B || 0) +
    (report?.bronze?.C || 0);
  return (
    <div>
      <div className="reportChampionship">
        <div>Общие отчёты: </div>
        <div>🥇 золото: {gold}</div>
        <div>🥈 серебро {silver}</div>
        <div>🥉 бронзы: {bronze}</div>
      </div>
      {report?.gold?.A && report?.silver?.A && report?.bronze?.A ? (
        <div className="reportChampionship">
          <div>Отчёты на A категорий: </div>
          <div>🥇 золото: {report?.gold?.A || 0}</div>
          <div>🥈 серебро {report?.silver?.A || 0}</div>
          <div>🥉 бронзы: {report?.bronze?.A || 0}</div>
        </div>
      ) : null}
      {report?.gold?.B && report?.silver?.B && report?.bronze?.B ? (
        <div className="reportChampionship">
          <div>Отчёты на B категорий: </div>
          <div>🥇 золото: {report?.gold?.B || 0}</div>
          <div>🥈 серебро {report?.silver?.B || 0}</div>
          <div>🥉 бронзы: {report?.bronze?.B || 0}</div>
        </div>
      ) : null}
      {report?.gold?.C && report?.silver?.C && report?.bronze?.C ? (
        <div className="reportChampionship">
          <div>Отчёты на C категорий: </div>
          <div>🥇 золото: {report?.gold?.C || 0}</div>
          <div>🥈 серебро {report?.silver?.C || 0}</div>
          <div>🥉 бронзы: {report?.bronze?.C || 0}</div>
        </div>
      ) : null}
    </div>
  );
};
